import React from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useI18n from '../../misc/languages/useI18n'
import { premiumConfig, stripeKey, pricingMode, facebookPixelId } from '../../config'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import Box from '@material-ui/core/Box'
import { loadStripe } from '@stripe/stripe-js'
import { useFirebase } from 'react-redux-firebase'
import useFirebaseFunctions from '../../misc/useFirebaseFunctions'
import CircularProgress from '@material-ui/core/CircularProgress'
import Chip from '@material-ui/core/Chip'
import { useSnackbar } from 'notistack'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ReactPixel from 'react-facebook-pixel'

const stripePromise = loadStripe(stripeKey)

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[300],
  },
  cardHeaderHighlighted: {
    backgroundColor: theme.palette.primary[400],
  },
  whiteText: {
    color: theme.palette.common.white,
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2, theme.breakpoints.down('sm') ? 2 : 6),
  },
}))

export default function Pricing({ hideTitle, skipFree, ...props }) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const { enqueueSnackbar } = useSnackbar()
  const { role, freeTrialEndsAt, createdAt, premium } = useSelector((state) =>
    get(state, 'firebase.profile')
  )
  const { t } = useI18n()
  const firebase = useFirebase()
  const [subscribe, unsubscribe, getBillingPortal] = useFirebaseFunctions([
    'subscribe',
    'unsubscribe',
    'getBillingPortal',
  ])
  const [countdown, setCountdown] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [unsubscribeLoading, setUnsubscribeLoading] = React.useState(false)
  const [isUnsubscribed, setIsUnsubscribed] = React.useState(false)

  const isPremium = !!get(premium, `${role}.quantity`, 0) && !isUnsubscribed
  const isDiscounted = (pricingMode === 'countdown' && !!countdown) || pricingMode === 'discount'

  const tiers = [
    skipFree
      ? null
      : {
          title: t('free'),
          price: '$0',
          description: [
            t(`questions per ${role === 'student' ? 'practice' : 'assignment'}`, {
              n: premiumConfig.freeLimit.questionPerPractice,
            }),
            t(`${role === 'student' ? 'practices' : 'assignments'} per day`, {
              n: premiumConfig.freeLimit.practicePerDay,
            }),
          ],
          buttonText: t('downgrade'),
          buttonVariant: 'outlined',
          buttonColor: 'default',
          buttonOnClick:
            isPremium && !unsubscribeLoading
              ? async () => {
                  try {
                    setUnsubscribeLoading(true)
                    await unsubscribe({})
                    enqueueSnackbar(t('successfully unsubscribed'), { key: 'unsubscribe' })
                    setUnsubscribeLoading(false)
                    setIsUnsubscribed(true)
                  } catch (err) {
                    setUnsubscribeLoading(false)
                    console.log(err)
                  }
                }
              : undefined,
        },
    {
      title: t('premium'),
      subheader: isDiscounted
        ? `${t('discount', { discount: 50 })}${
            pricingMode === 'discount'
              ? ''
              : t('offer ends at', { countdown: `<b style="font-size: 20px"> ${countdown} </b>` })
          }`
        : undefined,
      price: isDiscounted
        ? `<del>$${premiumConfig.plans[`${role}`].price}</del> $${
            premiumConfig.plans[`${role}LimitedTimeDiscount`].price
          }`
        : `$${premiumConfig.plans[`${role}`].price}`,
      description: [
        t(`questions per ${role === 'student' ? 'practice' : 'assignment'}`, {
          n: `<b>${t('unlimited')}</b>`,
        }),
        t(`${role === 'student' ? 'practices' : 'assignments'} per day`, {
          n: `<b>${t('unlimited')}</b>`,
        }),
      ],
      buttonText: loading ? <CircularProgress size={24} /> : t('upgrade'),
      buttonVariant: isPremium ? 'outlined' : 'contained',
      buttonColor: 'primary',
      highlighted: true,
      buttonOnClick:
        loading || isPremium
          ? undefined
          : async () => {
              try {
                setLoading(true)
                const session = await subscribe({
                  plans: [
                    {
                      id:
                        premiumConfig.plans[`${role}${isDiscounted ? 'LimitedTimeDiscount' : ''}`]
                          .key,
                      quantity: 1,
                    },
                  ],
                  prevPage: window.location.href,
                })
                const stripe = await stripePromise
                const { error } = await stripe.redirectToCheckout({
                  sessionId: session.data.id,
                })
                facebookPixelId &&
                  ReactPixel.track('Purchase', {
                    currency: 'HKD',
                    value:
                      premiumConfig.plans[`${role}${isDiscounted ? 'LimitedTimeDiscount' : ''}`]
                        .price,
                  })
                setLoading(false)
                console.log(error)
              } catch (error) {
                setLoading(false)
                console.log(error)
              }
            },
    },
  ].filter((a) => a)

  React.useEffect(() => {
    let interval
    if (createdAt < Date.now() - 30 * 24 * 3600 * 1000) {
      if (freeTrialEndsAt && Date.now() < freeTrialEndsAt + 24 * 3600 * 1000) {
        interval = setInterval(() => {
          const diff = Math.round((freeTrialEndsAt + 24 * 3600 * 1000 - Date.now()) / 1000)
          const hour = Math.floor(diff / 3600)
          setCountdown(
            diff > 0 ? `${hour}:${Math.floor((diff - hour * 3600) / 60)}:${diff % 60}` : ''
          )
        }, 1000)
      } else if (!freeTrialEndsAt && pricingMode === 'countdown') {
        firebase.updateProfile({ freeTrialEndsAt: Date.now() })
      }
    }
    return () => clearInterval(interval)
  }, [freeTrialEndsAt, createdAt])

  const navigateBillingPortal = async () => {
    try {
      setLoading(true)
      const billingPortal = await getBillingPortal({ prevPage: window.location.href })
      window.location.href = get(billingPortal, 'data.url')
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  return (
    <>
      {hideTitle ? null : (
        <Typography variant="h5" color="textPrimary" gutterBottom>
          {t('pricing')}
          {isPremium ? (
            <Box ml={2} display="inline">
              <Chip label={t('premium')} color="secondary" />
            </Box>
          ) : null}
        </Typography>
      )}
      {isPremium ? (
        <Button disabled={loading} size="large" onClick={navigateBillingPortal} color="primary">
          {t('billing details')}
        </Button>
      ) : (
        <Box display={isMobile ? 'block' : 'flex'} width="100%" {...props}>
          {tiers.map((tier, i) => (
            <Box
              key={tier.title}
              flex={1}
              width={isMobile ? '100%' : undefined}
              maxWidth={isMobile ? undefined : 400}
              m={2}
              ml={0}
              mr={i === tiers.length - 1 ? 0 : 2}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={<div dangerouslySetInnerHTML={{ __html: tier.subheader }} />}
                  titleTypographyProps={{
                    align: 'center',
                    className: tier.highlighted ? classes.whiteText : '',
                  }}
                  subheaderTypographyProps={{
                    align: 'center',
                    className: tier.highlighted ? classes.whiteText : '',
                  }}
                  className={tier.highlighted ? classes.cardHeaderHighlighted : classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography
                      dangerouslySetInnerHTML={{ __html: tier.price }}
                      component="h2"
                      variant="h3"
                      color="textPrimary"
                    />
                    <Typography variant="h6" color="textSecondary">
                      {t('per month')}
                    </Typography>
                  </div>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        dangerouslySetInnerHTML={{ __html: line }}
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      />
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    className={classes.button}
                    fullWidth
                    size="large"
                    variant={tier.buttonVariant}
                    color={tier.buttonColor}
                    onClick={tier.buttonOnClick}
                    disabled={!tier.buttonOnClick}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}
