import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useFirestore, useFirebaseConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSnackbar } from 'notistack'
import MenuItem from '@material-ui/core/MenuItem'
import Rating from '@material-ui/lab/Rating'
import { selectChapter } from '../../misc/selectors'
import Box from '@material-ui/core/Box'
import sample from 'lodash/sample'
import useI18n from '../../misc/languages/useI18n'

const ChangeQuestionDialog = ({ isVisible, onClose, assignment, questionId }) => {
  const firestore = useFirestore()
  const { t, locale } = useI18n()
  const [chapter, setChapter] = React.useState('')
  const [level, setLevel] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const chapters = useSelector((state) =>
    assignment.chapters.map((c) => selectChapter(state, c)).filter((c) => c)
  )
  const questionIndex = useSelector((state) =>
    get(state, `firebase.data.questionIndex.${assignment.subject}.${chapter}.level${level}`)
  )
  useFirebaseConnect(
    chapter && level ? [`questionIndex/${assignment.subject}/${chapter}/level${level}`] : []
  )

  const onSubmit = async () => {
    try {
      setLoading(true)
      const newId = sample(Object.keys(questionIndex))
      const newQuestions = assignment.questions.map((q) =>
        q.id === questionId
          ? {
              id: newId,
              chapter: questionIndex[newId].chapter,
              correctAns: questionIndex[newId].correctAns,
              ans: '',
            }
          : q
      )
      await firestore.update(
        { collection: 'assignments', doc: assignment.id },
        { questions: newQuestions }
      )
      setLoading(false)
      onClose()
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <Dialog open={isVisible} onClose={onClose} fullWidth>
      <DialogTitle>{t('change question')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('select new question criteria')}</DialogContentText>
        <TextField
          label={t('chapter')}
          fullWidth
          select
          value={chapter}
          onChange={(e) => setChapter(e.target.value)}
        >
          {chapters.map((c) => (
            <MenuItem key={c.id} value={c.id}>
              {c.chapterNo}. {get(c, `title.${locale}`)}
            </MenuItem>
          ))}
        </TextField>
        <Box mt={2}>
          <Typography variant="body2">{t('level')}</Typography>
          <Rating
            name="level"
            onChange={(e) => setLevel(Number(e.target.value))}
            size="large"
            value={level}
            max={3}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button style={{ width: 120 }} onClick={onClose} color="secondary">
          {t('cancel')}
        </Button>
        <Button
          style={{ width: 120 }}
          onClick={onSubmit}
          color="primary"
          disabled={!questionIndex || loading}
        >
          {loading ? <CircularProgress size={24} /> : t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangeQuestionDialog
