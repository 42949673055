import React from 'react'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { useHistory } from 'react-router-dom'
import format from 'date-fns/format'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase'
import InlineEditButton from '../../components/InlineEditButton'
import AdvancedTable from '../../components/AdvancedTable'
import { selectBookmarksWithSubjectChapter } from '../../misc/selectors'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ButtonBase from '../../components/ButtonBase'
import Divider from '@material-ui/core/Divider'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import useI18n from '../../misc/languages/useI18n'
import { enableCategories } from '../../config'
import TextField from '@material-ui/core/TextField'

const Bookmarks = () => {
  const firestore = useFirestore()
  const theme = useTheme()
  const [isFocused, setIsFocused] = React.useState(false)
  const { t, locale } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { height: minAvailableScreenHeight } = useMinAvailableSize(
    ['#app-bar', '#tab-bar'],
    isMobile ? 0 : 4
  )
  const bookmarks = useSelector(selectBookmarksWithSubjectChapter)
  const history = useHistory()
  useFirestoreConnect(
    bookmarks.map((b) => ({
      collection: 'chapters',
      doc: b.chapter.id,
    }))
  )
  const columns = [
    {
      key: 'date',
      label: t('date'),
    },
    {
      key: 'subject',
      label: t('subject'),
    },
    {
      key: 'chapter',
      label: t('chapter'),
    },
    {
      key: 'remark',
      label: t('remark'),
    },
  ]
  const rows = bookmarks.map((b) => ({
    key: b.id,
    onClick: () => history.push(`/app/solution/${b.practice}/${b.questionNo}`),
    date: {
      label: format(b.createdAt, 'yyyy-MM-dd'),
      sortValue: b.createdAt,
    },
    subject: {
      label:
        (enableCategories && get(b, `subject.category`)
          ? get(b, `subject.category.title.${locale}`) + ' - '
          : '') + get(b, `subject.title.${locale}`),
      id: get(b, 'subject.id'),
    },
    chapter: {
      label: `${get(b, 'chapter.chapterNo', '')}. ${get(b, `chapter.title.${locale}`)}`,
      sortValue: Number(get(b, 'chapter.chapterNo', 0)),
    },
    remark: {
      label: (
        <InlineEditButton
          value={b.remark}
          onSubmit={(remark) =>
            firestore.update({ collection: 'bookmarks', doc: b.id }, { remark })
          }
        />
      ),
      sortValue: b.remark,
      searchValue: b.remark,
    },
  }))
  return (
    <WithAppBar title={t('bookmarks')}>
      <AdvancedTable
        withSubjectFilter
        containerProps={{ height: minAvailableScreenHeight }}
        rows={rows}
        columns={columns}
        defaultSortBy="date"
        defaultSortOrder={false}
        renderMobileItem={(row) => (
          <ButtonBase key={row.key} onClick={!isFocused ? row.onClick : undefined}>
            <Box py={2} position="relative">
              <Typography>{row.subject.label}</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {row.date.label}
              </Typography>
              <Typography color="textSecondary">{row.chapter.label}</Typography>
              <Box mt={1} ml={3} onClick={(e) => e.stopPropagation()}>
                <TextField
                  fullWidth
                  multiline
                  InputProps={{
                    style: { fontSize: theme.typography.body2.fontSize },
                  }}
                  label={t('remark')}
                  defaultValue={row.remark.searchValue}
                  onFocus={() => setIsFocused(true)}
                  onBlur={(e) => {
                    setIsFocused(false)
                    firestore.update(
                      { collection: 'bookmarks', doc: row.key },
                      { remark: e.target.value }
                    )
                  }}
                />
              </Box>
            </Box>
            <Divider />
          </ButtonBase>
        )}
        emptyText={t('no bookmark')}
      />
    </WithAppBar>
  )
}

export default Bookmarks
