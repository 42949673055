import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import useI18n from '../../misc/languages/useI18n'

const ConfirmDialog = ({ title, message, isVisible, onClose, onSubmit }) => {
  const { t } = useI18n()
  return (
    <Dialog open={isVisible} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{title || t('warning')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button style={{ width: 120 }} onClick={onClose} color="secondary">
          {t('cancel')}
        </Button>
        <Button style={{ width: 120 }} onClick={onSubmit} color="primary" autoFocus>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
