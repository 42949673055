import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Checkbox from '@material-ui/core/Checkbox'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useFirestoreConnect } from 'react-redux-firebase'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'
import ReactToPrint from 'react-to-print'
import { useSelector } from 'react-redux'
import { selectQuestion } from '../../misc/selectors'
import QuestionWithAnswersCard from '../QuestionWithAnswersCard'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import format from 'date-fns/format'
import get from 'lodash/get'
import useI18n from '../../misc/languages/useI18n'

const PrintDialog = ({ isVisible, onClose, onPrint, assignment, practice }) => {
  const printContent = React.useRef()
  const { t, locale } = useI18n()
  const role = useSelector((state) => get(state, 'firebase.profile.role'))
  const studentOrTeacherName = useSelector((state) => {
    if (!assignment || !practice) {
      return null
    }
    if (role === 'teacher') {
      const student = state.firestore.ordered.students.find((s) => s.student === practice.user)
      return get(student, 'studentProfile.displayName')
    }
    if (role === 'student') {
      return get(assignment, 'teacher.teacherProfile.displayName')
    }
    return null
  })
  const [includeCorrectAns, setIncludeCorrectAns] = React.useState(false)
  const [includeAns, setIncludeAns] = React.useState(false)
  const [includeSolution, setIncludeSolution] = React.useState(false)

  const questionsMeta = (practice || assignment || { questions: [] }).questions
  const chapters = (practice || assignment || { chapters: [] }).chapters
  const questions = useSelector((state) => questionsMeta.map((q) => selectQuestion(state, q.id)))
  useFirestoreConnect([
    ...questionsMeta.map((q) => ({ collection: 'questions', doc: q.id })),
    ...chapters.map((q) => ({ collection: 'chapters', doc: q.id })),
  ])

  const { createdAt, subject } = practice || assignment || { createdAt: 0, subject: {} }

  return (
    <>
      <Dialog open={isVisible} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle>{t('print questions')}</DialogTitle>
        <DialogContent>
          <FormControlLabel
            style={{ display: 'block' }}
            control={
              <Checkbox
                checked={includeCorrectAns}
                onChange={(e) => setIncludeCorrectAns(e.target.checked)}
              />
            }
            label={t('include correct answer')}
          />
          {practice ? (
            <FormControlLabel
              style={{ display: 'block' }}
              control={
                <Checkbox checked={includeAns} onChange={(e) => setIncludeAns(e.target.checked)} />
              }
              label={role === 'teacher' ? t('include students answer') : t('include your answer')}
            />
          ) : null}
          <FormControlLabel
            style={{ display: 'block' }}
            control={
              <Checkbox
                checked={includeSolution}
                onChange={(e) => setIncludeSolution(e.target.checked)}
              />
            }
            label={t('include solution')}
          />
        </DialogContent>
        <DialogActions>
          <Button style={{ width: 120 }} onClick={onClose} color="secondary">
            {t('cancel')}
          </Button>
          <ReactToPrint
            content={() => printContent.current}
            onAfterPrint={onClose}
            trigger={() => (
              <Button style={{ width: 120 }} onClick={onPrint} color="primary">
                {t('print')}
              </Button>
            )}
          />
        </DialogActions>
      </Dialog>
      <Box display="none">
        <Box ref={printContent} m={4}>
          <Box display="flex" justifyContent="space-between" alignItems="center" m={4}>
            <img style={{ width: 80 }} src={require('../../assets/images/logo.png')} />
            <Typography variant="h5" color="textSecondary">
              {t('more questions at')}
            </Typography>
          </Box>
          <Box mx={4} mb={-4}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{t('subject')}</Typography>
                <Typography>{get(subject, `title.${locale}`)}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{t('date')}</Typography>
                <Typography>{format(createdAt, 'yyyy-MM-dd')}</Typography>
              </Grid>
              {studentOrTeacherName ? (
                <Grid item xs={4}>
                  <Typography variant="subtitle2">
                    {role === 'teacher' ? t('student') : t('teacher')}
                  </Typography>
                  <Typography>{studentOrTeacherName}</Typography>
                </Grid>
              ) : null}
            </Grid>
          </Box>
          {questionsMeta.map((q, i) => (
            <Box pt={4} mb={-8} key={q.id}>
              <QuestionWithAnswersCard
                question={questions[i]}
                questionNo={i}
                noBookmark
                noStat
                correctAns={includeCorrectAns ? q.correctAns : ''}
                ans={includeAns ? q.ans : ''}
                boxShadow={0}
                dense
                withSolution={includeSolution}
                style={{
                  pageBreakBefore: 'auto',
                  pageBreakAfter: 'auto',
                  pageBreakInside: 'avoid',
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </>
  )
}

export default PrintDialog
