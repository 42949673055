import React from 'react'
import get from 'lodash/get'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { styled, lighten } from '@material-ui/core/styles'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useSnackbar } from 'notistack'
import useI18n from '../../misc/languages/useI18n'

const CountDownBar = styled(LinearProgress)(({ theme, state }) => ({
  marginTop: theme.spacing(1),
  backgroundColor: lighten(theme.palette[state].main, 0.5),
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette[state].main,
  },
}))

const Timer = ({ practiceId, practice, onTimeOut }) => {
  const [timeLeft, setTimeLeft] = React.useState(0)
  const [submitted, setSubmitted] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useI18n()

  React.useEffect(() => {
    if (!submitted) {
      const now = Date.now()
      const newTimeLeft =
        60 * get(practice, 'timeLimit', 0) -
        differenceInSeconds(now, get(practice, 'createdAt', now))
      setTimeout(() => {
        setTimeLeft(Math.max(newTimeLeft, 0))
        if (get(practice, 'timeLimit') && newTimeLeft <= 0) {
          setSubmitted(true)
          onTimeOut()
          enqueueSnackbar(t('time is up'), { key: practiceId })
        }
      }, 1000)
    }
  }, [practice, timeLeft, submitted])

  const timeLeftPercentage = (100 * timeLeft) / (get(practice, 'timeLimit', 0) * 60) || 100
  const timeLeftText = `${t('time left')}: ${Math.floor(timeLeft / 3600)}:${`0${
    Math.floor(timeLeft / 60) % 60
  }`.slice(-2)}:${`0${timeLeft % 60}`.slice(-2)}`

  return (
    <Box id="time-left-container" p={2} pb={0} textAlign="center">
      <Typography variant="h5">{timeLeftText}</Typography>
      <CountDownBar
        variant="determinate"
        state={timeLeftPercentage < 20 ? 'error' : timeLeftPercentage < 50 ? 'warning' : 'success'}
        value={timeLeftPercentage}
      />
    </Box>
  )
}

export default Timer
