import React from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import format from 'date-fns/format'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import Rating from '@material-ui/lab/Rating'
import { useFirestore } from 'react-redux-firebase'
import { useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { useSnackbar } from 'notistack'
import InfoList from '../../components/InfoList'
import SmallAvatarWithName from '../../components/SmallAvatarWithName'
import { useHistory } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ConfirmDialog from '../../components/ConfirmDialog'
import useI18n from '../../misc/languages/useI18n'
import maxBy from 'lodash/maxBy'

const Overview = ({ practice, assignment, studentId }) => {
  const firestore = useFirestore()
  const theme = useTheme()
  const history = useHistory()
  const { t, locale } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const { enqueueSnackbar } = useSnackbar()
  const { height: minAvailableScreenHeight } = useMinAvailableSize(
    ['#app-bar', '#tab-bar'],
    isMobile ? 0 : 4
  )
  const role = useSelector((state) => state.firebase.profile.role)
  const percentage = practice ? practice.score / practice.noOfQuestions : 0
  const grade =
    maxBy(
      get(practice, 'subject.grades', []).filter((g) => percentage >= g.min && percentage <= g.max),
      (g) => g.max
    ) || {}
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = React.useState(false)
  const infoListItems = practice
    ? [
        assignment
          ? {
              title: role === 'student' ? t('teacher') : t('student'),
              label: (
                <Box mt={1} mb={3}>
                  <SmallAvatarWithName
                    subtitle={get(assignment, 'teacher.schoolProfile.displayName', '')}
                    profile={
                      role === 'student'
                        ? get(assignment, 'teacher.teacherProfile', {})
                        : get(
                            assignment.students.find((s) => s.student === studentId),
                            'studentProfile',
                            {}
                          )
                    }
                  />
                </Box>
              ),
            }
          : null,
        {
          title: t('subject'),
          label: get(practice, `subject.title.${locale}`),
        },
        {
          title: t('no of questions'),
          label: practice.noOfQuestions.toString(),
        },
        {
          title: t('time limit'),
          label: `${practice.timeLimit} ${t('minutes')}`,
        },
        {
          title: t('difficulty'),
          label: <Rating name="difficulty" size="large" value={practice.difficulty} readOnly />,
        },
        {
          title: t('date'),
          label: format(practice.createdAt, 'yyyy-MM-dd'),
        },
        assignment
          ? {
              title: t('due date'),
              label: format(assignment.dueDate, 'yyyy-MM-dd, hh:mm a'),
              labelProps:
                practice.finishedAt > assignment.dueDate
                  ? {
                      color: 'error',
                    }
                  : null,
            }
          : null,
        {
          title: t('duration'),
          label: `${format(practice.createdAt, 'hh:mm a')} - ${format(
            practice.finishedAt,
            'hh:mm a'
          )} (${differenceInMinutes(practice.finishedAt, practice.createdAt)} ${t('minutes')})`,
        },
        {
          title: t('score'),
          label: `${practice.score} / ${practice.noOfQuestions} (${Math.round(100 * percentage)}%)`,
        },
        {
          title: t('grade'),
          label: grade.grade,
          labelProps: {
            style: { color: grade.color },
            variant: 'h3',
          },
        },
      ].filter((a) => a)
    : []

  return (
    <InfoList
      items={infoListItems}
      footer={
        role === 'student' ? (
          <>
            <Box my={2}>
              <TextField
                fullWidth
                multiline
                label={t('remark')}
                defaultValue={practice.remark}
                onBlur={(e) => {
                  firestore.update(
                    { collection: 'practices', doc: practice.id },
                    { remark: e.target.value }
                  )
                  enqueueSnackbar(t('remark updated'), { key: practice.id })
                }}
              />
            </Box>
            {practice.assignment ? null : (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => setConfirmDeleteDialogOpen(true)}
              >
                {t('delete practice')}
              </Button>
            )}
            <ConfirmDialog
              isVisible={confirmDeleteDialogOpen}
              onClose={() => setConfirmDeleteDialogOpen(false)}
              message={t('confirm delete practice')}
              onSubmit={() => {
                firestore.update({ collection: 'practices', doc: practice.id }, { deleted: true })
                enqueueSnackbar(t('practice deleted'), { key: practice.id })
                history.replace('/app/practice-history')
              }}
            />
          </>
        ) : null
      }
      height={isMobile ? undefined : minAvailableScreenHeight}
    />
  )
}

export default Overview
