import React from 'react'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { useSelector } from 'react-redux'
import AdvancedTable from '../../components/AdvancedTable'
import { selectSchoolTeachers, selectIsFreeTrial } from '../../misc/selectors'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import ButtonBase from '../../components/ButtonBase'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import Avatar from '@material-ui/core/Avatar'
import get from 'lodash/get'
import { useFirestore } from 'react-redux-firebase'
import ConfirmDialog from '../../components/ConfirmDialog'
import SmallAvatarWithName from '../../components/SmallAvatarWithName'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Tooltip from '@material-ui/core/Tooltip'
import format from 'date-fns/format'
import useI18n from '../../misc/languages/useI18n'
import InviteTeacherModal from '../../components/InviteTeacherModal'
import AssignStudentsDialog from '../../components/AssignStudentsDialog'
import PremiumDialog from '../../components/PremiumDialog'
import getInitial from '../../misc/getInitial'

const SchoolTeachers = () => {
  const firestore = useFirestore()
  const history = useHistory()
  const theme = useTheme()
  const { t } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { height: minAvailableScreenHeight } = useMinAvailableSize(
    ['#app-bar', '#tab-bar'],
    isMobile ? 0 : 4
  )
  const teachers = useSelector(selectSchoolTeachers)
  const { premium } = useSelector((state) => state.firebase.profile || {})
  const isFreeTrial = useSelector(selectIsFreeTrial)
  const [deletingTeacher, setDeletingTeacher] = React.useState(null)
  const [assigningTeacher, setAssignningTeacher] = React.useState(null)
  const [inviting, setInviting] = React.useState(false)
  const [isPremiumDialogVisible, setIsPremiumDialogVisible] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const columns = [
    {
      key: 'displayName',
      label: t('name'),
    },
    {
      key: 'students',
      label: t('students'),
    },
    {
      key: 'createdAt',
      label: t('created at'),
    },
    {
      key: 'actions',
      label: '',
      disableSort: true,
    },
  ]
  const rows = teachers.map((b) => ({
    key: b.id,
    // onClick: () => history.push(`/app/teacher-statistics/${b.teacher}`),
    profile: b.teacherProfile,
    accepted: b.accepted,
    displayName: {
      label: <SmallAvatarWithName profile={b.teacherProfile} />,
      sortValue: get(b, 'teacherProfile.displayName', ''),
      searchValue: get(b, 'teacherProfile.displayName', ''),
    },
    students: {
      label: b.accepted ? (
        b.students.filter((s) => !s.deleted).length ? (
          <Box display="flex" my={-2}>
            <AvatarGroup
              style={{ cursor: 'pointer' }}
              max={4}
              onClick={() => setAssignningTeacher(b)}
            >
              {b.students
                .filter((s) => !s.deleted)
                .map((s) => (
                  <Tooltip key={s.student} title={get(s, 'studentProfile.displayName', '')}>
                    <Avatar src={get(s, 'studentProfile.avatarUrl', '')}>
                      {getInitial(get(s, 'studentProfile.displayName', ''))}
                    </Avatar>
                  </Tooltip>
                ))}
            </AvatarGroup>
          </Box>
        ) : (
          <Box display="flex" my={-2}>
            <Button color="primary" onClick={() => setAssignningTeacher(b)}>
              {t('assign students')}
            </Button>
          </Box>
        )
      ) : (
        <Typography color="textSecondary">{t('invitation sent pending acceptance')}</Typography>
      ),
      sortValue: b.accepted ? b.students : -1,
      searchValue: b.accepted
        ? b.students.length
          ? b.students
              .map((s) => get(s, 'studentProfile.displayName', ''))
              .reduce((a, b) => a + b, '')
          : t('no student')
        : t('invitation sent pending acceptance'),
    },
    createdAt: {
      label: format(b.createdAt, 'yyyy-MM-dd'),
      sortValue: b.createdAt,
    },
    actions: {
      label: (
        <Box display="flex" my={-2}>
          <Button
            color="secondary"
            onClick={(e) => {
              e.stopPropagation()
              setDeletingTeacher(b.id)
            }}
          >
            {t('remove')}
          </Button>
        </Box>
      ),
      searchValue: '',
    },
  }))

  const onAdd = () =>
    !isFreeTrial && get(premium, 'teacher.quantity', 0) <= teachers.length
      ? setIsPremiumDialogVisible(true)
      : setInviting(true)

  return (
    <WithAppBar
      title={t('my teachers')}
      rightButtonIcon={isMobile ? <AddIcon /> : undefined}
      onRightButtonClick={onAdd}
    >
      <AdvancedTable
        containerProps={{ height: minAvailableScreenHeight }}
        rows={rows}
        columns={columns}
        defaultSortBy="createdAt"
        defaultSortOrder={false}
        onAdd={isMobile ? undefined : onAdd}
        renderMobileItem={(row) => (
          <ButtonBase key={row.key} onClick={row.onClick}>
            <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
              <SmallAvatarWithName
                moreMargin
                profile={row.profile}
                subtitle={
                  row.accepted ? (
                    <>
                      <Typography variant="body2">{t('students')}</Typography>
                      <Box my={2}>{row.students.label}</Box>
                    </>
                  ) : (
                    row.students.label
                  )
                }
              />
              {row.actions.label}
            </Box>
            <Divider />
          </ButtonBase>
        )}
        emptyText={t('no teacher')}
      />
      <ConfirmDialog
        isVisible={!!deletingTeacher}
        onClose={() => setDeletingTeacher(null)}
        message={t('confirm remove teacher')}
        onSubmit={() => {
          firestore.update({ collection: 'teachers', doc: deletingTeacher }, { deleted: true })
          enqueueSnackbar(t('teacher removed'), { key: deletingTeacher })
          setDeletingTeacher(null)
        }}
      />
      <InviteTeacherModal isVisible={inviting} onClose={() => setInviting(false)} />
      <AssignStudentsDialog
        isVisible={!!assigningTeacher}
        onClose={() => setAssignningTeacher(null)}
        defaultSelectedStudents={get(assigningTeacher, 'students', [])
          .filter((s) => !s.deleted)
          .map((s) => s.student)}
        onSubmit={async (selectedStudents) => {
          await Promise.all([
            ...selectedStudents.map((s) => {
              const existingStudent = get(assigningTeacher, 'students', []).find(
                (ss) => ss.student === s.student
              )
              if (!existingStudent) {
                return firestore.add('students', {
                  student: s.student,
                  studentProfile: s.studentProfile,
                  teacher: assigningTeacher.teacher,
                  teacherProfile: assigningTeacher.teacherProfile,
                  school: s.school,
                  schoolProfile: s.schoolProfile,
                  createdAt: Date.now(),
                  accepted: true,
                  deleted: false,
                })
              } else if (existingStudent.deleted) {
                return firestore.update(
                  { collection: 'students', doc: existingStudent.id },
                  { deleted: false }
                )
              }
              return Promise.resolve()
            }),
            ...get(assigningTeacher, 'students', []).map(
              (s) =>
                !selectedStudents.find((ss) => ss.student === s.student) &&
                firestore.update(
                  {
                    collection: 'students',
                    doc: s.id,
                  },
                  { deleted: true }
                )
            ),
          ])
        }}
      />
      <PremiumDialog
        isVisible={isPremiumDialogVisible}
        onClose={() => setIsPremiumDialogVisible(false)}
      />
    </WithAppBar>
  )
}

export default SchoolTeachers
