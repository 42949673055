import { styled } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'

export default styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'stretch',
  textAlign: 'left',
  flexDirection: 'column',
}))
