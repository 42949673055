import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import { useSelector } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { availableLanguages, availableRoles, enableFileUpload } from '../../config'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import get from 'lodash/get'
import { useFirebase } from 'react-redux-firebase'
import ConfirmDialog from '../../components/ConfirmDialog'
import { useSnackbar } from 'notistack'
import useI18n from '../../misc/languages/useI18n'
import Pricing from './Pricing'
import { selectIsFreeTrial } from '../../misc/selectors'
import SchoolPricing from './SchoolPricing'
import ResetPasswordDialog from '../../components/ResetPasswordDialog'
import getInitial from '../../misc/getInitial'

const AvatarContainer = styled(ButtonBase)(({ theme }) => ({
  borderRadius: '50%',
  marginTop: theme.spacing(theme.breakpoints.down('sm') ? 0 : 6),
  marginBottom: theme.spacing(theme.breakpoints.down('sm') ? 0 : 2),
  overflow: 'hidden',
}))

const LargeAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(16),
  height: theme.spacing(16),
}))

const ResetPasswordButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const ProfileForm = () => {
  const firebase = useFirebase()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useI18n()
  const uid = useSelector((state) => get(state, 'firebase.auth.uid'))
  const profile = useSelector((state) => state.firebase.profile)

  const isEmailSignIn = useSelector(
    (state) =>
      !!get(state, 'firebase.auth.providerData', []).find((p) => p.providerId === 'password')
  )
  const isFreeTrial = useSelector(selectIsFreeTrial)
  const [imageFile, setImageFile] = React.useState(null)
  const [imageUri, setImageUri] = React.useState(null)
  const [confirmResetDialogOpen, setConfirmResetDialogOpen] = React.useState(false)

  const [displayNameError, setDisplayNameError] = React.useState('')

  const uploadAvatar = async () => {
    const task = await firebase.uploadFile(`avatars/${uid}`, imageFile)
    const avatarUrl = await task.uploadTaskSnapshot.ref.getDownloadURL()
    await firebase.updateProfile({ avatarUrl })
  }
  const onSubmit = async ({ displayName, language, role, isSchool }) => {
    try {
      setDisplayNameError('')
      if (displayName) {
        await firebase.updateProfile({ displayName })
      }
      if (language) {
        await firebase.updateProfile({ language })
      }
      if (role) {
        await firebase.updateProfile({ role, isSchool })
      }
    } catch (err) {
      console.log(err)
    }
  }
  const onPasswordReset = async () => {
    try {
      await firebase.resetPassword(profile.email)
      enqueueSnackbar(t('password reset email sent'), { key: uid })
    } catch (err) {
      console.log(err)
    }
  }
  React.useEffect(() => {
    if (imageFile) {
      setImageUri(window.URL.createObjectURL(imageFile))
      uploadAvatar()
    }
  }, [imageFile])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={2} style={{ textAlign: 'center' }}>
        {enableFileUpload ? (
          <AvatarContainer onClick={() => document.getElementById('file-input').click()}>
            <LargeAvatar src={imageUri || profile.avatarUrl}>
              {getInitial(profile.displayName)}
            </LargeAvatar>
            <Box
              position="absolute"
              bottom={0}
              left={0}
              right={0}
              textAlign="center"
              p={0.5}
              bgcolor="rgba(0, 0, 0, 0.5)"
            >
              <Typography style={{ color: 'white' }}>{t('edit')}</Typography>
            </Box>
            <input
              id="file-input"
              type="file"
              style={{ display: 'none' }}
              accept="image/*"
              onChange={(e) => setImageFile(get(e, 'target.files[0]'))}
            />
          </AvatarContainer>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={12} md={10}>
        <Box mb={2}>
          <Typography variant="h5">{t('user profile')}</Typography>
        </Box>
        <Box mb={2}>
          <TextField
            error={!!displayNameError}
            helperText={displayNameError}
            fullWidth
            defaultValue={profile.displayName}
            label={t('name')}
            onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
            onBlur={(e) => {
              if (e.target.value) {
                onSubmit({ displayName: e.target.value })
              } else {
                setDisplayNameError(t('invalid name'))
              }
            }}
          />
        </Box>
        <Box mb={2}>
          <TextField disabled fullWidth defaultValue={profile.email} label={t('email')} />
        </Box>
        <Box mb={2}>
          <TextField
            select
            fullWidth
            label={t('language')}
            defaultValue={profile.language}
            onChange={(e) => onSubmit({ language: e.target.value })}
          >
            {availableLanguages.map((l) => (
              <MenuItem key={l.value} value={l.value}>
                {l.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box mb={2}>
          <TextField
            select
            fullWidth
            label={t('i am a')}
            defaultValue={profile.isSchool ? 'school' : profile.role}
            onChange={(e) =>
              onSubmit({
                role: e.target.value === 'school' ? 'teacher' : e.target.value,
                isSchool: e.target.value === 'school',
              })
            }
          >
            {availableRoles.map((l) => (
              <MenuItem key={l.value} value={l.value}>
                {t(l.label)}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        {isEmailSignIn ? (
          <ResetPasswordButton onClick={() => setConfirmResetDialogOpen(true)}>
            {t('click here to reset password')}
          </ResetPasswordButton>
        ) : null}
        {['student', 'teacher'].includes(profile.role) &&
        !profile.isSchool &&
        !profile.email.includes('demo-') ? (
          <Pricing />
        ) : null}
        {profile.isSchool && !profile.email.includes('demo-') ? <SchoolPricing /> : null}
      </Grid>
      {/* <ConfirmDialog
        isVisible={!isEmailSignIn && confirmResetDialogOpen}
        onClose={() => setConfirmResetDialogOpen(false)}
        message={t('confirm reset password')}
        onSubmit={onPasswordReset}
      /> */}
      <ResetPasswordDialog
        isVisible={isEmailSignIn && confirmResetDialogOpen}
        onClose={() => setConfirmResetDialogOpen(false)}
      />
    </Grid>
  )
}

export default ProfileForm
