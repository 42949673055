import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import ToIcon from '@material-ui/icons/Remove'
import { DatePicker } from '@material-ui/pickers'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'
import addYears from 'date-fns/addYears'
import startOfToday from 'date-fns/startOfToday'
import endOfToday from 'date-fns/endOfToday'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import { selectSubjects, selectStudent } from '../../misc/selectors'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import SmallAvatarWithName from '../../components/SmallAvatarWithName'
import useI18n from '../../misc/languages/useI18n'
import { useTheme, useMediaQuery } from '@material-ui/core'
import { enableCategories } from '../../config'

const FilterCard = ({
  from,
  to,
  subject,
  onSubjectChange,
  onFromChange,
  onToChange,
  tab,
  setTab,
  studentId,
  schoolProfile,
}) => {
  const { t, locale } = useI18n()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const [dateMode, setDateMode] = React.useState('1Y')
  const userCreatedAt = useSelector(
    (state) => new Date(Number(get(state, 'firebase.auth.createdAt', '0')))
  )
  const subjects = useSelector(selectSubjects)
  const student = useSelector((state) => selectStudent(state, studentId))

  const dateModes = React.useMemo(
    () =>
      [
        {
          label: '7D',
          from: addDays(startOfToday(), -6),
        },
        {
          label: '1M',
          from: addMonths(startOfToday(), -1),
        },
        {
          label: '3M',
          from: addMonths(startOfToday(), -3),
        },
        {
          label: '6M',
          from: addMonths(startOfToday(), -6),
        },
        {
          label: '1Y',
          from: addYears(startOfToday(), -1),
        },
        isMobile
          ? null
          : {
              label: t('longest'),
            },
      ].filter((a) => a),
    []
  )

  return (
    <Box
      m={2}
      p={2}
      borderRadius="borderRadius"
      overflow="hidden"
      boxShadow={3}
      bgcolor="background.paper"
      id="filter-container"
    >
      <Grid container spacing={2} alignItems="flex-end">
        {student ? (
          <Grid item xs={12} sm={12} md={2}>
            <SmallAvatarWithName
              profile={student.studentProfile}
              subtitle={get(schoolProfile, 'displayName')}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={12} md={3}>
          <TextField
            label={t('subject')}
            fullWidth
            select
            value={subject}
            onChange={(e) => onSubjectChange(e.target.value)}
          >
            {subjects.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {enableCategories && s.category ? s.category.title[locale] + ' - ' : ''}
                {s.title[locale]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} md={student ? 4 : 6}>
          <Box display="flex" justifyContent="center">
            <ButtonGroup color="primary">
              {dateModes.map((d) => (
                <Button
                  key={d.label}
                  disableElevation
                  variant={d.label === dateMode ? 'contained' : 'outlined'}
                  onClick={() => {
                    setDateMode(d.label)
                    onFromChange(d.from || userCreatedAt)
                    onToChange(endOfToday())
                  }}
                >
                  {d.label}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Box display="flex" alignItems="flex-end">
            <DatePicker
              variant="inline"
              format="yyyy-MM-dd"
              label="From"
              value={from}
              maxDate={to}
              onChange={(d) => {
                onFromChange(startOfDay(d))
                onSubjectChange(null)
              }}
            />
            <Box mx={1}>
              <ToIcon />
            </Box>
            <DatePicker
              variant="inline"
              format="yyyy-MM-dd"
              label="To"
              value={to}
              minDate={from}
              onChange={(d) => {
                onToChange(endOfDay(d))
                onSubjectChange(null)
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box m={-2} mt={2}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tab}
          onChange={(e, v) => setTab(v)}
        >
          <Tab label={t('overview')} />
          {studentId ? <Tab label={t('assignments')} /> : null}
          <Tab label={t('by chapter')} />
        </Tabs>
      </Box>
    </Box>
  )
}

export default FilterCard
