const getInitial = (name) =>
  name
    ? name
        .split(' ')
        .map((a) => a[0])
        .join('')
        .slice(0, 2)
    : ''

export default getInitial
