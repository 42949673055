import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { useSelector } from 'react-redux'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import get from 'lodash/get'
import {
  selectSubjects,
  selectSchoolTeachers,
  selectCategories,
  selectTeachers,
} from '../../misc/selectors'
import AddIcon from '@material-ui/icons/Add'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
// import LazyLoad from 'react-lazyload'
import useI18n from '../../misc/languages/useI18n'
import SmallAvatarWithName from '../SmallAvatarWithName'
import { useFirebase } from 'react-redux-firebase'
import shortid from 'shortid'
import { enableCategories } from '../../config'
import uniqBy from 'lodash/uniqBy'

const AdvancedTable = ({
  rows,
  columns,
  defaultSortBy,
  defaultSortOrder,
  containerProps,
  withSubjectFilter,
  onAdd,
  disableSearch,
  renderMobileItem,
  emptyText,
  defaultSearch,
  withSchoolFilter: defaultWithSchoolFilter,
  defaultSubject: propsDefaultSubject,
  defaultSchool: propsDefaultSchool,
}) => {
  const theme = useTheme()
  const firebase = useFirebase()
  const { t, locale } = useI18n()
  const uid = useSelector((state) => state.firebase.auth.uid)
  const { isSchool, defaultSchool, defaultSubject, role } = useSelector(
    (state) => state.firebase.profile
  )
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const width = isMobile ? document.body.clientWidth - theme.spacing(4) : undefined
  const subjects = useSelector(selectSubjects)
  const schools = useSelector(
    role === 'student'
      ? (state) =>
          uniqBy(
            selectTeachers(state).filter((t) => t.school),
            (t) => t.school
          )
      : selectSchoolTeachers
  )
  const [sortBy, setSortBy] = React.useState(defaultSortBy)
  const [sortOrder, setSortOrder] = React.useState(defaultSortOrder)
  const [subject, setSubject] = React.useState(
    propsDefaultSubject === undefined ? defaultSubject || '' : propsDefaultSubject
  )
  const [school, setSchool] = React.useState(propsDefaultSchool || defaultSchool || '')
  const [search, setSearch] = React.useState(defaultSearch || '')
  const [domId] = React.useState(shortid.generate())

  const withSchoolFilter = defaultWithSchoolFilter && schools.length && !isSchool

  React.useEffect(() => {
    setSearch(defaultSearch || '')
  }, [defaultSearch])

  React.useEffect(() => {
    if (school !== defaultSchool) {
      firebase.updateProfile({
        defaultSchool: school || '',
      })
    }
  }, [school])

  React.useEffect(() => {
    if (subject !== defaultSubject) {
      firebase.updateProfile({
        defaultSubject: subject || '',
      })
    }
  }, [subject])

  const filteredRows = rows
    .filter(
      (r) =>
        columns
          .map((c) =>
            ((r[c.key].searchValue === undefined ? r[c.key].label : r[c.key].searchValue) || '')
              .toLowerCase()
              .includes((search || '').toLowerCase())
          )
          .reduce((a, b) => a || b, false) &&
        (!withSubjectFilter || !subject || get(r, 'subject.id') === subject) &&
        (!withSchoolFilter || get(r, 'school.id', '') === (isSchool ? uid : school))
    )
    .sort(
      (a, b) =>
        (sortOrder ? 1 : -1) *
        ((a[sortBy].sortValue === undefined ? a[sortBy].label : a[sortBy].sortValue) >
        (b[sortBy].sortValue === undefined ? b[sortBy].label : b[sortBy].sortValue)
          ? 1
          : -1)
    )
  return (
    <Box
      width={width}
      m={2}
      overflow="hidden"
      boxShadow={3}
      borderRadius="borderRadius"
      bgcolor="background.paper"
      display="flex"
      flexDirection="column"
      {...containerProps}
    >
      {disableSearch && !onAdd && !withSubjectFilter && !withSchoolFilter ? null : (
        <Box p={2} pb={0}>
          <Grid container spacing={2}>
            {withSchoolFilter ? (
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  label={t('school')}
                  fullWidth
                  select
                  value={school || 'my-students'}
                  onChange={(e) =>
                    setSchool(e.target.value === 'my-students' ? '' : e.target.value)
                  }
                >
                  <MenuItem value="my-students">{t('no school')}</MenuItem>
                  {schools.map((s) => (
                    <MenuItem
                      style={{
                        paddingBottom: theme.spacing(1),
                        paddingTop: theme.spacing(1),
                        height: theme.spacing(6),
                      }}
                      key={s.school}
                      value={s.school}
                    >
                      <SmallAvatarWithName profile={s.schoolProfile} />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ) : null}
            {withSubjectFilter ? (
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  label={t('subject')}
                  fullWidth
                  select
                  value={subject || 'all'}
                  onChange={(e) => setSubject(e.target.value === 'all' ? '' : e.target.value)}
                >
                  <MenuItem value="all">{t('all')}</MenuItem>
                  {subjects.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {enableCategories && s.category ? s.category.title[locale] + ' - ' : ''}
                      {s.title[locale]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ) : null}
            {disableSearch ? null : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12 - (onAdd ? 2 : 0) - (withSchoolFilter ? 3 : 0) - (withSubjectFilter ? 3 : 0)}
              >
                <TextField
                  label={t('search')}
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            {onAdd ? (
              <Grid item xs={12} sm={12} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={onAdd}
                >
                  {t('add')}
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      )}
      <Box
        id={`advanced-table-scrollable-${domId}`}
        position="relative"
        overflow="auto"
        flex={1}
        p={2}
        pt={0}
      >
        {isMobile && renderMobileItem ? (
          filteredRows.map(renderMobileItem)
        ) : (
          <Table stickyHeader={!isMobile}>
            <TableHead>
              <TableRow>
                {columns.map((c) => (
                  <TableCell
                    key={c.key}
                    style={{ backgroundColor: theme.palette.background.paper }}
                    align={c.align}
                  >
                    <TableSortLabel
                      active={sortBy === c.key}
                      disabled={c.disableSort}
                      direction={sortOrder ? 'asc' : 'desc'}
                      onClick={() => {
                        setSortBy(c.key)
                        setSortOrder(c.key === sortBy ? !sortOrder : true)
                      }}
                    >
                      {c.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.map((r) => (
                // <LazyLoad
                //   scrollContainer={`#advanced-table-scrollable-${domId}`}
                //   key={r.key}
                //   height={40}
                //   once
                //   overflow
                // >
                <TableRow
                  hover={!!r.onClick}
                  onClick={r.onClick}
                  style={r.onClick ? { cursor: 'pointer' } : {}}
                >
                  {columns.map((c) => (
                    <TableCell key={`${r.key}-${c.key}`} align={c.align}>
                      {typeof r[c.key].label === 'string' ? (
                        <Typography
                          color={
                            r[c.key].color && r[c.key].color.match(/^#/)
                              ? undefined
                              : r[c.key].color
                          }
                          style={{
                            color:
                              r[c.key].color && r[c.key].color.match(/^#/)
                                ? r[c.key].color
                                : undefined,
                            fontWeight: r[c.key].bold ? 'bold' : undefined,
                          }}
                        >
                          {r[c.key].label}
                        </Typography>
                      ) : (
                        r[c.key].label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
                // </LazyLoad>
              ))}
            </TableBody>
          </Table>
        )}
        {!filteredRows.length ? (
          <Box mt={8}>
            <Typography align="center" variant={isMobile ? 'h4' : 'h2'} color="textSecondary">
              {emptyText}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default AdvancedTable
