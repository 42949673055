import React from 'react'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const InlineEditButton = ({ value, onSubmit }) => {
  const [editting, setEditting] = React.useState(false)
  return (
    <Box my={-2} display="flex" alignItems="center">
      {editting ? (
        <TextField
          style={{ margin: 0 }}
          defaultValue={value}
          autoFocus
          multiline
          onClick={(e) => e.stopPropagation()}
          onBlur={(e) => {
            onSubmit(e.target.value)
            setEditting(false)
          }}
        />
      ) : (
        <>
          <Typography style={{ whiteSpace: 'pre-wrap' }}>{value}</Typography>
          <Box ml={1}>
            <IconButton
              size="small"
              color="primary"
              onClick={(e) => {
                e.stopPropagation()
                setEditting(true)
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </>
      )}
    </Box>
  )
}

export default InlineEditButton
