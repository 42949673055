import React from 'react'
import get from 'lodash/get'
import Box from '@material-ui/core/Box'
import HTMLContent from '../../components/HTMLContent'
import Typography from '@material-ui/core/Typography'
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import BookmarkButton from '../../components/BookmarkButton'
import { selectBookmark } from '../../misc/selectors'
import useI18n from '../../misc/languages/useI18n'
import { useTheme } from '@material-ui/core/styles'

const QuestionCard = ({ questionNo, practiceId, practice, question }) => {
  const { locale } = useI18n()
  const theme = useTheme()
  const firestore = useFirestore()
  const uid = useSelector((state) => get(state, 'firebase.auth.uid'))
  const bookmark = useSelector((state) => selectBookmark(state, practiceId, null, questionNo))
  return (
    <Box m={2} p={2} borderRadius="borderRadius" boxShadow={3} bgcolor="background.paper">
      <Box mb={2} display="flex" justifyContent="space-between">
        <Typography variant="h5">{questionNo + 1}.</Typography>
        <BookmarkButton
          value={bookmark ? 1 : 0}
          onChange={(e, v) => {
            bookmark
              ? firestore.delete({ collection: 'bookmarks', doc: bookmark.id })
              : firestore.add('bookmarks', {
                  user: uid,
                  practice: practiceId,
                  questionNo,
                  createdAt: Date.now(),
                  chapter: get(question, 'chapter', ''),
                  remark: '',
                })
          }}
        />
      </Box>
      <Box overflow="auto" maxWidth={`calc(100vw - ${theme.spacing(8)}px)`}>
        <HTMLContent html={get(question, `question.${locale}`, '')} />
      </Box>
    </Box>
  )
}

export default QuestionCard
