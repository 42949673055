import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { styled } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import get from 'lodash/get'
import { useSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import useFirebaseFunctions from '../../misc/useFirebaseFunctions'
import useI18n from '../../misc/languages/useI18n'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Close'
import cloneDeep from 'lodash/cloneDeep'

const Input = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const InviteTeacherModal = ({ isVisible, onClose }) => {
  const { t } = useI18n()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = React.useState(false)
  const [items, setItems] = React.useState([
    { email: '', shouldCreateNewAccount: false, password: '', confirmPassword: '' },
  ])
  const [inviteTeacher] = useFirebaseFunctions(['inviteTeacher'])

  const onSubmit = async () => {
    const newItems = cloneDeep(items)
    let error = false
    newItems.forEach((item) => {
      item.error = {}
      if (
        !item.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ) {
        item.error.email = t('invalid email')
        error = true
      }
      if (item.shouldCreateNewAccount && item.password.length < 6) {
        item.error.password = t('invalid password')
        error = true
      }
      if (item.shouldCreateNewAccount && item.password !== item.confirmPassword) {
        item.error.confirmPassword = t('mismatch password')
        error = true
      }
    })
    setItems(newItems)
    if (error) {
      return
    }
    setLoading(true)
    for (let i in newItems.filter((item) => !item.success)) {
      if (!newItems[i].success) {
        try {
          await inviteTeacher(newItems[i])
          newItems[i].success = true
        } catch (err) {
          if (err.code === 'not-found') {
            newItems[i].error.email = t('teacher not registered yet')
          } else if (err.code === 'internal') {
            newItems[i].error.email = t('email existed')
          } else {
            newItems[i].error.email = t('teacher already invited')
          }
          return setLoading(false)
        }
      }
    }
    setLoading(false)
    onClose()
    enqueueSnackbar(t('teacher invited wait for acceptance'))
  }

  React.useEffect(() => {
    setItems([{ email: '', shouldCreateNewAccount: false, password: '', confirmPassword: '' }])
    setLoading(false)
  }, [isVisible])

  return (
    <Dialog open={isVisible} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('invite teacher')}</DialogTitle>
      <img style={{ width: '100%' }} src={require('../../assets/images/teachers.jpg')} />
      <DialogContent style={{ paddingTop: 0 }}>
        <DialogContentText>{t('invite teacher description')}</DialogContentText>
        {items.map((item, i) => (
          <Box mb={1} key={i.toString()}>
            <Box display="flex" alignItems="flex-end">
              <Input
                className={item.success ? 'success' : ''}
                disabled={item.success}
                label={t('email')}
                value={item.email}
                error={!!get(item, 'error.email')}
                helperText={get(item, 'error.email', '')}
                fullWidth
                onChange={(e) =>
                  setItems([
                    ...items.slice(0, i),
                    { ...item, email: e.target.value },
                    ...items.slice(i + 1),
                  ])
                }
              />
              <IconButton
                onClick={() => setItems((s) => [...s.slice(0, i), ...s.slice(i + 1)])}
                color="secondary"
                disabled={items.length <= 1}
              >
                <RemoveIcon />
              </IconButton>
            </Box>
            {item.shouldCreateNewAccount ? (
              <>
                <Input
                  disabled={item.success}
                  label={t('password')}
                  value={item.password}
                  error={!!get(item, 'error.password')}
                  helperText={get(item, 'error.password', '')}
                  fullWidth
                  type="password"
                  onChange={(e) =>
                    setItems([
                      ...items.slice(0, i),
                      { ...item, password: e.target.value },
                      ...items.slice(i + 1),
                    ])
                  }
                />
                <Input
                  disabled={item.success}
                  label={t('confirm password')}
                  value={item.confirmPassword}
                  error={!!get(item, 'error.confirmPassword')}
                  helperText={get(item, 'error.confirmPassword', '')}
                  fullWidth
                  type="password"
                  onChange={(e) =>
                    setItems([
                      ...items.slice(0, i),
                      { ...item, confirmPassword: e.target.value },
                      ...items.slice(i + 1),
                    ])
                  }
                />
              </>
            ) : null}
            <FormControlLabel
              disabled={item.success}
              control={<Checkbox />}
              label={t('create new account for teacher')}
              checked={item.shouldCreateNewAccount}
              onChange={(e) =>
                setItems([
                  ...items.slice(0, i),
                  { ...item, shouldCreateNewAccount: !item.shouldCreateNewAccount },
                  ...items.slice(i + 1),
                ])
              }
            />
          </Box>
        ))}
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() =>
            setItems((ss) => [
              ...ss,
              { email: '', shouldCreateNewAccount: false, password: '', confirmPassword: '' },
            ])
          }
          color="secondary"
        >
          {t('more')}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button style={{ width: 120 }} onClick={onClose} color="secondary">
          {t('cancel')}
        </Button>
        <Button
          disabled={loading}
          style={{ width: 120 }}
          onClick={onSubmit}
          color="primary"
          autoFocus
        >
          {loading ? <CircularProgress size={24} /> : t('invite')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InviteTeacherModal
