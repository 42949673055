import React from 'react'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import PrevIcon from '@material-ui/icons/KeyboardArrowLeft'
import NextIcon from '@material-ui/icons/KeyboardArrowRight'
import CircularProgress from '@material-ui/core/CircularProgress'
import useI18n from '../../misc/languages/useI18n'

const Footer = ({ practiceId, practice, questionNo, onSubmit, loading }) => {
  const { t } = useI18n()
  return (
    <Box m={2} my={0} p={2} display="flex" justifyContent="space-between">
      <Button
        size="large"
        startIcon={<PrevIcon />}
        component={Link}
        to={`/app/working/${practiceId}/${questionNo - 1}`}
        disabled={questionNo <= 0}
      >
        {t('prev')}
      </Button>
      <Button size="large" color="primary" onClick={onSubmit} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : t('submit')}
      </Button>
      <Button
        size="large"
        endIcon={<NextIcon />}
        component={Link}
        to={`/app/working/${practiceId}/${questionNo + 1}`}
        disabled={questionNo >= get(practice, 'questions.length', 0) - 1}
      >
        {t('next')}
      </Button>
    </Box>
  )
}

export default Footer
