import React from 'react'
import { useSelector } from 'react-redux'
import MenuList from '../../components/WithAppBar/MenuList'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ContactIcon from '@material-ui/icons/ContactPhone'
import ShareIcon from '@material-ui/icons/Share'
import RateIcon from '@material-ui/icons/ThumbUp'
import SchoolsIcon from '@material-ui/icons/House'
import StudentsIcon from '@material-ui/icons/People'
import CircularProgress from '@material-ui/core/CircularProgress'
import ContactUsDialog from '../../components/ContactUsDialog'
import { selectTeachers, selectSchoolTeachers, selectBadgeCount } from '../../misc/selectors'
import useI18n from '../../misc/languages/useI18n'
import WithAppBar from '../../components/WithAppBar'
import { landingPage } from '../../config'

// This page is for mobile only

const More = () => {
  const { t } = useI18n()
  const { role, isSchool } = useSelector((state) => state.firebase.profile)
  const shouldShowTeachers = useSelector((state) => !!selectTeachers(state).length)
  const shouldShowSchool = useSelector((state) => !!selectSchoolTeachers(state).length)
  const badgeCount = useSelector(selectBadgeCount)
  const [isContactModalOpen, setIsContactModalOpen] = React.useState(false)
  const [isRating, setIsRating] = React.useState(false)

  const commonItems = [
    {
      title: t('contact us'),
      onClick: () => setIsContactModalOpen(true),
      icon: <ContactIcon />,
    },
    {
      title: t('share app'),
      onClick: () => window.ReactNativeWebView.postMessage(`share::${landingPage}`),
      icon: <ShareIcon />,
    },
    {
      title: t('rate us on app store'),
      onClick: () => {
        setIsRating(true)
        window.ReactNativeWebView.postMessage('rate_on_app_store')
      },
      icon: isRating ? <CircularProgress size={20} /> : <RateIcon />,
    },
  ]

  const menuItems = (
    role === 'teacher'
      ? [
          !isSchool && shouldShowSchool
            ? {
                title: t('my schools'),
                href: '/app/my-schools',
                icon: <SchoolsIcon />,
                badge: badgeCount.school,
              }
            : null,
          ...commonItems,
        ]
      : [
          shouldShowTeachers
            ? {
                title: t('my teachers'),
                href: '/app/my-teachers',
                icon: <StudentsIcon />,
                badge: badgeCount.student,
              }
            : null,
          ...commonItems,
        ]
  ).filter((a) => a)

  React.useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.ratedOnAppStore = () => {
        setIsRating(false)
      }
    }
  }, [])

  return (
    <WithAppBar title={t('more')} back>
      <MenuList
        fromMore
        menuItemsTop={menuItems}
        menuItemsBottom={[{ title: t('sign out'), href: '/logout', icon: <ExitToAppIcon /> }]}
      />
      <ContactUsDialog
        isVisible={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
      />
    </WithAppBar>
  )
}

export default More
