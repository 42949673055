import React from 'react'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { useHistory } from 'react-router-dom'
import format from 'date-fns/format'
import { useFirestore, useFirebase } from 'react-redux-firebase'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import InlineEditButton from '../../components/InlineEditButton'
import AdvancedTable from '../../components/AdvancedTable'
import SmallAvatarWithName from '../../components/SmallAvatarWithName'
import ButtonBase from '../../components/ButtonBase'
import { selectAssignments } from '../../misc/selectors'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import ConfirmDialog from '../../components/ConfirmDialog'
import useI18n from '../../misc/languages/useI18n'
import { enableCategories } from '../../config'
import Chip from '@material-ui/core/Chip'
import getInitial from '../../misc/getInitial'
import { firebaseConfig } from '../../config'

const Assignments = () => {
  const firestore = useFirestore()
  const theme = useTheme()
  const { t, locale } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { height: minAvailableScreenHeight } = useMinAvailableSize(
    ['#app-bar', '#tab-bar'],
    isMobile ? 0 : 4
  )
  const assignments = useSelector(selectAssignments)
  const history = useHistory()
  const { role, isSchool } = useSelector((state) => state.firebase.profile)
  const uid = useSelector((state) => state.firebase.auth.uid)
  const [startingAssignment, setStartingAssignment] = React.useState(null)

  const columns = [
    {
      key: 'status',
      label: t('status'),
    },
    isSchool
      ? {
          key: 'teacher',
          label: t('teachers'),
        }
      : null,
    {
      key: role === 'teacher' ? 'students' : 'teacher',
      label: role === 'teacher' ? t('students') : t('teachers'),
    },
    {
      key: 'subject',
      label: t('subject'),
    },
    {
      key: 'dueDate',
      label: t('due date'),
    },
    isSchool
      ? null
      : {
          key: 'timeLimit',
          label: t('time limit'),
        },
    {
      key: 'noOfQuestions',
      label: t('no of questions'),
    },
    role === 'teacher'
      ? {
          key: 'remark',
          label: t('remark'),
        }
      : null,
  ].filter((a) => a)
  const rows = assignments.map((b) => {
    const myself = role === 'student' ? b.students.find((s) => s && s.student === uid) : null
    return {
      key: b.id,
      school: {
        id: b.school || '',
      },
      onClick: async () => {
        try {
          if (role === 'teacher') {
            history.push(`/app/assignment-result/${b.id}`)
          } else if (b.practice && b.practice.finishedAt) {
            history.push(`/app/result/${b.practice.id}/0`)
          } else if (b.practice && !b.practice.finishedAt) {
            history.push(`/app/working/${b.practice.id}/0`)
          } else {
            setStartingAssignment(b)
          }
        } catch (err) {
          console.log(err)
        }
      },
      createdAt: {
        label: format(b.createdAt, 'yyyy-MM-dd'),
        sortValue: b.createdAt,
      },
      status: {
        label: (
          <Typography color={get(b, 'status.color', '')} variant="subtitle2">
            {t(get(b, 'status.label', ''))}
          </Typography>
        ),
        mobileLabel: (
          <Chip color={get(b, 'status.color', '')} label={t(get(b, 'status.label', ''))} />
        ),
        searchValue: t(get(b, 'status.label', '')),
        sortValue: t(get(b, 'status.label', '')),
      },
      teacher: {
        label: (
          <SmallAvatarWithName
            profile={get(myself, 'teacherProfile', get(b, 'students[0].teacherProfile', ''))}
            subtitle={
              uid === get(b, 'students[0].school', '')
                ? null
                : get(b, 'students[0].schoolProfile.displayName', '')
            }
          />
        ),
        searchValue:
          get(b, 'students[0].teacherProfile.displayName', '') +
          get(b, 'students[0].schoolProfile.displayName', ''),
        sortValue: get(b, 'students[0].teacherProfile.displayName', ''),
      },
      students: {
        label:
          role === 'teacher' ? (
            <Box my={isMobile ? 0 : -2}>
              <AvatarGroup max={3}>
                {b.students.map((s) => (
                  <Tooltip
                    key={s.student}
                    title={
                      (get(s, 'schoolProfile.displayName', '')
                        ? get(s, 'schoolProfile.displayName', '') + ' - '
                        : '') + get(s, 'studentProfile.displayName', '')
                    }
                  >
                    <Avatar src={get(s, 'studentProfile.avatarUrl', '')}>
                      {getInitial(get(s, 'studentProfile.displayName', ' '))}
                    </Avatar>
                  </Tooltip>
                ))}
              </AvatarGroup>
            </Box>
          ) : null,
        searchValue: b.students
          .map(
            (s) =>
              (get(s, 'schoolProfile.displayName', '')
                ? get(s, 'schoolProfile.displayName', '') + ' - '
                : '') + get(s, 'studentProfile.displayName', '')
          )
          .join(', '),
        sortValue: b.students.length,
      },
      subject: {
        label:
          (enableCategories && get(b, `subject.category`)
            ? get(b, `subject.category.title.${locale}`) + ' - '
            : '') + get(b, `subject.title.${locale}`),
        id: get(b, 'subject.id'),
      },
      dueDate: {
        label: format(b.dueDate, 'yyyy-MM-dd, hh:mm a'),
        sortValue: b.dueDate,
      },
      timeLimit: {
        label: `${b.timeLimit} ${t('minutes')}`,
        sortValue: b.timeLimit,
      },
      noOfQuestions: {
        label: b.noOfQuestions.toString(),
        sortValue: b.noOfQuestions,
      },
      remark: {
        label: (
          <InlineEditButton
            value={b.remark}
            onSubmit={(remark) =>
              firestore.update({ collection: 'assignments', doc: b.id }, { remark })
            }
          />
        ),
        sortValue: b.remark,
        searchValue: b.remark,
      },
    }
  })
  const firebase = useFirebase()
  return (
    <WithAppBar title={t('assignments')}>
      <AdvancedTable
        withSubjectFilter
        withSchoolFilter
        containerProps={{ height: minAvailableScreenHeight }}
        rows={rows}
        columns={columns}
        defaultSortBy="createdAt"
        defaultSortOrder={false}
        renderMobileItem={(row) => (
          <ButtonBase key={row.key} onClick={row.onClick}>
            <Box py={2} position="relative">
              <Box position="absolute" right={0} top={theme.spacing(2)}>
                {row.status.mobileLabel}
              </Box>
              <Box mb={1}>{role === 'student' ? row.teacher.label : row.students.label}</Box>
              <Typography variant="body2">{row.subject.label}</Typography>
              <Typography variant="body2" color="textSecondary">
                {t('due date')}: {row.dueDate.label}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {t('n questions', { n: row.noOfQuestions.label })} | {row.timeLimit.label}
              </Typography>
            </Box>
            <Divider />
          </ButtonBase>
        )}
        emptyText={t('no assignment')}
      />
      <ConfirmDialog
        isVisible={!!startingAssignment}
        onClose={() => setStartingAssignment(null)}
        message={t('confirm start assignment')}
        onSubmit={async () => {
          const params = {
            subject: startingAssignment.subject.id,
            remark: '',
            noOfQuestions: startingAssignment.noOfQuestions,
            timeLimit: startingAssignment.timeLimit,
            difficulty: startingAssignment.difficulty,
            chapters: startingAssignment.chapters,
            user: uid,
            questions: startingAssignment.questions,
            createdAt: Date.now(),
            deleted: false,
            assignment: startingAssignment.id,
            teacher: startingAssignment.teacher || '',
            school: startingAssignment.school || '',
          }
          const result = await firestore.add({ collection: 'practices' }, params)
          firebaseConfig.measurementId && firebase.analytics().logEvent('start_assignment', params)
          history.push(`/app/working/${result.id}/0`)
        }}
      />
    </WithAppBar>
  )
}

export default Assignments
