import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, combineReducers } from 'redux'
import { ReactReduxFirebaseProvider, firebaseReducer } from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore'
import { Provider } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import { composeWithDevTools } from 'redux-devtools-extension'
import { FacebookProvider } from 'react-facebook'
import * as serviceWorker from './serviceWorker'
import App from './pages/routes'
import { firebaseConfig, facebookPixelId } from './config'
import * as firebase from 'firebase/app'
import ReactPixel from 'react-facebook-pixel'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'
import 'mathquill4quill/mathquill4quill.js'
import 'mathquill4quill/mathquill4quill.css'
import 'mathquill/build/mathquill.js'
import 'mathquill/build/mathquill.css'
import 'react-quill/dist/quill.snow.css'
import './index.css'
import './misc/languages'

firebase.initializeApp(firebaseConfig)
firebase.firestore().enablePersistence({ synchronizeTabs: true })
firebaseConfig.measurementId && firebase.analytics()

facebookPixelId &&
  ReactPixel.init(
    facebookPixelId,
    {},
    {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    }
  )

const rootReducer = combineReducers({
  firebase: persistReducer({ key: 'firebase', blacklist: ['profile'], storage }, firebaseReducer),
  firestore: persistReducer({ key: 'firestore', storage }, firestoreReducer),
})

const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'development' ? composeWithDevTools() : undefined
)

const persistor = persistStore(store)

const rrfProps = {
  firebase,
  config: {
    userProfile: 'users',
    useFirestoreForProfile: true,
  },
  dispatch: store.dispatch,
  createFirestoreInstance: (fb, config, dispatch) =>
    createFirestoreInstance(fb, { ...(config || {}), allowMultipleListeners: true }, dispatch),
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <FacebookProvider appId="249371996403228">
          <App />
        </FacebookProvider>
      </ReactReduxFirebaseProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
