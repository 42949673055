import React from 'react'
import WithAppBar from '../../components/WithAppBar'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useRouteMatch, useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import QuestionsList from '../../components/QuestionsList'
import HTMLContent from '../../components/HTMLContent'
import ReportDialog from '../../components/ReportDialog'
import QuestionWithAnswersCard from '../../components/QuestionWithAnswersCard'
import { selectPractice, selectQuestion, selectAssignment } from '../../misc/selectors'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import ListIcon from '@material-ui/icons/FormatListNumbered'
import useI18n from '../../misc/languages/useI18n'

const Solution = () => {
  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 0)
  const { height: fullHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 4)
  const { height: halfHeightRaw } = useMinAvailableSize(['#app-bar', '#tab-bar'], 6)
  const halfHeight = halfHeightRaw / 2
  const theme = useTheme()
  const { t, locale } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const width = isMobile ? document.body.clientWidth - theme.spacing(4) : undefined
  const [isReportDialogOpen, setIsReportDialogOpen] = React.useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
  const {
    params: { practiceId, questionNo: questionNoString },
  } = useRouteMatch()
  const questionNo = Number(questionNoString)
  const history = useHistory()
  const { practice, isAssignment } = useSelector((state) => {
    const targetPractice = selectPractice(state, practiceId)
    const targetAssignment = selectAssignment(state, practiceId)
    return {
      practice: targetPractice || targetAssignment,
      isAssignment: !!targetAssignment,
    }
  })
  const questionId = get(practice, `questions[${questionNo}].id`)
  const question = useSelector((state) => selectQuestion(state, questionId))
  const role = useSelector((state) => state.firebase.profile.role)

  useFirestoreConnect(
    [
      questionId ? { collection: 'questions', doc: questionId } : undefined,
      get(question, 'chapter')
        ? { collection: 'chapters', doc: get(question, 'chapter') }
        : undefined,
    ].filter((a) => a)
  )

  React.useEffect(() => {
    if (role === 'student' && get(practice, 'createdAt') && !get(practice, 'finishedAt')) {
      history.push(`/app/working/${practiceId}/${questionNo}`)
    }
  }, [practice, questionNo, role])

  React.useEffect(() => {
    setIsDrawerOpen(false)
  }, [questionNo])

  return (
    <WithAppBar
      title={t('solution')}
      back
      hideRightButtonOnDesktop
      rightButtonIcon={<ListIcon />}
      onRightButtonClick={() => setIsDrawerOpen(true)}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={9} lg={10}>
          <Box
            height={minAvailableScreenHeight}
            pb={`${get(window, 'ReactNativeWebView.bottomSpace', 0)}px`}
            overflow="auto"
          >
            <QuestionWithAnswersCard
              questionNo={questionNo}
              question={question}
              practiceId={isAssignment ? '' : practiceId}
              assignmentId={role === 'teacher' ? get(practice, 'assignment', practiceId) : ''}
              width={width}
              correctAns={get(practice, `questions[${questionNo}].correctAns`)}
              ans={get(practice, `questions[${questionNo}].ans`)}
            />
            <Box
              width={width}
              m={2}
              p={2}
              borderRadius="borderRadius"
              overflow="hidden"
              boxShadow={3}
              bgcolor="background.paper"
              overflow="auto"
            >
              <Box mb={2}>
                <Typography variant="h6" color="primary">
                  {t('solution')}
                </Typography>
              </Box>
              <HTMLContent html={get(question, `solution.${locale}`)} />
            </Box>
            <Hidden mdUp>
              <Box m={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => setIsReportDialogOpen(true)}
                >
                  {t('report question')}
                </Button>
              </Box>
            </Hidden>
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid item md={3} lg={2}>
            <Box height={fullHeight} display="flex" flexDirection="column" m={2} ml={0}>
              <Box
                borderRadius="borderRadius"
                overflow="hidden"
                boxShadow={3}
                bgcolor="background.paper"
                flex={1}
                mb={2}
                overflow="auto"
              >
                <QuestionsList
                  practiceId={practiceId}
                  practice={practice}
                  questionNo={questionNo}
                  isAssignment={isAssignment}
                />
              </Box>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => setIsReportDialogOpen(true)}
              >
                {t('report question')}
              </Button>
            </Box>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <SwipeableDrawer
            disableSwipeToOpen={false}
            open={isDrawerOpen}
            onOpen={() => setIsDrawerOpen(true)}
            onClose={() => setIsDrawerOpen(false)}
            anchor="right"
          >
            <QuestionsList
              practiceId={practiceId}
              practice={practice}
              questionNo={questionNo}
              isAssignment={isAssignment}
            />
          </SwipeableDrawer>
        </Hidden>
      </Grid>
      <ReportDialog
        isVisible={isReportDialogOpen}
        onClose={() => setIsReportDialogOpen(false)}
        questionId={questionId}
        subject={get(question, 'subject')}
        chapter={get(question, 'chapter')}
      />
    </WithAppBar>
  )
}

export default Solution
