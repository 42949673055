import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import SmallAvatarWithName from '../SmallAvatarWithName'
import useI18n from '../../misc/languages/useI18n'
import { useSelector } from 'react-redux'
import { selectSchoolTeachers } from '../../misc/selectors'
import SearchIcon from '@material-ui/icons/Search'
import get from 'lodash/get'
import { useTheme } from '@material-ui/core/styles'

const StudentsSelector = ({
  selectedStudents,
  students,
  setSelectedStudents,
  school,
  setSchool,
}) => {
  const { t } = useI18n()
  const theme = useTheme()
  const isSchool = useSelector((state) => state.firebase.profile.isSchool)
  const uid = useSelector((state) => state.firebase.auth.uid)
  const schools = useSelector(selectSchoolTeachers)
  const [search, setSearch] = React.useState('')

  const data = students
    .filter((s) => (isSchool ? s.school === uid : school === '' ? !s.school : s.school === school))
    .filter((s) =>
      get(s, 'studentProfile.displayName', '').toLowerCase().includes(search.toLowerCase())
    )

  return (
    <>
      {!isSchool && schools.length ? (
        <Box mb={2}>
          <TextField
            label={t('school')}
            fullWidth
            select
            value={school || 'my-students'}
            onChange={(e) => {
              setSchool(e.target.value === 'my-students' ? '' : e.target.value)
              setSelectedStudents([])
            }}
          >
            <MenuItem value="my-students">{t('my students')}</MenuItem>
            {schools.map((s) => (
              <MenuItem
                style={{
                  paddingBottom: theme.spacing(1),
                  paddingTop: theme.spacing(1),
                  height: theme.spacing(6),
                }}
                key={s.school}
                value={s.school}
              >
                <SmallAvatarWithName profile={s.schoolProfile} />
              </MenuItem>
            ))}
          </TextField>
        </Box>
      ) : null}
      <Box mb={2}>
        <TextField
          placeholder={t('search')}
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        border={1}
        borderColor="divider"
        borderTop={0}
        borderLeft={0}
        borderRight={0}
      >
        <Typography variant="subtitle2">
          {t('select students')}: {selectedStudents.length}/{data.length}
        </Typography>
        <Checkbox
          checked={data.length && selectedStudents.length === data.length}
          onChange={(e) => setSelectedStudents(e.target.checked ? data.map((s) => s.student) : [])}
        />
      </Box>
      {data.length ? (
        data.map((s) => (
          <Box key={s.id} display="flex" justifyContent="space-between" alignItems="center" my={2}>
            <SmallAvatarWithName width="calc(100% - 42px)" profile={s.studentProfile} />
            <Checkbox
              checked={selectedStudents.includes(s.student)}
              onChange={(e) =>
                setSelectedStudents(
                  e.target.checked
                    ? [...selectedStudents, s.student]
                    : selectedStudents.filter((v) => v !== s.student)
                )
              }
            />
          </Box>
        ))
      ) : (
        <Box mt={2}>
          <Typography align="center" variant="h4" color="textSecondary">
            {t('no student')}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default StudentsSelector
