import React from 'react'
import Box from '@material-ui/core/Box'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import ConfirmDialog from '../ConfirmDialog'
import useI18n from '../../misc/languages/useI18n'

const StatCard = ({ title, value, onClick, active, containerProps, color, masked }) => {
  const theme = useTheme()
  const { t } = useI18n()
  const [isMasked, setIsMasked] = React.useState(true)
  const [isDialogVisible, setIsDialogVisible] = React.useState(false)
  return (
    <Box
      borderRadius="borderRadius"
      overflow="hidden"
      boxShadow={3}
      bgcolor="background.paper"
      display="flex"
      {...containerProps}
    >
      <ButtonBase
        style={{ flex: 1 }}
        onClick={masked && isMasked ? () => setIsDialogVisible(true) : onClick}
      >
        <Box
          p={2}
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          borderBottom={`4px solid ${active ? theme.palette.primary[500] : 'transparent'}`}
        >
          <Typography variant="h6" color="primary">
            {title}
          </Typography>
          <Typography
            variant="h4"
            style={{ color, filter: masked && isMasked ? 'blur(10px)' : undefined }}
          >
            {value}
          </Typography>
        </Box>
      </ButtonBase>
      {masked ? (
        <ConfirmDialog
          title={t('warning')}
          message={t('unmask')}
          isVisible={isDialogVisible}
          onClose={() => setIsDialogVisible(false)}
          onSubmit={() => {
            setIsDialogVisible(false)
            setIsMasked(false)
            onClick()
          }}
        />
      ) : null}
    </Box>
  )
}

export default StatCard
