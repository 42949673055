import React from 'react'
import Box from '@material-ui/core/Box'
import { useForm, Controller } from 'react-hook-form'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import get from 'lodash/get'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Rating from '@material-ui/lab/Rating'
import MenuItem from '@material-ui/core/MenuItem'
import { useFirestoreConnect, useFirebase } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import CheckboxGroup from '../../components/CheckboxGroup'
import {
  selectSectionsAndChaptersBySubject,
  selectIsPremiumFeaturesPermitted,
  selectAllSubjects,
  selectCategories,
} from '../../misc/selectors'
import { maxNoOfQuestions, maxTimeLimit, premiumConfig, enableCategories } from '../../config'
import { DateTimePicker } from '@material-ui/pickers'
import scrollParentToChild from '../../misc/scrollParentToChild'
import useI18n from '../../misc/languages/useI18n'
import PremiumDialog from '../PremiumDialog'
import { useSnackbar } from 'notistack'

const GeneratePracticeForm = ({
  onSubmit: submit,
  loading,
  submitButtonText,
  withDueDate,
  ignorePremium,
}) => {
  const { t, locale } = useI18n()
  const firebase = useFirebase()
  const { enqueueSnackbar } = useSnackbar()
  const premiumStatus = useSelector(selectIsPremiumFeaturesPermitted)
  const { isAvailable, isWithinDailyLimit } = premiumStatus
  const { handleSubmit, control, errors, watch, setValue, getValues } = useForm({
    reValidateMode: 'onSubmit',
  })
  const watchSubject = watch('subject')
  const watchCategory = watch('category')
  const subjects = useSelector(selectAllSubjects)
  const categories = useSelector((state) => selectCategories(state).filter((s) => s.published))
  const sections = useSelector((state) => selectSectionsAndChaptersBySubject(state, watchSubject))
  const defaultCategory = useSelector((state) => get(state, 'firebase.profile.defaultCategory', ''))
  const [isPremiumDialogVisible, setIsPremiumDialogVisible] = React.useState(false)

  const onSubmit = (params) => {
    window.localStorage.removeItem('params')
    if (
      ignorePremium ||
      isAvailable ||
      (isWithinDailyLimit && params.noOfQuestions <= premiumConfig.freeLimit.questionPerPractice)
    ) {
      submit(params)
    } else {
      window.localStorage.setItem('params', JSON.stringify(params))
      setIsPremiumDialogVisible(true)
    }
  }

  useFirestoreConnect([
    { collection: 'chapters', where: [['subject', '==', watchSubject || '']] },
    { collection: 'sections', where: [['subject', '==', watchSubject || '']] },
  ])

  const selectAllChapter = (section) => () => {
    const sectionsFormValue = getValues()[`section-${section}`] || []
    const selectSection = sections.find((s) => s.id === section)
    const selectSectionChapters = selectSection ? selectSection.chapters.filter(c => c.published) : []
    setValue(
      `section-${section}`,
      sectionsFormValue.length === selectSectionChapters.length
        ? []
        : selectSectionChapters.map((c) => c.id)
    )
  }

  React.useEffect(() => {
    Object.keys(errors || {}).some((err) => {
      if (err) {
        scrollParentToChild(
          document.getElementById('scroll-parent'),
          document.getElementsByName(err)[0],
          100
        )
        return true
      } else {
        return false
      }
    })
  }, [errors])

  React.useEffect(() => {
    const tempParams = window.localStorage.getItem('params')
    if (isAvailable && tempParams) {
      onSubmit(JSON.parse(tempParams))
    }
  }, [isAvailable])

  React.useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.sharedOnFacebook = () => {
        // Shared
        firebase.updateProfile({ sharedOnFacebookAt: Date.now() })
        enqueueSnackbar(t('thank you for sharing'))
      }
    }
  }, [])

  React.useEffect(() => {
    watchCategory &&
      firebase.updateProfile({
        defaultCategory: watchCategory,
      })
  }, [watchCategory])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        m={2}
        p={2}
        borderRadius="borderRadius"
        overflow="hidden"
        boxShadow={3}
        bgcolor="background.paper"
      >
        <Grid container spacing={2} justify="center">
          {withDueDate ? (
            <Grid item xs={12} sm={12} md={withDueDate ? 6 : 12}>
              <Controller
                as={DateTimePicker}
                name="dueDate"
                format="yyyy-MM-dd, hh:mm a"
                label={t('due date')}
                defaultValue={null}
                disablePast
                fullWidth
                clearable
                control={control}
                error={!!errors.dueDate}
                helperText={get(errors, 'dueDate.message')}
                rules={{ required: true }}
              />
            </Grid>
          ) : null}
          {enableCategories ? (
            <Grid item xs={12} sm={12} md={withDueDate ? 3 : 6}>
              <Controller
                as={TextField}
                name="category"
                label={t('category')}
                control={control}
                error={!!errors.category}
                helperText={get(errors, 'category.message')}
                rules={{ required: true }}
                fullWidth
                select
                defaultValue={categories.length === 1 ? categories[0].id : defaultCategory}
              >
                {categories.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.title[locale]}
                  </MenuItem>
                ))}
              </Controller>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={12} md={(withDueDate ? 6 : 12) / (enableCategories ? 2 : 1)}>
            <Controller
              as={TextField}
              name="subject"
              label={t('subject')}
              control={control}
              error={!!errors.subject}
              helperText={get(errors, 'subject.message')}
              rules={{ required: true }}
              fullWidth
              select
              defaultValue=""
              disabled={enableCategories && !watchCategory}
            >
              {subjects
                .filter(
                  (s) => s.published && (enableCategories ? s.category === watchCategory : true)
                )
                .map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.title[locale]}
                  </MenuItem>
                ))}
            </Controller>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              as={TextField}
              name="noOfQuestions"
              label={t('no of questions 1 - n', { n: maxNoOfQuestions })}
              control={control}
              defaultValue=""
              type="number"
              error={!!errors.noOfQuestions}
              helperText={get(errors, 'noOfQuestions.message')}
              rules={{ required: true, max: maxNoOfQuestions, min: 1 }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              as={TextField}
              name="timeLimit"
              label={t('time limit 1 - n min', { n: maxTimeLimit })}
              control={control}
              defaultValue=""
              type="number"
              error={!!errors.timeLimit}
              helperText={get(errors, 'timeLimit.message')}
              rules={{ required: true, max: maxTimeLimit, min: 1 }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box ml={2}>
              <Typography color={errors.difficulty ? 'error' : 'initial'} variant="body2">
                {t('difficulty')}
              </Typography>
              <Controller
                as={Rating}
                name="difficulty"
                control={control}
                onChange={([e]) => Number(e.target.value)}
                rules={{ required: true, min: 1 }}
                size="large"
              />
            </Box>
          </Grid>
          {sections
            .filter((s) => s.published)
            .map((s) => (
              <Grid
                key={s.id}
                item
                xs={12}
                sm={12}
                md={4}
                // lg={Math.min(4, Math.max(2, Math.floor(12 / sections.length)))}
              >
                <Button fullWidth onClick={selectAllChapter(s.id)} color="primary" variant="outlined">
                  {s.title[locale]}
                </Button>
                <Controller
                  as={CheckboxGroup}
                  name={`section-${s.id}`}
                  control={control}
                  options={s.chapters
                    .filter((s) => s.published)
                    .map((c) => ({
                      value: c.id,
                      label: `${c.chapterNo}. ${c.title[locale]}`,
                    }))}
                />
              </Grid>
            ))}
          <Grid item xs={12}>
            <Box mt={4} display="flex" justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ width: 240 }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : submitButtonText}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <PremiumDialog
        isVisible={isPremiumDialogVisible}
        onClose={() => {
          window.localStorage.removeItem('params')
          setIsPremiumDialogVisible(false)
        }}
      />
    </form>
  )
}

export default GeneratePracticeForm
