import React from 'react'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { useHistory } from 'react-router-dom'
import format from 'date-fns/format'
import InlineEditButton from '../../components/InlineEditButton'
import { useFirestore } from 'react-redux-firebase'
import AdvancedTable from '../../components/AdvancedTable'
import { selectPracticesWithSubjectAndGrade } from '../../misc/selectors'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import ButtonBase from '../../components/ButtonBase'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import useI18n from '../../misc/languages/useI18n'
import { enableCategories } from '../../config'

const PracticeHistory = () => {
  const firestore = useFirestore()
  const history = useHistory()
  const theme = useTheme()
  const { t, locale } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { height: minAvailableScreenHeight } = useMinAvailableSize(
    ['#app-bar', '#tab-bar'],
    isMobile ? 0 : 4
  )
  const practices = useSelector(selectPracticesWithSubjectAndGrade)
  const columns = [
    {
      key: 'date',
      label: t('date'),
    },
    {
      key: 'subject',
      label: t('subject'),
    },
    {
      key: 'noOfQuestions',
      label: t('no of questions'),
      align: 'center',
    },
    {
      key: 'timeLimit',
      label: t('time limit'),
    },
    {
      key: 'score',
      label: t('score'),
    },
    {
      key: 'grade',
      label: t('grade'),
    },
    {
      key: 'remark',
      label: t('remark'),
    },
  ]
  const rows = practices.map((p) => ({
    key: p.id,
    onClick: () => history.push(p.finishedAt ? `/app/result/${p.id}` : `/app/working/${p.id}/0`),
    date: {
      label: format(p.createdAt, 'yyyy-MM-dd'),
      sortValue: p.createdAt,
    },
    subject: {
      label:
        (enableCategories && get(p, `subject.category`)
          ? get(p, `subject.category.title.${locale}`) + ' - '
          : '') + get(p, `subject.title.${locale}`),
      id: get(p, 'subject.id'),
    },
    timeLimit: {
      label: `${p.timeLimit} ${t('minutes')}`,
      sortValue: p.timeLimit,
    },
    noOfQuestions: {
      label: `${p.noOfQuestions}`,
      sortValue: p.noOfQuestions,
    },
    score: {
      label: p.finishedAt
        ? `${p.score} / ${p.noOfQuestions} (${Math.round((100 * p.score) / p.noOfQuestions)}%)`
        : t('not finished'),
      sortValue: p.score / p.noOfQuestions,
    },
    grade: {
      label: p.finishedAt ? get(p, 'grade.grade') : '',
      sortValue: p.score / p.noOfQuestions,
      color: get(p, 'grade.color'),
      bold: true,
    },
    remark: {
      label: (
        <InlineEditButton
          value={p.remark}
          onSubmit={(remark) =>
            firestore.update({ collection: 'practices', doc: p.id }, { remark })
          }
        />
      ),
      sortValue: p.remark,
      searchValue: p.remark,
    },
  }))

  return (
    <WithAppBar title={t('practice history')}>
      <AdvancedTable
        withSubjectFilter
        containerProps={{
          height: minAvailableScreenHeight,
        }}
        rows={rows}
        columns={columns}
        defaultSortBy="date"
        defaultSortOrder={false}
        renderMobileItem={(row) => (
          <ButtonBase key={row.key} onClick={row.onClick}>
            <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography>{row.subject.label}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {row.date.label} | {t('n questions', { n: row.noOfQuestions.label })} |{' '}
                  {row.timeLimit.label}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {row.score.label}
                </Typography>
              </Box>
              {row.grade.label ? (
                <Box
                  bgcolor={row.grade.color}
                  borderRadius="50%"
                  width={50}
                  height={50}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h5" style={{ color: 'white' }}>
                    {row.grade.label}
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Divider />
          </ButtonBase>
        )}
        emptyText={t('no practice')}
      />
    </WithAppBar>
  )
}

export default PracticeHistory
