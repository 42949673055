import React from 'react'
import Box from '@material-ui/core/Box'
import MenuIcon from '@material-ui/icons/Menu'
import EditIcon from '@material-ui/icons/Edit'
import PieChartIcon from '@material-ui/icons/PieChart'
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark'
import ProfileIcon from '@material-ui/icons/AccountCircle'
import BookmarksIcon from '@material-ui/icons/Bookmarks'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SchoolsIcon from '@material-ui/icons/House'
import TeachersIcon from '@material-ui/icons/School'
import { styled, useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Hidden from '@material-ui/core/Hidden'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import BackIcon from '@material-ui/icons/ArrowBack'
import SubjectIcon from '@material-ui/icons/LocalLibrary'
import ChapterIcon from '@material-ui/icons/MenuBook'
import QuestionIcon from '@material-ui/icons/LibraryBooks'
import AdminIcon from '@material-ui/icons/People'
import ReportIcon from '@material-ui/icons/Report'
import StudentsIcon from '@material-ui/icons/People'
import AddIcon from '@material-ui/icons/Add'
import get from 'lodash/get'
import ContactIcon from '@material-ui/icons/ContactPhone'
import CategoriesIcon from '@material-ui/icons/Apps'
import AssignmentIcon from '@material-ui/icons/Assignment'
import {
  selectBadgeCount,
  selectTeachers,
  selectSchoolTeachers,
  selectIsFreeTrial,
  selectStudents,
} from '../../misc/selectors'
import ContactUsDialog from '../ContactUsDialog'
import useI18n from '../../misc/languages/useI18n'
import { enableCategories } from '../../config'
import MenuList from './MenuList'
import TabBar from './TabBar'
import InviteTeacherModal from '../InviteTeacherModal'
import InviteStudentModal from '../InviteStudentModal'

export const MenuWidth = 240

const Background = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.palette.background.default,
}))

const TopBar = styled(({ isMobile, ...props }) => <AppBar {...props} />)(({ isMobile }) => ({
  left: isMobile ? 0 : MenuWidth,
  paddingTop: get(window, 'ReactNativeWebView.statusBarHeight', 0),
}))

const MenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
}))

const Offset = styled(Box)(({ theme }) => ({
  ...theme.mixins.toolbar,
  marginTop: get(window, 'ReactNativeWebView.statusBarHeight', 0),
}))

const WithAppBar = ({
  children,
  title,
  back,
  onBack,
  rightButtonIcon,
  onRightButtonClick,
  hideRightButtonOnDesktop,
}) => {
  const { t } = useI18n()
  const theme = useTheme()
  const history = useHistory()
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })

  const isFreeTrial = useSelector(selectIsFreeTrial)
  const { premium, isSchool, role } = useSelector((state) => state.firebase.profile)
  const teachersCount = useSelector((state) => selectSchoolTeachers(state).length)
  const studentsCount = useSelector((state) => selectStudents(state).length)

  const shouldShowTeachers = useSelector((state) => !!selectTeachers(state).length)
  const shouldShowSchool = useSelector((state) => !!selectSchoolTeachers(state).length)
  const reportsCount = useSelector((state) => get(state, 'firestore.ordered.reports.length', 0))
  const badgeCount = useSelector(selectBadgeCount)

  const [isContactModalOpen, setIsContactModalOpen] = React.useState(false)

  let menuItemsTop = []
  if (role === 'super-admin') {
    menuItemsTop = [
      enableCategories
        ? {
            title: t('manage categories'),
            href: '/app/manage-categories',
            icon: <CategoriesIcon />,
          }
        : null,
      { title: t('manage subjects'), href: '/app/manage-subjects', icon: <SubjectIcon /> },
      { title: t('manage chapters'), href: '/app/manage-chapters', icon: <ChapterIcon /> },
      { title: t('manage questions'), href: '/app/manage-questions', icon: <QuestionIcon /> },
      { title: t('admins'), href: '/app/admins', icon: <AdminIcon /> },
      { title: t('reports'), href: '/app/reports', icon: <ReportIcon />, badge: reportsCount },
    ].filter((a) => a)
  } else if (role === 'admin') {
    menuItemsTop = [
      { title: t('manage questions'), href: '/app/manage-questions', icon: <QuestionIcon /> },
      { title: t('reports'), href: '/app/reports', icon: <ReportIcon /> },
    ]
  } else if (role === 'teacher') {
    menuItemsTop = [
      { title: t('new assignment'), href: '/app/create-assignment', icon: <AddIcon /> },
      isSchool
        ? { title: t('my teachers'), href: '/app/my-teachers', icon: <TeachersIcon /> }
        : shouldShowSchool
        ? {
            title: t('my schools'),
            href: '/app/my-schools',
            icon: <SchoolsIcon />,
            badge: badgeCount.school,
          }
        : null,
      { title: t('my students'), href: '/app/my-students', icon: <StudentsIcon /> },
      {
        title: t('assignments'),
        href: '/app/assignments',
        icon: <AssignmentIcon />,
        badge: badgeCount.assignment,
      },
      { title: t('bookmarks'), href: '/app/bookmarks', icon: <BookmarksIcon /> },
    ].filter((a) => a)
  } else {
    menuItemsTop = [
      { title: t('free practice'), href: '/app/free-practice', icon: <EditIcon /> },
      shouldShowTeachers
        ? {
            title: t('assignments'),
            href: '/app/assignments',
            icon: <AssignmentIcon />,
            badge: badgeCount.assignment,
          }
        : null,
      shouldShowTeachers
        ? {
            title: t('my teachers'),
            href: '/app/my-teachers',
            icon: <StudentsIcon />,
            badge: badgeCount.student,
          }
        : null,
      { title: t('statistics'), href: '/app/statistics', icon: <PieChartIcon /> },
      {
        title: t('practice history'),
        href: '/app/practice-history',
        icon: <CollectionsBookmarkIcon />,
      },
      { title: t('bookmarks'), href: '/app/bookmarks', icon: <BookmarksIcon /> },
    ].filter((a) => a)
  }

  const menuItemsBottom = [
    { title: t('profile'), href: '/app/profile', icon: <ProfileIcon /> },
    { title: t('contact us'), onClick: () => setIsContactModalOpen(true), icon: <ContactIcon /> },
    { title: t('sign out'), href: '/logout', icon: <ExitToAppIcon /> },
  ]

  const tabs = (
    role === 'teacher'
      ? [
          { title: t('new assignment'), href: '/app/create-assignment', icon: <AddIcon /> },
          isSchool
            ? { title: t('my teachers'), href: '/app/my-teachers', icon: <TeachersIcon /> }
            : null,
          { title: t('my students'), href: '/app/my-students', icon: <StudentsIcon /> },
          {
            title: t('assignments'),
            href: '/app/assignments',
            icon: <AssignmentIcon />,
            badge: badgeCount.assignment,
          },
          { title: t('bookmarks'), href: '/app/bookmarks', icon: <BookmarksIcon /> },
          {
            title: t('profile'),
            href: '/app/profile',
            icon: <ProfileIcon />,
            badge: badgeCount.school,
          },
        ]
      : role === 'student'
      ? [
          { title: t('free practice'), href: '/app/free-practice', icon: <EditIcon /> },
          shouldShowTeachers
            ? {
                title: t('assignments'),
                href: '/app/assignments',
                icon: <AssignmentIcon />,
                badge: badgeCount.assignment,
              }
            : null,
          { title: t('statistics'), href: '/app/statistics', icon: <PieChartIcon /> },
          {
            title: t('practice history'),
            href: '/app/practice-history',
            icon: <CollectionsBookmarkIcon />,
          },
          { title: t('bookmarks'), href: '/app/bookmarks', icon: <BookmarksIcon /> },
          {
            title: t('profile'),
            href: '/app/profile',
            icon: <ProfileIcon />,
            badge: badgeCount.student,
          },
        ]
      : []
  ).filter((a) => a)

  const showModal = React.useCallback(() => {
    if (isSchool && teachersCount === 0) {
      const shouldShow =
        Date.now() - Number(localStorage.getItem('lastShowTeacherModal') || 0) > 1000 * 3600
      if (shouldShow) {
        localStorage.setItem('lastShowTeacherModal', Date.now())
        setIsInviteTeacherModalVisible(true)
      }
    }
    if (
      (isSchool && teachersCount > 0 && studentsCount === 0) ||
      (role === 'teacher' && !isSchool && studentsCount === 0)
    ) {
      const shouldShow =
        Date.now() - Number(localStorage.getItem('lastShowStudentModal') || 0) > 1000 * 3600
      if (shouldShow) {
        localStorage.setItem('lastShowStudentModal', Date.now())
        setIsInviteStudentModalVisible(true)
      }
    }
  }, [studentsCount, teachersCount])

  React.useEffect(() => {
    if (
      isSchool &&
      !isFreeTrial &&
      (studentsCount > get(premium, 'student.quantity', 0) ||
        teachersCount > get(premium, 'teacher.quantity', 0))
    ) {
      history.replace('/app/profile')
    }
    setTimeout(showModal, 1000)
  }, [isSchool, premium, studentsCount, teachersCount, isFreeTrial])

  const isTabView = ['student', 'teacher'].includes(role) && isMobile && !back

  const [isInviteTeacherModalVisible, setIsInviteTeacherModalVisible] = React.useState(false)
  const [isInviteStudentModalVisible, setIsInviteStudentModalVisible] = React.useState(false)

  return (
    <>
      <Background>
        <Hidden smDown>
          <MenuList
            id="md-menu"
            isMobile={isMobile}
            menuItemsTop={menuItemsTop}
            menuItemsBottom={menuItemsBottom}
          />
        </Hidden>
        <Box flex={1}>
          <TopBar position="fixed" isMobile={isMobile} id="app-bar">
            <Toolbar>
              <Box display="flex" flex={1} alignItems="center">
                {back ? (
                  <MenuButton
                    edge="start"
                    color="inherit"
                    onClick={() => (onBack ? onBack() : history.goBack())}
                  >
                    <BackIcon />
                  </MenuButton>
                ) : isTabView ? null : (
                  <Hidden mdUp>
                    <MenuButton edge="start" color="inherit" onClick={() => setIsDrawerOpen(true)}>
                      <MenuIcon />
                    </MenuButton>
                  </Hidden>
                )}
                <Box position={isTabView ? 'absolute' : 'static'} left={0} right={0} zIndex={-1}>
                  <Typography align={isTabView ? 'center' : undefined} variant="h6">
                    {title}
                  </Typography>
                </Box>
              </Box>
              {rightButtonIcon && (isMobile || !hideRightButtonOnDesktop) ? (
                <MenuButton
                  style={{ marginRight: isMobile ? 0 : MenuWidth }}
                  color="inherit"
                  onClick={onRightButtonClick}
                >
                  {rightButtonIcon}
                </MenuButton>
              ) : null}
            </Toolbar>
          </TopBar>
          <Offset />
          {children}
          {isTabView ? <TabBar tabs={tabs} /> : null}
        </Box>
      </Background>
      {isTabView ? null : (
        <SwipeableDrawer
          disableSwipeToOpen={back ? true : false}
          open={isDrawerOpen}
          onOpen={() => setIsDrawerOpen(true)}
          onClose={() => setIsDrawerOpen(false)}
        >
          <MenuList
            menuItemsTop={menuItemsTop}
            menuItemsBottom={menuItemsBottom}
            isMobile={isMobile}
          />
        </SwipeableDrawer>
      )}
      <ContactUsDialog
        isVisible={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
      />
      <InviteTeacherModal
        isVisible={isInviteTeacherModalVisible}
        onClose={() => setIsInviteTeacherModalVisible(false)}
      />
      <InviteStudentModal
        isVisible={isInviteStudentModalVisible}
        onClose={() => setIsInviteStudentModalVisible(false)}
      />
    </>
  )
}

export default WithAppBar
