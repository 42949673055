import React from 'react'
import { useFirestore, useFirestoreConnect, useFirebase } from 'react-redux-firebase'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import AddIcon from '@material-ui/icons/Add'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import DownIcon from '@material-ui/icons/KeyboardArrowDown'
import RemoveIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import SortableList from '../../components/SortableList'
import ConfirmDialog from '../../components/ConfirmDialog'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { availableLanguages, enableCategories } from '../../config'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import {
  selectSubjects,
  selectSectionsAndChaptersBySubject,
  selectLatestSubject,
} from '../../misc/selectors'
import IconButton from '@material-ui/core/IconButton'
import max from 'lodash/max'
import { useSnackbar } from 'notistack'
import useI18n from '../../misc/languages/useI18n'

const ManageChapters = () => {
  const firestore = useFirestore()
  const firebase = useFirebase()
  const { t, locale } = useI18n()
  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 0)
  const subjects = useSelector(selectSubjects)
  const defaultSubject = useSelector((state) => state.firebase.profile.defaultSubject)
  const [subject, setSubject] = React.useState(defaultSubject)
  const sections = useSelector((state) => selectSectionsAndChaptersBySubject(state, subject))
  const { enqueueSnackbar } = useSnackbar()
  const [isItemExpanded, setIsItemExpanded] = React.useState({})
  const [deletingSectionId, setDeletingSectionId] = React.useState(null)
  const [deletingChapterId, setDeletingChapterId] = React.useState(null)
  return (
    <WithAppBar title={t('manage chapters')}>
      <Box height={minAvailableScreenHeight} overflow="auto">
        <Box m={2}>
          <TextField
            label={t('subject')}
            fullWidth
            select
            defaultValue={subject}
            onChange={(e) => {
              setSubject(e.target.value)
              firebase.updateProfile({ defaultSubject: e.target.value })
            }}
          >
            {subjects.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {enableCategories && s.category ? s.category.title[locale] + ' - ' : ''}
                {s.title[locale]}
              </MenuItem>
            ))}
          </TextField>
          <Box mt={2}>
            <Typography variant="h5">{t('sections')}</Typography>
          </Box>
          <SortableList
            defaultItems={sections}
            onSort={(result) =>
              result.map((r, order) =>
                firestore.update({ collection: 'sections', doc: r.id }, { order })
              )
            }
            renderRightItem={(section) => (
              <Box display="flex">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={section.published}
                      onChange={(e) =>
                        firestore
                          .collection('sections')
                          .doc(section.id)
                          .update({ published: e.target.checked })
                      }
                      color="secondary"
                    />
                  }
                  label={t('publish')}
                />
                <IconButton
                  onClick={() => setIsItemExpanded({ [section.id]: !isItemExpanded[section.id] })}
                >
                  {isItemExpanded[section.id] ? <UpIcon /> : <DownIcon />}
                </IconButton>
                <IconButton
                  color="secondary"
                  disabled={sections.length <= 1}
                  onClick={() => setDeletingSectionId(section.id)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            )}
            renderCenterItem={(section) => (
              <Box>
                <Box mb={1}>
                  <Typography variant="h6">{t('title')}</Typography>
                </Box>
                <Grid container spacing={2}>
                  {availableLanguages.map((lang) => (
                    <Grid key={lang.value} item xs={12} sm={12} md={6}>
                      <TextField
                        label={lang.label}
                        defaultValue={get(section, `title.${lang.value}`)}
                        onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
                        onBlur={(e) =>
                          firestore.update(
                            { collection: 'sections', doc: section.id },
                            { [`title.${lang.value}`]: e.target.value }
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                  ))}
                </Grid>
                {isItemExpanded[section.id] ? (
                  <>
                    <Box mt={2} mb={1}>
                      <Typography variant="h6">{t('chapters')}</Typography>
                    </Box>
                    {section.chapters.map((chapter, index) => (
                      <Grid key={chapter.id} container spacing={2}>
                        <Grid item xs={12} sm={12} md={2}>
                          <TextField
                            label="Chapter Number"
                            defaultValue={chapter.chapterNo}
                            onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
                            onBlur={(e) =>
                              firestore.update(
                                { collection: 'chapters', doc: chapter.id },
                                { chapterNo: e.target.value }
                              )
                            }
                            type="number"
                            fullWidth
                          />
                        </Grid>
                        {availableLanguages.map((lang) => (
                          <Grid key={lang.value} item xs={12} sm={12} md={4}>
                            <TextField
                              label={lang.label}
                              defaultValue={get(chapter, `title.${lang.value}`)}
                              onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
                              onBlur={(e) =>
                                firestore.update(
                                  { collection: 'chapters', doc: chapter.id },
                                  { [`title.${lang.value}`]: e.target.value }
                                )
                              }
                              fullWidth
                            />
                          </Grid>
                        ))}
                        <Grid item xs={12} sm={12} md={2}>
                          <Box display="flex">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={chapter.published}
                                  onChange={(e) =>
                                    firestore
                                      .collection('chapters')
                                      .doc(chapter.id)
                                      .update({ published: e.target.checked })
                                  }
                                  color="secondary"
                                />
                              }
                              label={t('publish')}
                            />
                            <IconButton
                              color="secondary"
                              onClick={() => setDeletingChapterId(chapter.id)}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                    <Box mt={2}>
                      <Button
                        startIcon={<AddIcon />}
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          firestore.add(
                            { collection: 'chapters' },
                            {
                              subject,
                              section: section.id,
                              title: {},
                              deleted: false,
                              chapterNo:
                                (max(section.chapters.map((c) => Number(c.chapterNo))) || 0) + 1,
                            }
                          )
                        }}
                      >
                        {t('add chapter')}
                      </Button>
                    </Box>
                  </>
                ) : null}
              </Box>
            )}
          />
          <Button
            startIcon={<AddIcon />}
            color="secondary"
            variant="contained"
            onClick={async () => {
              const result = await firestore.add(
                { collection: 'sections' },
                { order: sections.length, subject, title: {}, deleted: false }
              )
              setIsItemExpanded({ [result.id]: true })
            }}
          >
            {t('add section')}
          </Button>
        </Box>
      </Box>
      <ConfirmDialog
        isVisible={!!deletingSectionId}
        onClose={() => setDeletingSectionId(null)}
        message={t('confirm delete section')}
        onSubmit={() => {
          firestore.update({ collection: 'sections', doc: deletingSectionId }, { deleted: true })
          enqueueSnackbar(t('section deleted'), { key: deletingSectionId })
          setDeletingSectionId(null)
        }}
      />
      <ConfirmDialog
        isVisible={!!deletingChapterId}
        onClose={() => setDeletingChapterId(null)}
        message={t('confirm delete chapter')}
        onSubmit={() => {
          firestore.update({ collection: 'chapters', doc: deletingChapterId }, { deleted: true })
          enqueueSnackbar(t('chapter deleted'), { key: deletingChapterId })
          setDeletingChapterId(null)
        }}
      />
    </WithAppBar>
  )
}

export default ManageChapters
