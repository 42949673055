import React from 'react'
import { useFirebase } from 'react-redux-firebase'

const useFirebaseFunctions = (functionNames) => {
  const firebase = useFirebase()
  const functions = functionNames.map((f) => firebase.functions().httpsCallable(f))
  React.useEffect(() => {
    // Ping function to warm it up before using
    if (functions && functions.length) {
      functions.forEach((f) => f({ ping: true }))
    }
  }, [])
  return functions
}

export default useFirebaseFunctions
