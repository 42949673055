import React from 'react'
import ReactQuill, { Quill } from 'react-quill'
import ImageResize from 'quill-image-resize-module-react'
import { ImageDrop } from 'quill-image-drop-module'
import Box from '@material-ui/core/Box'
import { useFirebase } from 'react-redux-firebase'
import shortid from 'shortid'

Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)

const Editor = ({ onChange, value, ...props }) => {
  const editorRef = React.useRef()
  const firebase = useFirebase()
  const [content, setContent] = React.useState(value)

  const uploadHandler = React.useCallback(
    (type) => () => {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', `${type}/*`)
      input.click()
      input.onchange = async () => {
        const file = input.files[0]
        const task = await firebase.uploadFile(`questions/${shortid.generate()}`, file)
        const url = await task.uploadTaskSnapshot.ref.getDownloadURL()
        const editor = editorRef.current.getEditor()
        const range = editor.getSelection()
        editor.insertEmbed(range.index, type, url)
      }
    },
    [firebase]
  )
  const modules = React.useMemo(
    () => ({
      formula: true,
      imageDrop: true,
      imageResize: {
        parchment: Quill.import('parchment'),
      },
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ['link', 'image', 'video'],
          ['blockquote', 'code-block', 'formula'],
        ],
        handlers: {
          image: uploadHandler('image'),
          video: uploadHandler('video'),
        },
      },
    }),
    [uploadHandler]
  )

  const [randomId] = React.useState(shortid.generate())

  React.useEffect(() => {
    if (editorRef.current) {
      const enableMathQuillFormulaAuthoring = window.mathquill4quill({ Quill })
      enableMathQuillFormulaAuthoring(editorRef.current.editor, {
        operators: [
          ['\\times', '\\times'],
          ['\\div', '\\div'],
          ['\\pi', '\\pi'],
          ['a^b', '^'],
          ['a_b', '_'],
          ['\\sqrt[n]{x}', '\\nthroot'],
          ['\\frac{x}{y}', '\\frac'],
        ],
      })
    }
  }, [editorRef])

  return (
    <Box id={`quill-bounds-${randomId}`} {...props}>
      <ReactQuill
        ref={editorRef}
        bounds={`#quill-bounds-${randomId}`}
        value={content}
        modules={modules}
        onChange={(e) => {
          setContent(e)
          onChange(e)
        }}
      />
    </Box>
  )
}

export default Editor
