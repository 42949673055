import React from 'react'
import WithAppBar, { MenuWidth } from '../../components/WithAppBar'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { useSelector } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'
import get from 'lodash/get'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase'
import { selectAssignmentWithChapter } from '../../misc/selectors'
import QuestionsTab from './QuestionsTab'
import StudentsTab from './StudentsTab'
import OverviewTab from './OverviewTab'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from '@material-ui/core/Box'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import ChaptersTab from './ChaptersTab'
import TabsView from '../../components/TabsView'
import IconButton from '@material-ui/core/IconButton'
import PrintIcon from '@material-ui/icons/Print'
import PrintDialog from '../../components/PrintDialog'
import useI18n from '../../misc/languages/useI18n'

const AssignmentResult = () => {
  const theme = useTheme()
  const { t } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const { height: minAvailableScreenHeight } = useMinAvailableSize(
    ['#app-bar', '#tab-bar'],
    isMobile ? 0 : 4
  )
  const firestore = useFirestore()
  const {
    params: { assignmentId },
  } = useRouteMatch()
  const history = useHistory()
  const assignment = useSelector((state) => selectAssignmentWithChapter(state, assignmentId))
  const submissions = Object.keys(get(assignment, 'submissions', {})).map((s) => ({
    user: s,
    ...get(assignment, `submissions.${s}`, {}),
  }))
  const submissionsWithPractices = useSelector((state) =>
    (state.firestore.ordered.practices || []).filter((p) => p.assignment === assignmentId)
  )
  const tabs = isMobile
    ? [t('overview'), t('students'), t('questions'), t('chapters')]
    : [t('students'), t('questions'), t('chapters')]

  useFirestoreConnect([
    { collection: 'practices', where: [['assignment', '==', assignmentId]] },
    ...get(assignment, 'chapters', []).map((c) => ({
      collection: 'chapters',
      doc: c,
    })),
  ])
  const [isPrintDialogVisible, setIsPrintDialogVisible] = React.useState(false)

  const shouldRedirect = assignment && !get(assignment, 'published')
  const shouldSetChecked = assignment && !assignment.checked && assignment.dueDate < Date.now()
  React.useEffect(() => {
    if (shouldRedirect) {
      history.replace(`/app/confirm-assignment/${assignmentId}/0`)
    }
  }, [shouldRedirect])

  React.useEffect(() => {
    if (shouldSetChecked) {
      firestore.update({ collection: 'assignments', doc: assignmentId }, { checked: true })
    }
  }, [shouldSetChecked])

  return assignment ? (
    <WithAppBar title={t('assignment result')} back>
      <Box
        height={isMobile ? minAvailableScreenHeight : undefined}
        pb={2}
        overflow="auto"
        width={isMobile ? '100vw' : `calc(100vw - ${MenuWidth}px)`}
      >
        <Grid container>
          <Hidden smDown>
            <Grid item xs={12} sm={12} md={3}>
              <OverviewTab assignment={assignment} submissions={submissions} isMobile={isMobile} />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} md={9}>
            <TabsView
              tabs={tabs}
              rightComponent={
                isMobile ? null : (
                  <IconButton onClick={() => setIsPrintDialogVisible(true)}>
                    <PrintIcon />
                  </IconButton>
                )
              }
            >
              {isMobile ? (
                <Box width={document.body.clientWidth} pb={4}>
                  <OverviewTab
                    assignment={assignment}
                    submissions={submissions}
                    isMobile={isMobile}
                  />
                </Box>
              ) : null}
              <StudentsTab assignment={assignment} submissions={submissions} />
              <QuestionsTab assignment={assignment} submissions={submissionsWithPractices} />
              <ChaptersTab assignment={assignment} submissions={submissionsWithPractices} />
            </TabsView>
          </Grid>
        </Grid>
      </Box>
      <PrintDialog
        isVisible={isPrintDialogVisible}
        onClose={() => setIsPrintDialogVisible(false)}
        assignment={assignment}
      />
    </WithAppBar>
  ) : null
}

export default AssignmentResult
