import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const InfoList = ({ items, footer, ...props }) => {
  return (
    <Box
      m={2}
      mb={0}
      p={2}
      pt={1}
      borderRadius="borderRadius"
      overflow="auto"
      boxShadow={3}
      bgcolor="background.paper"
      {...props}
    >
      {items.map((item) => (
        <Box key={item.title} mt={2}>
          <Typography variant="subtitle2">{item.title}</Typography>
          {typeof item.label === 'string' ? (
            <Typography {...item.labelProps}>{item.label}</Typography>
          ) : (
            item.label
          )}
        </Box>
      ))}
      {footer ? footer : null}
    </Box>
  )
}

export default InfoList
