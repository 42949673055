function scrollParentToChild(parent, child, offset) {
  // Where is the parent on page
  var parentRect = parent.getBoundingClientRect()
  // What can you see?
  var parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth,
  }

  // Where is the child
  var childRect = child.getBoundingClientRect()

  // scroll by offset relative to parent
  parent.scrollTo({
    behavior: 'smooth',
    top: childRect.top + parent.scrollTop - parentRect.top - (offset || 0),
  })
}

export default scrollParentToChild
