import React from 'react'
import Box from '@material-ui/core/Box'
import WithAppBar from '../../components/WithAppBar'
import { useHistory } from 'react-router-dom'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import GeneratePracticeForm from '../../components/GeneratePracticeForm'
import { useSnackbar } from 'notistack'
import useFirebaseFunctions from '../../misc/useFirebaseFunctions'
import useI18n from '../../misc/languages/useI18n'
import { useFirebase } from 'react-redux-firebase'
import { firebaseConfig } from '../../config'

const FreePractice = () => {
  const firebase = useFirebase()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const { t } = useI18n()
  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 0)
  const [loading, setLoading] = React.useState(false)
  const [generatePractice] = useFirebaseFunctions(['generatePractice'])

  const onSubmit = async (data) => {
    try {
      const params = { ...data, chapters: [] }
      params.noOfQuestions = Number(params.noOfQuestions)
      params.timeLimit = Number(params.timeLimit)
      Object.keys(params).forEach((k) => {
        if (k.match(/^section-/)) {
          params.chapters = [...params.chapters, ...(params[k] || [])]
          delete params[k]
        }
      })
      if (!params.chapters.length) {
        return enqueueSnackbar(t('invalid chapters'))
      }
      setLoading(true)
      const { data: practiceId } = await generatePractice(params)
      setLoading(false)
      firebaseConfig.measurementId && firebase.analytics().logEvent('start_practice', params)
      history.push(`/app/working/${practiceId}/0`)
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <WithAppBar title={t('free practice')}>
      <Box id="scroll-parent" height={minAvailableScreenHeight} overflow="auto">
        <GeneratePracticeForm
          onSubmit={onSubmit}
          loading={loading}
          submitButtonText={t('start practice')}
        />
      </Box>
    </WithAppBar>
  )
}

export default FreePractice
