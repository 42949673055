import React from 'react'
import Box from '@material-ui/core/Box'
import AdvancedTable from '../../components/AdvancedTable'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import get from 'lodash/get'
import BookmarkButton from '../../components/BookmarkButton'
import { styled, useTheme } from '@material-ui/core/styles'
import { selectBookmarks } from '../../misc/selectors'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import CrossIcon from '@material-ui/icons/Close'
import Rating from '@material-ui/lab/Rating'
import Divider from '@material-ui/core/Divider'
import ButtonBase from '../../components/ButtonBase'
import useI18n from '../../misc/languages/useI18n'
import { green, red } from '@material-ui/core/colors'

const BookmarkIcon = styled((props) => <BookmarkButton readOnly value={1} {...props} />)(
  ({ theme }) => ({
    marginLeft: theme.spacing(1),
  })
)

const QuestionsTab = ({ practice, assignmentId }) => {
  const theme = useTheme()
  const { t, locale } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const search = query.get('search')
  const { height: tabContentSizeWithoutBottomSpace } = useMinAvailableSize(
    ['#app-bar', '#tab-bar', '#tabs-bar'],
    6
  )
  const tabContentSize =
    tabContentSizeWithoutBottomSpace - get(window, 'ReactNativeWebView.bottomSpace', 0)

  const history = useHistory()
  const bookmarks = useSelector(selectBookmarks)

  const columns = [
    {
      key: 'questionNo',
      label: t('question no'),
    },
    {
      key: 'chapter',
      label: t('chapter'),
    },
    {
      key: 'level',
      label: t('level'),
    },
    {
      key: 'ans',
      label: t('your answer'),
      align: 'center',
    },
    {
      key: 'correctAns',
      label: t('correct answer'),
      align: 'center',
    },
  ]

  const rows = practice
    ? practice.questions.map((q, i) => {
        const color = q.ans !== q.correctAns ? 'error' : 'textPrimary'
        return {
          key: q.id,
          onClick: () => history.push(`/app/solution/${practice.id}/${i}`),
          questionNo: {
            label: (
              <Box display="flex">
                <Typography color={color}>{i + 1}</Typography>
                {bookmarks.find(
                  (bm) =>
                    ((bm.practice && bm.practice === practice.id) ||
                      (bm.assignment && bm.assignment === assignmentId)) &&
                    bm.questionNo === i
                ) ? (
                  <BookmarkIcon />
                ) : null}
              </Box>
            ),
            sortValue: i,
            searchValue: (i + 1).toString(),
          },
          chapter: {
            label: `${get(q, 'chapter.chapterNo', '')}. ${get(q, `chapter.title.${locale}`, '')}`,
            sortValue: Number(get(q, 'chapter.chapterNo', 0)),
            color,
          },
          level: {
            label: <Rating name="level" value={q.level} max={3} readOnly />,
            sortValue: q.level,
            searchValue: q.level || '',
          },
          ans: {
            label: q.ans.toUpperCase(),
            color,
          },
          correctAns: {
            label: q.correctAns.toUpperCase(),
            color,
          },
        }
      })
    : []

  return (
    <Box pt={2} pb={isMobile ? 2 : 0}>
      <AdvancedTable
        containerProps={{
          pb: get(window, 'ReactNativeWebView.bottomSpace', 0) + 'px',
          mb: 0,
          mt: 0,
          ml: isMobile ? 2 : 0,
          height: isMobile ? undefined : tabContentSize,
          minHeight: isMobile ? tabContentSize : undefined,
        }}
        columns={columns}
        rows={rows}
        defaultSortBy="questionNo"
        defaultSortOrder
        defaultSearch={search}
        renderMobileItem={(row) => (
          <ButtonBase key={row.key} onClick={row.onClick}>
            <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center" flex={1}>
                <Box width={theme.spacing(4)}>{row.questionNo.label}</Box>
                <Box ml={2}>
                  <Typography variant="body2">{row.chapter.label}</Typography>
                  <Box display="flex" alignItems="center">
                    <Typography
                      style={{ marginRight: theme.spacing(1) }}
                      variant="body2"
                      color="textSecondary"
                    >
                      {t('level')}:
                    </Typography>
                    <Rating
                      size="small"
                      name="level"
                      value={row.level.sortValue}
                      max={3}
                      readOnly
                    />
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    {t('your ans')}: {row.ans.label} | {t('correct ans')}: {row.correctAns.label}
                  </Typography>
                </Box>
              </Box>
              {row.ans.label === row.correctAns.label ? (
                <CheckIcon style={{ color: green[500] }} />
              ) : (
                <CrossIcon style={{ color: red[500] }} />
              )}
            </Box>
            <Divider />
          </ButtonBase>
        )}
        emptyText={t('no question')}
      />
    </Box>
  )
}

export default QuestionsTab
