import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const CheckboxGroup = ({ options, defaultValue, value = [], onChange }) => {
  React.useEffect(() => {
    onChange(defaultValue)
  }, [defaultValue])
  return (
    <FormGroup>
      {options.map((c) => (
        <FormControlLabel
          key={c.value}
          control={
            <Checkbox
              name={c.id}
              checked={value.includes(c.value)}
              onChange={(e) =>
                onChange(
                  e.target.checked ? [...value, c.value] : value.filter((v) => v !== c.value)
                )
              }
            />
          }
          label={c.label}
        />
      ))}
    </FormGroup>
  )
}

export default CheckboxGroup
