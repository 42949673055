import React from 'react'
import { useFirestore } from 'react-redux-firebase'
import get from 'lodash/get'
import set from 'lodash/set'
import Box from '@material-ui/core/Box'
import HTMLContent from '../../components/HTMLContent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ButtonBase from '../../components/ButtonBase'
import TickIcon from '@material-ui/icons/Check'
import useI18n from '../../misc/languages/useI18n'

const Working = ({ ans, practice, practiceId, questionNo, question, disabled }) => {
  const firestore = useFirestore()
  const { locale } = useI18n()

  return (
    <Grid key={ans} item xs={12} sm={12} md={6}>
      <Box m={2} mt={0}>
        <ButtonBase
          disabled={disabled}
          onClick={() => {
            const questions = get(practice, 'questions')
            set(questions, `[${questionNo}].ans`, ans)
            firestore.update({ collection: 'practices', doc: practiceId }, { questions })
          }}
        >
          <Box
            p={2}
            borderRadius="borderRadius"
            boxShadow={3}
            bgcolor="background.paper"
            display="flex"
            justifyContent="space-between"
          >
            <Box display="flex">
              <Box mr={2}>
                <Typography>{ans.toUpperCase()}. </Typography>
              </Box>
              <HTMLContent html={get(question, `${ans}.${locale}`, '')} />
            </Box>
            {get(practice, `questions[${questionNo}].ans`) === ans ? (
              <TickIcon color="primary" />
            ) : null}
          </Box>
        </ButtonBase>
      </Box>
    </Grid>
  )
}

export default Working
