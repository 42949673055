import React from 'react'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import format from 'date-fns/format'
import { useFirestore } from 'react-redux-firebase'
import { styled } from '@material-ui/core/styles'
import get from 'lodash/get'
import AdvancedTable from '../../components/AdvancedTable'
import { selectReportsWithSubjectChapter } from '../../misc/selectors'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Close'
import ConfirmDialog from '../../components/ConfirmDialog'
import { useSnackbar } from 'notistack'
import useI18n from '../../misc/languages/useI18n'
import { enableCategories } from '../../config'

const DeleteButton = styled(IconButton)(({ theme }) => ({
  marginTop: theme.spacing(-2),
  marginBottom: theme.spacing(-2),
}))

const Reports = () => {
  const firestore = useFirestore()
  const { t, locale } = useI18n()
  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 4)
  const reports = useSelector(selectReportsWithSubjectChapter)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [deletingReportId, setDeletingReportId] = React.useState(null)
  const columns = [
    {
      key: 'date',
      label: t('date'),
    },
    {
      key: 'subject',
      label: t('subject'),
    },
    {
      key: 'chapter',
      label: t('chapter'),
    },
    {
      key: 'message',
      label: t('message'),
    },
    {
      key: 'remove',
      label: '',
      disableSort: true,
    },
  ]
  const rows = reports.map((b) => ({
    key: b.id,
    onClick: () => history.push(`/app/manage-question/${b.question}`),
    date: {
      label: format(b.createdAt, 'yyyy-MM-dd'),
      sortValue: b.createdAt,
    },
    subject: {
      label:
        (enableCategories && get(b, `subject.category`)
          ? get(b, `subject.category.title.${locale}`) + ' - '
          : '') + get(b, `subject.title.${locale}`),
      id: get(b, 'subject.id'),
    },
    chapter: {
      label: `${get(b, `chapter.chapterNo`)}. ${get(b, `chapter.title.${locale}`)}`,
      id: get(b, 'chapter.id'),
    },
    message: {
      label: b.message,
    },
    remove: {
      label: (
        <DeleteButton
          color="secondary"
          onClick={(e) => {
            e.stopPropagation()
            setDeletingReportId(b.id)
          }}
        >
          <DeleteIcon />
        </DeleteButton>
      ),
      sortValue: '',
      searchValue: '',
    },
  }))
  return (
    <WithAppBar title={t('reports')}>
      <AdvancedTable
        withSubjectFilter
        containerProps={{ height: minAvailableScreenHeight }}
        rows={rows}
        columns={columns}
        defaultSortBy="date"
        defaultSortOrder={false}
        emptyText={t('no report')}
        defaultSubject={''}
      />
      <ConfirmDialog
        isVisible={!!deletingReportId}
        onClose={() => setDeletingReportId(null)}
        message={t('confirm delete report')}
        onSubmit={() => {
          firestore.update({ collection: 'reports', doc: deletingReportId }, { deleted: true })
          enqueueSnackbar(t('report deleted'), { key: deletingReportId })
          setDeletingReportId(null)
        }}
      />
    </WithAppBar>
  )
}

export default Reports
