import React from 'react'
import Rating from '@material-ui/lab/Rating'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import { withStyles } from '@material-ui/core/styles'

const BookmarkButton = withStyles((theme) => ({
  iconFilled: {
    color: theme.palette.primary[500],
  },
}))((props) => (
  <Rating name="bookmarked" icon={<BookmarkIcon />} max={1} unselectable="on" {...props} />
))

export default BookmarkButton
