import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import BookmarkButton from '../BookmarkButton'
import { useSelector } from 'react-redux'
import { red } from '@material-ui/core/colors'
import { selectBookmarks } from '../../misc/selectors'
import Box from '@material-ui/core/Box'

const QuestionsList = ({ practiceId, practice, questionNo, isAssignment }) => {
  const bookmarks = useSelector(selectBookmarks)

  return (
    <Box
      minWidth={120}
      pt={get(window, 'ReactNativeWebView.statusBarHeight', 0) + 'px'}
      pb={get(window, 'ReactNativeWebView.bottomSpace', 0) + 'px'}
    >
      <List>
        {get(practice, 'questions', []).map((q, i) => (
          <ListItem
            key={i.toString()}
            button
            component={Link}
            to={`/app/${isAssignment || get(practice, 'finishedAt') ? 'solution' : 'working'}/${get(
              practice,
              'id'
            )}/${i}`}
            selected={questionNo === i}
            replace
          >
            <ListItemText
              style={
                !isAssignment &&
                get(practice, 'finishedAt') &&
                get(practice, `questions[${i}].ans`) !== get(practice, `questions[${i}].correctAns`)
                  ? { color: red[500] }
                  : {}
              }
              primary={`${i + 1}. ${
                !isAssignment ? q.ans.toUpperCase() : q.correctAns.toUpperCase()
              }`}
            />
            {bookmarks.find(
              (bm) =>
                (isAssignment ? bm.assignment : bm.practice) === practiceId && bm.questionNo === i
            ) ? (
              <BookmarkButton value={1} readOnly />
            ) : null}
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default QuestionsList
