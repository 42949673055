import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import useI18n from '../../misc/languages/useI18n'
import StudentsSelector from '../StudentsSelector'
import { selectStudents } from '../../misc/selectors'
import { useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'

const AssignStudentsDialog = ({ isVisible, onClose, onSubmit, defaultSelectedStudents }) => {
  const { t } = useI18n()
  const students = useSelector((state) => selectStudents(state).filter((s) => s.accepted))
  const uid = useSelector((state) => state.firebase.auth.uid)
  const [selectedStudents, setSelectedStudents] = React.useState(defaultSelectedStudents || [])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setSelectedStudents(defaultSelectedStudents || [])
  }, [defaultSelectedStudents])

  return (
    <Dialog open={isVisible} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{t('assign students')}</DialogTitle>
      <DialogContent>
        <StudentsSelector
          school={uid}
          students={students}
          selectedStudents={selectedStudents}
          setSelectedStudents={setSelectedStudents}
        />
      </DialogContent>
      <DialogActions>
        <Button style={{ width: 120 }} onClick={onClose} color="secondary">
          {t('cancel')}
        </Button>
        <Button
          style={{ width: 120 }}
          onClick={async () => {
            try {
              setLoading(true)
              await onSubmit(students.filter((s) => selectedStudents.includes(s.student)))
              setLoading(false)
              onClose()
            } catch (err) {
              console.log(err)
              setLoading(false)
            }
          }}
          color="primary"
          autoFocus
          disabled={loading}
        >
          {loading ? <CircularProgress size={21} /> : t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AssignStudentsDialog
