import React from 'react'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { useForm, Controller } from 'react-hook-form'
import { styled } from '@material-ui/core/styles'
import get from 'lodash/get'
import Button from '@material-ui/core/Button'
import { useFirebase } from 'react-redux-firebase'
import { useSnackbar } from 'notistack'
import useI18n from '../../misc/languages/useI18n'

const Form = styled((props) => <form {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch',
}))

const Input = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const ForgotPasswordForm = ({ onBack }) => {
  const firebase = useFirebase()
  const { t } = useI18n()
  const { enqueueSnackbar } = useSnackbar()
  const { handleSubmit, control, errors, setError } = useForm({ reValidateMode: 'onSubmit' })
  const onSubmit = async ({ email }) => {
    try {
      await firebase.resetPassword(email)
      enqueueSnackbar(t('password reset email sent'), { key: email })
    } catch (err) {
      setError('email', 'user-does-not-exist', t('user not exist'))
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        as={Input}
        name="email"
        label={t('email')}
        control={control}
        defaultValue=""
        error={!!errors.email}
        helperText={get(errors, 'email.message')}
        rules={{
          required: true,
          pattern: {
            value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: t('invalid email'),
          },
        }}
      />

      <Box display="flex">
        <Button fullWidth onClick={onBack}>
          {t('back')}
        </Button>
        <Button fullWidth type="submit" variant="contained" color="primary">
          {t('submit')}
        </Button>
      </Box>
    </Form>
  )
}

export default ForgotPasswordForm
