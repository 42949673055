import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { useForm, Controller } from 'react-hook-form'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import { styled } from '@material-ui/core/styles'
import get from 'lodash/get'
import { useSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSelector } from 'react-redux'
import useFirebaseFunctions from '../../misc/useFirebaseFunctions'
import useI18n from '../../misc/languages/useI18n'

const Input = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const AdminModal = ({ isVisible, onClose, user }) => {
  const { t } = useI18n()
  const { enqueueSnackbar } = useSnackbar()
  const { handleSubmit, control, errors, setError } = useForm({ reValidateMode: 'onSubmit' })
  const [loading, setLoading] = React.useState(false)
  const uid = useSelector((state) => state.firebase.auth.uid)
  const [createAdmin, updateAdmin, deleteAdmin] = useFirebaseFunctions([
    'createAdmin',
    'updateAdmin',
    'deleteAdmin',
  ])

  const onSubmit = async (params) => {
    try {
      setLoading(true)
      const result = await (user ? updateAdmin : createAdmin)(
        user ? { ...params, id: user.id } : params
      )
      setLoading(false)
      onClose()
      enqueueSnackbar(user ? t('user updated') : t('user created'), { key: result.uid })
    } catch (err) {
      setError('email', 'email-existed', t('email existed'))
      setLoading(false)
    }
  }

  const deleteUser = async () => {
    try {
      setLoading(true)
      await deleteAdmin({ id: user.id })
      setLoading(false)
      onClose()
      enqueueSnackbar(t('user deleted'), { key: user.id })
    } catch (err) {
      setLoading(false)
    }
  }
  return (
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle>{user ? t('edit admin') : t('create admin')}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ paddingTop: 0 }}>
          <DialogContentText>{t('new admin password')}</DialogContentText>
          <Controller
            as={Input}
            name="email"
            label={t('email')}
            control={control}
            defaultValue={get(user, 'email', '')}
            error={!!errors.email}
            helperText={get(errors, 'email.message')}
            fullWidth
            rules={{
              required: true,
              pattern: {
                value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: t('invalid email'),
              },
            }}
          />
          <Controller
            as={Input}
            name="displayName"
            label={t('name')}
            control={control}
            defaultValue={get(user, 'displayName', '')}
            error={!!errors.displayName}
            helperText={get(errors, 'displayName.message')}
            fullWidth
            rules={{ required: true }}
          />
          <FormControlLabel
            label={t('super admin')}
            control={
              <Controller
                as={Checkbox}
                name="isSuperAdmin"
                control={control}
                defaultValue={get(user, 'role') === 'super-admin'}
                disabled={uid === get(user, 'id')}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between" flex={1} p={2} pt={0}>
            {user && uid !== user.id ? (
              <Button
                disabled={loading}
                style={{ width: 120 }}
                color="secondary"
                variant="contained"
                onClick={deleteUser}
              >
                {loading ? <CircularProgress size={24} /> : t('delete')}
              </Button>
            ) : (
              <Box />
            )}
            <Box display="flex">
              <Button onClick={onClose} color="secondary">
                {t('cancel')}
              </Button>
              <Button
                disabled={loading}
                style={{ width: 120 }}
                type="submit"
                color="primary"
                autoFocus
              >
                {loading ? <CircularProgress size={24} /> : user ? t('save') : t('create')}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AdminModal
