import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { useFirebase } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSnackbar } from 'notistack'
import { DropzoneArea } from 'material-ui-dropzone'
import postSlack from '../../misc/postSlack'
import useI18n from '../../misc/languages/useI18n'
import { enableFileUpload } from '../../config'

const ContactUsDialog = ({ isVisible, onClose }) => {
  const firebase = useFirebase()
  const { t } = useI18n()
  const { enqueueSnackbar } = useSnackbar()
  const defaultEmail = useSelector((state) => get(state, 'firebase.profile.email'))
  const [message, setMessage] = React.useState('')
  const [email, setEmail] = React.useState(defaultEmail)
  const [files, setFiles] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const onSubmit = async () => {
    try {
      setLoading(true)
      let urls = []
      if (files.length) {
        const result = await firebase.uploadFiles(`feedback/${Date.now()}`, files)
        urls = await Promise.all(result.map((r) => r.uploadTaskSnapshot.ref.getDownloadURL()))
      }
      await postSlack({
        text: `Email: ${email}\n\n${message}`,
        attachments: urls.map((u, i) => ({ image_url: u, title: `Image ${i + 1}` })),
      })
      setLoading(false)
      setMessage('')
      enqueueSnackbar(t('thank you for contacting us'))
      onClose()
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <Dialog open={isVisible} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{t('contact us')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('contact us description')}</DialogContentText>
        <Box my={2}>
          <TextField
            label={t('email')}
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box mb={2}>
          <TextField
            multiline
            rows="4"
            label={t('message')}
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Box>
        {enableFileUpload ? (
          <DropzoneArea
            filesLimit={5}
            showPreviews
            showPreviewsInDropzone={false}
            dropzoneClass="dropzone"
            dropzoneText=""
            onChange={(e) => setFiles(e)}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button style={{ width: 120 }} onClick={onClose} color="secondary">
          {t('cancel')}
        </Button>
        <Button
          style={{ width: 120 }}
          onClick={onSubmit}
          color="primary"
          disabled={!email || !message || loading}
        >
          {loading ? <CircularProgress size={24} /> : t('send')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ContactUsDialog
