import React from 'react'
import Box from '@material-ui/core/Box'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import QuestionEditor from './QuestionEditor'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectQuestion } from '../../misc/selectors'
import useI18n from '../../misc/languages/useI18n'

const ManageQuestion = () => {
  const {
    params: { questionId },
  } = useRouteMatch()
  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 0)
  const question = useSelector((state) => selectQuestion(state, questionId))
  const { t } = useI18n()

  useFirestoreConnect([{ collection: 'questions', doc: questionId }].filter((a) => a))

  return (
    <WithAppBar title={t('manage question')} back>
      <Box height={minAvailableScreenHeight} pb={2} overflow="auto">
        <QuestionEditor questionId={questionId} question={question} />
      </Box>
    </WithAppBar>
  )
}

export default ManageQuestion
