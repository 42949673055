import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SwipeableViews from 'react-swipeable-views'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'

const TabsView = ({ tabs, rightComponent, children }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const [animateHeight, setAnimateHeight] = React.useState(false)
  const history = useHistory()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const tab = query.get('tab')

  React.useEffect(() => {
    setTimeout(() => setAnimateHeight(true), 100)
  }, [])
  React.useEffect(() => {
    if (tab === null) {
      history.replace(`${location.pathname}?tab=${tabs[0]}`)
    }
  }, [tab])

  const tabIndex = Math.max(tabs.indexOf(tab), 0)
  const onTabChange = (i) => {
    history.replace(`${location.pathname}?tab=${tabs[i]}`)
  }

  return (
    <>
      <Box
        position={isMobile ? 'fixed' : 'static'}
        zIndex={1}
        m={isMobile ? 0 : 2}
        width={isMobile ? '100vw' : undefined}
        ml={0}
        mb={0}
        boxShadow={3}
        bgcolor="background.paper"
        borderRadius={isMobile ? undefined : 'borderRadius'}
        id="tabs-bar"
        display="flex"
        justifyContent="space-between"
      >
        <Tabs
          value={tabIndex}
          onChange={(e, i) => onTabChange(i)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          {tabs.map((t) => (
            <Tab key={t} label={t} />
          ))}
        </Tabs>
        {rightComponent}
      </Box>
      {isMobile ? <Box height={48} /> : null}
      <SwipeableViews
        animateHeight={animateHeight}
        disableLazyLoading
        index={tabIndex}
        onChangeIndex={onTabChange}
        resistance
      >
        {children && children.length ? children.filter((c) => c) : children}
      </SwipeableViews>
    </>
  )
}

export default TabsView
