import React from 'react'
import Box from '@material-ui/core/Box'
import AdvancedTable from '../../components/AdvancedTable'
import ButtonBase from '../../components/ButtonBase'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import groupBy from 'lodash/groupBy'
import get from 'lodash/get'
import flatten from 'lodash/flatten'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { useHistory, useLocation } from 'react-router-dom'
import useI18n from '../../misc/languages/useI18n'

const ChaptersTab = ({ submissions, assignment }) => {
  const theme = useTheme()
  const { t, locale } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const history = useHistory()
  const location = useLocation()
  const { height: tabContentSizeWithoutBottomSpace } = useMinAvailableSize(
    ['#app-bar', '#tab-bar', '#tabs-bar'],
    6
  )
  const tabContentSize =
    tabContentSizeWithoutBottomSpace - get(window, 'ReactNativeWebView.bottomSpace', 0)

  const questionsGroupedByChapters = groupBy(
    flatten(submissions.map((s) => s.questions)),
    (q) => q.chapter
  )

  const chapters = assignment.chaptersObj.map((c) => ({
    ...c,
    score:
      get(questionsGroupedByChapters, c.id, []).filter((q) => q.ans === q.correctAns).length /
        submissions.length || 0,
    count: assignment.questions.filter((q) => q.chapter.id === c.id).length,
  }))

  const columns = [
    {
      key: 'chapter',
      label: t('chapter'),
    },
    {
      key: 'performance',
      label: t('performance'),
    },
  ]

  const rows = chapters.map((q, i) => ({
    key: q.id,
    onClick: () =>
      history.replace(
        `${location.pathname}?tab=${t('questions')}&search=${`${get(q, 'chapterNo', '')}. ${get(
          q,
          `title.${locale}`,
          ''
        )}`}`
      ),
    chapter: {
      label: `${get(q, 'chapterNo', '')}. ${get(q, `title.${locale}`, '')}`,
      sortValue: Number(get(q, 'chapterNo', 0)),
    },
    performance: {
      label: `${Math.round(10 * q.score) / 10} / ${q.count} (${
        Math.round((100 * q.score) / q.count) || 0
      }%)`,
      sortValue: q.score / q.count,
      color: get(
        get(assignment, 'subject.grades', []).find(
          (g) => (q.score / q.count || 0) >= g.min && (q.score / q.count || 0) <= g.max
        ),
        'color'
      ),
    },
    ...q,
  }))

  return (
    <Box pt={2} pb={isMobile ? 2 : 0}>
      <AdvancedTable
        containerProps={{
          pb: get(window, 'ReactNativeWebView.bottomSpace', 0) + 'px',
          mb: 0,
          mt: 0,
          ml: isMobile ? 2 : 0,
          height: isMobile ? undefined : tabContentSize,
          minHeight: isMobile ? tabContentSize : undefined,
        }}
        columns={columns}
        rows={rows}
        defaultSortBy="chapter"
        defaultSortOrder
        renderMobileItem={(row) => (
          <ButtonBase key={row.key} onClick={row.onClick}>
            <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
              <Box flex={1}>
                <Typography variant="body2">{row.chapter.label}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {t('performance')}: {row.score} / {row.count}
                </Typography>
              </Box>
              <Typography variant="h5" style={{ color: row.performance.color }}>
                {Math.round((100 * row.score) / row.count) || 0}%
              </Typography>
            </Box>
            <Divider />
          </ButtonBase>
        )}
        emptyText={t('no chapter')}
      />
    </Box>
  )
}

export default ChaptersTab
