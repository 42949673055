import { slackUrl } from '../config'

const postSlack = async (body) => {
  try {
    const response = await fetch(slackUrl, {
      body: JSON.stringify(body),
      method: 'POST',
    })
    if (response.ok) {
      return response
    }
    throw response
  } catch (err) {
    return Promise.reject(err)
  }
}

export default postSlack
