import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { styled } from '@material-ui/core/styles'
import getInitial from '../../misc/getInitial'

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(-2),
  marginBottom: theme.spacing(-2),
}))

const SmallAvatarWithName = ({ profile = {}, subtitle, moreMargin, ...props }) => {
  return (
    <Box display="flex" alignItems="center" {...props}>
      <SmallAvatar src={profile.avatarUrl}>{getInitial(profile.displayName)}</SmallAvatar>
      <Box ml={moreMargin ? 1 : 0}>
        <Typography noWrap variant="subtitle2">
          {profile.displayName || ''}
        </Typography>
        {subtitle ? (
          typeof subtitle === 'string' ? (
            <Box mt={-0.8}>
              <Typography variant="caption" color="textSecondary">
                {subtitle}
              </Typography>
            </Box>
          ) : (
            subtitle
          )
        ) : null}
      </Box>
    </Box>
  )
}

export default SmallAvatarWithName
