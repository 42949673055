import React, { useCallback } from 'react'
import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import { availableLanguages } from '../../config'
import Editor from './Editor'
import cheerio from 'cheerio'

const shortenMathML = (html) => {
  const $ = cheerio.load(html)
  $('.ql-formula').map((i, e) => {
    const value = $(e).attr('data-value')
    $(e).replaceWith(`$$${value}$$`)
  })
  return $('body')
    .html()
    .replace(
      /<iframe class="ql-video" frameborder="0" allowfullscreen="true"/g,
      '<video class="ql-video" controls'
    )
    .replace(/iframe>/g, 'video>')
}

const expandMathML = (html) => {
  const element = document.createElement('div')
  element.innerHTML = html
  window.renderMathInElement(element)
  const $ = cheerio.load(element.innerHTML)
  $('.katex-display').parent().addClass('ql-formula')
  $('.ql-formula').map((i, e) => {
    $(e).attr(
      'data-value',
      get(
        e,
        'childNodes[0].children[0].children[0].children[0].children[0].children[1].children[0].data'
      )
    )
  })
  return $('body').html()
}

const HTMLEditor = ({ onChange, value }) => {
  const lang = useSelector((state) => get(state, 'firebase.profile.language'))
  const [tab, setTab] = React.useState(
    Math.max(
      availableLanguages.findIndex((l) => l.value === lang),
      0
    )
  )
  const temp = value || {}
  Object.keys(temp).map((k) => {
    temp[k] = expandMathML(temp[k])
  })
  const [content, setContent] = React.useState(temp || {})
  const [initAt, setInitAt] = React.useState(Date.now())

  const submit = (lang, value) => {
    setContent({
      ...content,
      [lang]: value,
    })
  }

  React.useEffect(() => {
    if (Date.now() - 2000 > initAt) {
      const temp2 = content || {}
      Object.keys(temp2).map((k) => {
        temp2[k] = shortenMathML(temp2[k])
      })
      onChange(temp2)
    }
  }, [content])

  return (
    <Box
      m={2}
      mb={0}
      p={2}
      borderRadius="borderRadius"
      overflow="hidden"
      boxShadow={3}
      bgcolor="white"
    >
      <Tabs value={tab} onChange={(e, v) => setTab(v)} indicatorColor="primary" textColor="primary">
        {availableLanguages.map((l) => (
          <Tab key={l.value} label={l.label} />
        ))}
      </Tabs>
      {availableLanguages.map((l, i) => (
        <Editor
          key={l.value}
          display={tab === i ? 'block' : 'none'}
          value={content[l.value] || ''}
          onChange={debounce((e) => submit(l.value, e), 1000)}
        />
      ))}
    </Box>
  )
}

export default HTMLEditor
