import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import groupBy from 'lodash/groupBy'
import get from 'lodash/get'
import maxBy from 'lodash/maxBy'
import flatten from 'lodash/flatten'
import format from 'date-fns/format'
import isFuture from 'date-fns/isFuture'
import isToday from 'date-fns/isToday'
import isTomorrow from 'date-fns/isTomorrow'
import getGlobalRanking from './getGlobalRanking'
import { premiumConfig } from '../config'
import uniq from 'lodash/uniq'

export const selectAllSubjects = createSelector(
  (state) => state.firestore.ordered.subjects || [],
  (subjects) => subjects.filter((s) => !s.deleted).sort((a, b) => a.order - b.order)
)

export const selectCategories = createSelector(
  (state) => state.firestore.ordered.categories || [],
  (categories) => categories.filter((s) => !s.deleted).sort((a, b) => a.order - b.order)
)

export const selectSubjects = createSelector(
  selectAllSubjects,
  (state) => state.firebase.profile,
  (state) => state.firestore.ordered.practices || [],
  (state) => state.firestore.ordered.assignments || [],
  (state) => state.firestore.data.categories || {},
  (subjects, profile, practices, assignments, categories) => {
    const subjectsToInclude = uniq([...practices, ...assignments].map((p) => p.subject))
    return (
      get(profile, 'role', '').includes('admin')
        ? subjects
        : subjects.filter((s) => subjectsToInclude.includes(s.id))
    ).map((s) => ({ ...s, category: categories[s.category] }))
  }
)

export const selectBookmarks = createSelector(
  (state) => state.firestore.ordered.bookmarks || [],
  (bookmarks) => bookmarks.filter((s) => !s.deleted).sort((a, b) => b.createdAt - a.createdAt)
)

export const selectAdmins = createSelector(
  (state) => state.firestore.ordered.users || [],
  (users) =>
    users
      .filter((s) => !s.deleted && ['admin', 'super-admin'].includes(s.role))
      .map((s) => ({ ...s, superAdmin: s.role === 'super-admin' }))
      .sort((a, b) => b.createdAt - a.createdAt)
)

export const selectStudents = createSelector(
  (state) => state.firestore.ordered.students || [],
  (state) => state.firestore.ordered.assignments || [],
  (state) => state.firestore.ordered.teachers || [],
  (state) => state.firebase.auth.uid,
  (state) => state.firebase.profile.isSchool,
  (students, assignments, teachers, uid, isSchool) =>
    students
      .filter((s) => {
        const school =
          s.school && !isSchool
            ? teachers.find(
                (t) => t.school === s.school && t.teacher === uid && !t.deleted && t.accepted
              )
            : true
        return s.teacher === uid && !s.deleted && !!school
      })
      .map((s) => {
        let pendingAssignments = 0
        let lateAssignments = 0
        let totalAssignments = 0
        assignments
          .filter((a) => a.students.includes(s.student) && a.school === s.school)
          .forEach((a) => {
            const submittedAt = get(a, `submissions.${s.student}.finishedAt`, 0)
            if (!submittedAt) {
              pendingAssignments += 1
            }
            if ((a.dueDate < Date.now() && !submittedAt) || submittedAt > a.dueDate) {
              lateAssignments += 1
            }
            totalAssignments += 1
          })
        return {
          ...s,
          pendingAssignments,
          lateAssignments,
          totalAssignments,
        }
      })
      .sort((a, b) => b.createdAt - a.createdAt)
)

export const selectTeachers = createSelector(
  (state) => state.firestore.ordered.students || [],
  (state) => state.firestore.ordered.assignments || [],
  (state) => state.firebase.auth.uid,
  (students, assignments, uid) =>
    students
      .filter((s) => s.student === uid && !s.deleted)
      .map((s) => {
        let pendingAssignments = 0
        let lateAssignments = 0
        let totalAssignments = 0
        assignments
          .filter(
            (a) => a.teacher === s.teacher && (a.school === s.school || (!a.school && !s.school))
          )
          .forEach((a) => {
            const submittedAt = get(a, `submissions.${s.student}.finishedAt`, 0)
            if (!submittedAt) {
              pendingAssignments += 1
            }
            if ((a.dueDate < Date.now() && !submittedAt) || submittedAt > a.dueDate) {
              lateAssignments += 1
            }
            totalAssignments += 1
          })
        return {
          ...s,
          pendingAssignments,
          lateAssignments,
          totalAssignments,
        }
      })
      .sort((a, b) => b.createdAt - a.createdAt)
)

export const selectSchoolTeachers = createSelector(
  (state) => state.firestore.ordered.teachers || [],
  (state) => state.firestore.ordered.students || [],
  (state) => state.firebase.auth.uid,
  (state) => state.firebase.profile.isSchool,
  (teachers, students, uid, isSchool) =>
    teachers
      .filter(
        (t) => ((isSchool && t.school === uid) || (!isSchool && t.teacher === uid)) && !t.deleted
      )
      .map((t) => ({
        ...t,
        students: students.filter((s) => s.teacher === t.teacher && s.school === t.school),
      }))
      .sort((a, b) => b.createdAt - a.createdAt)
)

export const selectBookmark = createCachedSelector(
  selectBookmarks,
  (_state, practiceId, _assignmentId, _questionNo) => practiceId,
  (_state, _practiceId, assignmentId, _questionNo) => assignmentId,
  (_state, _practiceId, _assignmentId, questionNo) => questionNo,
  (bookmarks, practiceId, assignmentId, questionNo) =>
    bookmarks.find(
      (bm) =>
        ((bm.practice && bm.practice === practiceId) ||
          (bm.assignment && bm.assignment === assignmentId)) &&
        bm.questionNo === questionNo
    )
)(
  (_state, practiceId, assignmentId, questionNo) =>
    `${practiceId || assignmentId || ''}_${questionNo || ''}`
)

export const selectPractice = createCachedSelector(
  (state) => state.firestore.data.practices || {},
  (_state, id) => id,
  (practices, id) => (practices[id] ? { ...practices[id], id } : null)
)((_state, id) => id || '')

export const selectStudent = createCachedSelector(
  (state) => state.firestore.ordered.students || [],
  (_state, id) => id,
  (students, id) => students.find((s) => s.student === id)
)((_state, id) => id || '')

export const selectAssignment = createCachedSelector(
  (state) => state.firestore.data.assignments || {},
  (_state, id) => id,
  (assignments, id) => (assignments[id] ? { ...assignments[id], id } : null)
)((_state, id) => id || '')

export const selectQuestion = createCachedSelector(
  (state) => state.firestore.data.questions || {},
  (_state, id) => id,
  (questions, id) => (questions[id] ? { ...questions[id], id } : null)
)((_state, id) => id || '')

export const selectChapter = createCachedSelector(
  (state) => state.firestore.data.chapters || {},
  (_state, id) => id,
  (chapters, id) => (chapters[id] ? { ...chapters[id], id } : null)
)((_state, id) => id || '')

export const selectSubjectWithOrderedGrades = createCachedSelector(
  (state) => state.firestore.data.subjects || {},
  (_state, id) => id,
  (subjects, id) =>
    subjects[id]
      ? {
          ...subjects[id],
          id,
          grades: subjects[id].grades
            .sort((a, b) => a.min - b.min)
            .map((g, i) => ({ ...g, order: i })),
        }
      : null
)((_state, id) => id || '')

export const selectBookmarksWithSubjectChapter = createSelector(
  selectBookmarks,
  (state) => state.firestore.data.subjects || {},
  (state) => state.firestore.data.chapters || {},
  (state) => state.firestore.data.categories || {},
  (bookmarks, subjects, chapters, categories) =>
    bookmarks.map((b) => {
      const chapter = { ...(chapters[b.chapter] || {}), id: b.chapter }
      const subject = {
        ...(subjects[chapter.subject] || {}),
        id: chapter.subject,
      }
      subject.category = get(categories, subject.category)
      return { ...b, chapter, subject }
    })
)

export const selectReportsWithSubjectChapter = createSelector(
  (state) => state.firestore.ordered.reports,
  (state) => state.firestore.data.subjects || {},
  (state) => state.firestore.data.categories || {},
  (state) => state.firestore.data.chapters || {},
  (reports, subjects, categories, chapters) =>
    reports.map((b) => {
      const chapter = { ...(chapters[b.chapter] || {}), id: b.chapter }
      const subject = {
        ...(subjects[b.subject] || {}),
        id: b.subject,
        category: get(categories, (subjects[b.subject] || {}).category),
      }
      return { ...b, chapter, subject }
    })
)

export const selectSectionsAndChaptersBySubject = createCachedSelector(
  (state) => state.firestore.ordered.sections || [],
  (state) => state.firestore.ordered.chapters || [],
  (_state, subject) => subject,
  (sections, chapters, subject) =>
    sections
      .filter((s) => s.subject === subject && !s.deleted)
      .sort((a, b) => a.order - b.order)
      .map((s) => ({
        ...s,
        chapters: chapters
          .filter((c) => c.section === s.id && !c.deleted)
          .sort((a, b) => a.chapterNo - b.chapterNo),
      }))
)((_state, subject) => subject || '')

export const selectPracticesWithSubjectAndGrade = createSelector(
  (state) => state.firestore.ordered.practices || [],
  (state) => state.firestore.data.subjects || {},
  (state) => state.firestore.data.categories || {},
  (practices, subjects, categories) =>
    practices
      .filter((p) => !p.deleted)
      .map((p) => {
        const subject = {
          ...(subjects[p.subject] || { grades: [] }),
          id: p.subject,
        }
        subject.category = get(categories, subject.category)
        const percentage = p.score / p.noOfQuestions || 0
        const grade = maxBy(
          subject.grades.filter((g) => percentage >= g.min && percentage <= g.max),
          (g) => g.max
        )
        return { ...p, subject, grade }
      })
)

export const selectPracticeWithSubjectAndChapters = createCachedSelector(
  (state) => state.firestore.data.practices || {},
  (state) => state.firestore.data.subjects || {},
  (state) => state.firestore.data.chapters || {},
  (_state, practiceId) => practiceId,
  (_state, _practiceId, assignmentId) => assignmentId,
  (_state, _practiceId, _assignmentId, studentId) => studentId,
  (practices, subjects, chapters, practiceId, assignmentId, studentId) => {
    const practice = practiceId
      ? practices[practiceId]
      : Object.keys(practices)
          .map((p) => ({ ...practices[p], id: p }))
          .find((p) => p.assignment === assignmentId && p.user === studentId)
    return practice
      ? {
          id: practiceId,
          ...practice,
          subject: {
            ...(subjects[practice.subject] || {}),
            id: practice.subject,
          },
          questions: practice.questions.map((q) => ({
            ...q,
            chapter: { ...chapters[q.chapter], id: q.chapter },
          })),
        }
      : null
  }
)((_state, practiceId, assignmentId, studentId) => practiceId || `${assignmentId}_${studentId}`)

const getStatFromPractices = (allPractices, subject) => {
  const practices = allPractices.filter((p) => p.finishedAt)
  const questionsDone = practices.map((p) => p.noOfQuestions).reduce((a, b) => a + b, 0)
  const averageScore = questionsDone
    ? practices.map((p) => p.score).reduce((a, b) => a + b, 0) / questionsDone
    : 0
  const timeSpentPerQuestion = questionsDone
    ? practices.map((p) => (p.finishedAt - p.createdAt) / 1000).reduce((a, b) => a + b, 0) /
      questionsDone
    : 0
  const globalRanking = getGlobalRanking(averageScore, subject.globalStat)
  const expectedGrade = (subject.grades || []).find(
    (g) => averageScore >= g.min && averageScore <= g.max
  )
  return {
    questionsDone,
    averageScore,
    timeSpentPerQuestion,
    globalRanking,
    expectedGrade,
    papersDone: practices.length,
  }
}

export const selectStat = createCachedSelector(
  (state) => state.firestore.ordered.practices || [],
  (state) => state.firestore.data.subjects || {},
  (state) => state.firestore.data.sections || {},
  (state) => state.firestore.ordered.chapters || [],
  (_state, subject, _from, _to) => subject,
  (_state, _subject, from, _to) => from,
  (_state, _subject, _from, to) => to,
  (state, _subject, _from, _to, studentId) => studentId || state.firebase.auth.uid,
  (_state, _subject, _from, _to, _studentId, schoolId) => schoolId || '',
  (state) => state.firebase.profile.role,
  (practices, subjects, sections, chapters, id, from, to, studentId, schoolId, role) => {
    const subject = {
      ...get(subjects, id, {}),
      id,
      grades: get(subjects, `${id}.grades`, [])
        .sort((a, b) => a.min - b.min)
        .map((g, i) => ({ ...g, order: i })),
    }
    const filteredPractices = practices.filter(
      (p) =>
        !p.deleted &&
        p.subject === get(subject, 'id') &&
        p.createdAt >= from &&
        p.createdAt <= to &&
        p.user === studentId &&
        (p.school === schoolId || role === 'student')
    )
    const groupedPractices = groupBy(filteredPractices, (p) => format(p.createdAt, 'yyyy-MM-dd'))
    const chartData = Object.keys(groupedPractices)
      .map((d) => ({
        ...getStatFromPractices(groupedPractices[d], subject),
        date: d,
      }))
      .sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
    const chaptersData = chapters
      .filter((c) => !c.deleted)
      .map((c) => {
        const chapterStat = flatten(
          filteredPractices.map((p) => p.questions.filter((q) => q.chapter === c.id))
        )
          .map((q) => q.correctAns === q.ans)
          .reduce(
            (a, b) => ({
              score: a.score + (b ? 1 : 0),
              noOfQuestions: a.noOfQuestions + 1,
            }),
            { score: 0, noOfQuestions: 0 }
          )
        const score = chapterStat.score / chapterStat.noOfQuestions || 0
        return {
          ...c,
          section: sections[c.section],
          score: chapterStat.score,
          noOfQuestions: chapterStat.noOfQuestions,
          grade: (subject.grades || []).find((g) => score >= g.min && score <= g.max),
        }
      })
    return {
      ...getStatFromPractices(filteredPractices, subject),
      chartData,
      chaptersData,
    }
  }
)(
  (_state, subject, from, to, studentId, schoolId) =>
    `${subject || ''}_${from}_${to}_${studentId || ''}_${schoolId || ''}`
)

export const selectLatestSubject = createSelector(
  (state) => state.firestore.ordered.practices || [],
  selectSubjects,
  (practices, subjects) => {
    const latestPractice = maxBy(practices, (p) => p.createdAt)
    return latestPractice ? latestPractice.subject : get(subjects, '[0].id')
  }
)

export const selectAssignments = createCachedSelector(
  (state) => state.firestore.ordered.assignments || [],
  (state) => state.firestore.data.subjects || {},
  (state) => state.firestore.data.categories || {},
  (state) => state.firestore.ordered.students || [],
  (state) => state.firestore.ordered.practices || [],
  (state, uid) => uid || state.firebase.auth.uid,
  (state) => state.firebase.profile.role,
  (assignments, subjects, categories, students, practices, uid, role) =>
    assignments
      .filter(
        (s) =>
          !s.deleted &&
          (s.teacher === uid || s.school === uid || s.students.includes(uid)) &&
          (role === 'teacher' || s.published)
      )
      .map((a) => {
        let status = {}
        const practice = practices.find((p) => p.assignment === a.id)
        if (!a.published) {
          status = {
            key: 'not-published',
            label: 'not published',
            color: 'textSecondary',
          }
        } else if (role === 'student' && practice && practice.finishedAt) {
          status = {
            key: 'finished',
            label: 'finished',
            color: 'textSecondary',
          }
        } else if (isFuture(a.dueDate)) {
          status = {
            key: 'not-due',
            label: isToday(a.dueDate)
              ? 'due today'
              : isTomorrow(a.dueDate)
              ? 'due tomorrow'
              : 'new',
            color: 'primary',
          }
        } else if (!a.checked && role === 'teacher') {
          status = {
            key: 'not-checked',
            label: 'not checked',
            color: 'error',
          }
        } else if (role === 'teacher') {
          status = {
            key: 'checked',
            label: 'checked',
            color: 'textPrimary',
          }
        } else {
          status = {
            key: 'overdue',
            label: 'overdue',
            color: 'error',
          }
        }
        return {
          ...a,
          subject: {
            id: a.subject,
            ...(subjects[a.subject] || {}),
            category: get(categories, (subjects[a.subject] || {}).category),
          },
          students: a.students.map((s) =>
            students.find(
              (ss) =>
                ss.student === s &&
                ss.teacher === a.teacher &&
                (!a.school || ss.school === a.school)
            )
          ),
          status,
          practice,
        }
      })
      .sort((a, b) => b.createdAt - a.createdAt)
)((state, uid) => uid || '')

export const selectAssignmentWithChapter = createCachedSelector(
  selectAssignment,
  (state) => state.firestore.data.subjects || {},
  (state) => state.firestore.data.chapters || {},
  (state) => state.firestore.ordered.students || [],
  (assignment, subjects, chapters, students) =>
    assignment
      ? {
          ...assignment,
          subject: { ...subjects[assignment.subject], id: assignment.subject },
          chaptersObj: assignment.chapters.map((c) => ({ ...chapters[c], id: c })),
          teacher: students.find(
            (ss) =>
              ss.teacher === assignment.teacher &&
              (ss.school === assignment.school || (!ss.school && !assignment.school))
          ),
          students: assignment.students.map((s) => students.find((ss) => ss.student === s)),
          questions: assignment.questions.map((q) => ({
            ...q,
            chapter: { ...chapters[q.chapter], id: q.chapter },
          })),
        }
      : null
)((_state, id) => id || '')

export const selectAllQuestions = createCachedSelector(
  (state) => state.firebase.data.questionIndex || {},
  (state, subject) => subject,
  (questionIndex, subject) =>
    flatten(
      Object.values(questionIndex[subject] || {}).map((index) => [
        ...Object.keys(index.level1 || {})
          .filter((k) => !index.level1[k].deleted)
          .map((k) => ({ ...index.level1[k], level: 1, id: k })),
        ...Object.keys(index.level2 || {})
          .filter((k) => !index.level2[k].deleted)
          .map((k) => ({ ...index.level2[k], level: 2, id: k })),
        ...Object.keys(index.level3 || {})
          .filter((k) => !index.level3[k].deleted)
          .map((k) => ({ ...index.level3[k], level: 3, id: k })),
      ])
    )
)((state, subject) => subject || '')

export const selectQuestionsByChapter = createCachedSelector(
  (state) => state.firestore.ordered.questions || [],
  (state, chapter) => chapter,
  (questions, chapter) =>
    questions
      .filter((q) => q.chapter === chapter && !q.deleted)
      .sort((a, b) => b.createdAt - a.createdAt)
)((state, chapter) => chapter || '')

export const selectBadgeCount = createSelector(
  (state) => state.firebase.auth.uid,
  (state) => state.firebase.profile.role,
  (state) => state.firebase.profile.isSchool,
  (state) => state.firestore.ordered.assignments || [],
  (state) => state.firestore.ordered.students || [],
  (state) => state.firestore.ordered.teachers || [],
  (uid, role, isSchool, assignments, students, teachers) => ({
    assignment: assignments.filter((a) =>
      role === 'teacher'
        ? !a.deleted && a.teacher === uid && a.dueDate < Date.now() && !a.checked
        : !a.deleted &&
          a.published &&
          (a.students || []).includes(uid) &&
          !get(a, `submissions.${uid}`)
    ).length,
    student:
      role === 'student'
        ? students.filter((s) => !s.accepted && !s.deleted && s.student === uid).length
        : 0,
    school: !isSchool
      ? teachers.filter((t) => !t.accepted && !t.deleted && t.teacher === uid).length
      : 0,
  })
)

export const selectIsFreeTrial = createSelector(
  (state) => state.firebase.profile || {},
  (profile) => {
    const isFreeTrial =
      get(profile, 'createdAt', 0) + 3600 * 24 * 1000 * premiumConfig.freeLimit.freeTrialDays >
      Date.now()
    console.log(
      get(profile, 'createdAt', 0),
      get(profile, 'createdAt', 0) + 3600 * 24 * 1000 * premiumConfig.freeLimit.freeTrialDays,
      Date.now()
    )
    return isFreeTrial
  }
)

export const selectIsPremiumFeaturesPermitted = createSelector(
  (state) => state.firebase.profile || {},
  (state) => state.firestore.ordered.practices || [],
  (state) => state.firestore.ordered.assignments || [],
  selectIsFreeTrial,
  (profile, practices, assignments, isFreeTrial) => {
    const isPremium = !!get(profile, `premium.${get(profile, 'role', 'student')}.quantity`, 0)
    const isWithinDailyLimit =
      (profile.role === 'student' ? practices : assignments).filter((p) => isToday(p.createdAt))
        .length < premiumConfig.freeLimit.practicePerDay
    const isRatedWithinDays =
      Date.now() - (profile.ratedOnAppStoreAt || 0) <
      3600 * 1000 * 24 * premiumConfig.freeLimit.rateOnAppStoreDays
    const isSharedWithinDays =
      Date.now() - (profile.sharedOnFacebookAt || 0) <
      3600 * 1000 * 24 * premiumConfig.freeLimit.shareOnFacebookDays

    const isAvailable = isFreeTrial || isPremium || isRatedWithinDays || isSharedWithinDays
    return {
      isFreeTrial,
      isPremium,
      isWithinDailyLimit,
      isRatedWithinDays,
      isSharedWithinDays,
      isAvailable,
    }
  }
)

export const selectSchoolProfile = createCachedSelector(
  (state) => state.firestore.ordered.teachers || [],
  (state) => state.firestore.ordered.students || [],
  (state, schoolId) => schoolId,
  (teachers, students, schoolId) =>
    ([...teachers, ...students].find((s) => s.school === schoolId) || {}).schoolProfile
)((state, schoolId) => schoolId)
