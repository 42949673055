import React from 'react'
import Box from '@material-ui/core/Box'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import ChapterSelector from './ChapterSelector'
import { selectAllQuestions, selectQuestionsByChapter } from '../../misc/selectors'
import { useFirebaseConnect, useFirestoreConnect, useFirestore } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import HTMLContent from '../../components/HTMLContent'
import Typography from '@material-ui/core/Typography'
import ButtonBase from '@material-ui/core/ButtonBase'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import ImportIcon from '@material-ui/icons/CloudUpload'
import DownloadIcon from '@material-ui/icons/GetApp'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Switch from '@material-ui/core/Switch'
import readXlsxFile from 'read-excel-file'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import useI18n from '../../misc/languages/useI18n'
import { availableLanguages } from '../../config'
import LazyLoad from 'react-lazyload'
import { green } from '@material-ui/core/colors'
import ReportDialog from '../../components/ReportDialog'
import get from 'lodash/get'

const ManageQuestions = () => {
  const theme = useTheme()
  const history = useHistory()
  const firestore = useFirestore()
  const { t } = useI18n()
  const { role, defaultSubject } = useSelector((state) => state.firebase.profile)
  const query = new URLSearchParams(useLocation().search)
  const subject = query.get('subject') || defaultSubject
  const section = query.get('section')
  const chapter = query.get('chapter')
  const level = Number(query.get('level')) || 0
  const unapprove = !!query.get('unapprove')

  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 0)

  const allQuestions = useSelector((state) => selectAllQuestions(state, subject))
  const questions = useSelector((state) => selectQuestionsByChapter(state, chapter))
  const uid = useSelector((state) => state.firebase.auth.uid)

  const [importing, setImporting] = React.useState(false)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      document.getElementById('scroll-memory-element').scrollTo({
        behavior: 'auto',
        top: Number(localStorage.getItem('scrollTop')),
      })
      localStorage.removeItem('scrollTop')
    }, 1000)
    return () => clearTimeout(timeout)
  }, [])

  const createQuestion = async () => {
    try {
      const result = await firestore.add(
        { collection: 'questions' },
        {
          subject,
          chapter,
          level: '1',
          deleted: false,
          createdAt: Date.now(),
          createdBy: uid,
          correctAns: '',
          question: {},
          a: {},
          b: {},
          c: {},
          d: {},
          solution: {},
          approved: false,
          stat: {
            a: 0,
            b: 0,
            c: 0,
            d: 0,
            blank: 0,
          },
        }
      )
      history.push(`/app/manage-question/${result.id}`)
    } catch (err) {
      console.log(err)
    }
  }

  const importQuestions = async (e) => {
    try {
      setImporting(true)
      const rows = await readXlsxFile(e.target.files[0])
      const createdAt = Date.now()
      for (let i = 2; i < rows.length; i++) {
        if (rows[i].length === 15) {
          await firestore.add(
            { collection: 'questions' },
            {
              subject,
              chapter,
              level: rows[i][1].toString(),
              deleted: false,
              createdAt,
              createdBy: uid,
              correctAns: rows[i][12].toString().toLowerCase(),
              question: {
                zh: rows[i][2] || rows[i][3],
                en: rows[i][3] || rows[i][2],
              },
              a: {
                zh: rows[i][4] || rows[i][5],
                en: rows[i][5] || rows[i][4],
              },
              b: {
                zh: rows[i][6] || rows[i][7],
                en: rows[i][7] || rows[i][6],
              },
              c: {
                zh: rows[i][8] || rows[i][9],
                en: rows[i][9] || rows[i][8],
              },
              d: {
                zh: rows[i][10] || rows[i][11],
                en: rows[i][11] || rows[i][10],
              },
              solution: {
                zh: rows[i][13] || rows[i][14],
                en: rows[i][14] || rows[i][13],
              },
              approved: false,
              stat: {
                a: 0,
                b: 0,
                c: 0,
                d: 0,
                blank: 0,
              },
            }
          )
        }
      }
      setImporting(false)
    } catch (err) {
      console.log(err)
    }
  }

  useFirebaseConnect(subject ? [`questionIndex/${subject}`] : [])
  useFirestoreConnect(
    [chapter ? { collection: 'questions', where: [['chapter', '==', chapter]] } : null].filter(
      (a) => a
    )
  )

  const items = questions.filter(
    (q) => (!unapprove || !q.approved) && (!level || level === Number(q.level))
  )

  const [reportingQuestion, setReportingQuestion] = React.useState(null)

  return (
    <WithAppBar title={t('manage questions')}>
      <Box id="scroll-memory-element" height={minAvailableScreenHeight} pb={2} overflow="auto">
        <ChapterSelector
          subject={subject}
          section={section}
          chapter={chapter}
          allQuestions={allQuestions}
        />
        {subject && chapter ? (
          <Box display="flex" m={2} justifyContent="space-between" alignItems="center">
            <Box display="flex" height={42}>
              <Button
                disabled={importing}
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={createQuestion}
              >
                {t('add new question')}
              </Button>
              {role === 'super-admin' ? (
                <>
                  <Box mx={1} />
                  <Button
                    disabled={importing}
                    style={{ width: 200 }}
                    variant="outlined"
                    color="secondary"
                    startIcon={importing ? null : <ImportIcon />}
                    onClick={() => document.getElementById('import-questions').click()}
                  >
                    {importing ? <CircularProgress size={24} /> : t('import questions')}
                  </Button>
                  <input
                    style={{ display: 'none' }}
                    id="import-questions"
                    type="file"
                    accept=".xlsx"
                    onChange={importQuestions}
                  />
                  <Box mx={1} />
                  <Button
                    as={Link}
                    variant="outlined"
                    color="secondary"
                    startIcon={<DownloadIcon />}
                    href="https://docs.google.com/spreadsheets/d/1NVA7VtlpgQNwJPiREEPCLZjILzj_JDUA-M2nJPfM77Y/edit#gid=0"
                    target="_blank"
                  >
                    {t('excel template')}
                  </Button>
                </>
              ) : null}
            </Box>
            <Box display="flex">
              <Box width={100} mr={2}>
                <TextField
                  label={t('level')}
                  select
                  fullWidth
                  value={level}
                  onChange={(e) =>
                    history.replace(
                      `/app/manage-questions?subject=${subject}&section=${section}&chapter=${chapter}${
                        e.target.value ? `&level=${e.target.value}` : ''
                      }`
                    )
                  }
                >
                  {[0, 1, 2, 3].map((l) => (
                    <MenuItem key={l.toString()} value={l}>
                      {l ? l : t('all')}
                      {l ? (
                        <Box ml={2} display="inline-flex">
                          <Typography color="textSecondary">
                            ({questions.filter((q) => Number(q.level) === l).length})
                          </Typography>
                        </Box>
                      ) : null}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={unapprove}
                    onChange={(e) =>
                      history.replace(
                        `/app/manage-questions?subject=${subject}&section=${section}&chapter=${chapter}${
                          e.target.checked ? '&unapprove=true' : ''
                        }`
                      )
                    }
                    color="secondary"
                  />
                }
                label={t('show unapproved only')}
              />
            </Box>
          </Box>
        ) : null}

        {items.map((q) => (
          <LazyLoad scrollContainer="#scroll-memory-element" key={q.id} height={400} once>
            <Box
              m={2}
              boxShadow={3}
              borderRadius="borderRadius"
              overflow="hidden"
              bgcolor="background.paper"
            >
              <ButtonBase
                style={{ width: '100%', textAlign: 'left', position: 'relative' }}
                onClick={() => {
                  localStorage.setItem(
                    'scrollTop',
                    document.getElementById('scroll-memory-element').scrollTop
                  )
                  history.push(`/app/manage-question/${q.id}`)
                }}
              >
                {role === 'super-admin' ? (
                  <Box
                    position="absolute"
                    right={0}
                    top={0}
                    m={1}
                    zIndex={1}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!q.approved}
                          onChange={(e) => {
                            firestore.update(
                              { collection: 'questions', doc: q.id },
                              { approved: e.target.checked }
                            )
                          }}
                          color="primary"
                        />
                      }
                      label={t('approved')}
                    />
                    <Button
                      style={{ width: 120 }}
                      color="secondary"
                      onClick={() => setReportingQuestion(q)}
                    >
                      {t('report')}
                    </Button>
                  </Box>
                ) : null}
                <Box p={2} width="100%">
                  <Box display="flex">
                    <Typography variant="subtitle2">
                      {t('level')}: {q.level}{' '}
                    </Typography>
                    {!q.approved ? (
                      <Typography
                        style={{ marginLeft: theme.spacing(2) }}
                        variant="subtitle2"
                        color="error"
                      >
                        ({t('unapproved')})
                      </Typography>
                    ) : null}
                  </Box>

                  {availableLanguages.map((l) => (
                    <React.Fragment key={l.value}>
                      <Box my={2}>
                        <Typography gutterBottom color="primary">
                          {l.label}
                        </Typography>
                        <HTMLContent html={q.question[l.value]} />
                      </Box>
                      <Grid container>
                        {['a', 'b', 'c', 'd'].map((ans) => (
                          <Grid key={ans} item xs={12} sm={6} md={3}>
                            <Box display="flex">
                              <Box mr={1} position="relative">
                                <Typography>{ans.toUpperCase()}. </Typography>
                                {ans === q.correctAns ? (
                                  <Box
                                    position="absolute"
                                    top={-8}
                                    left={-12}
                                    p={2}
                                    border={2}
                                    borderColor={green[500]}
                                    borderRadius="50%"
                                  />
                                ) : null}
                              </Box>
                              <HTMLContent html={q[ans][l.value]} />
                            </Box>
                          </Grid>
                        ))}
                        <Box my={2}>
                          <Typography gutterBottom variant="subtitle2">
                            {t('solution', { locale: l.value })}
                          </Typography>
                          <HTMLContent html={q.solution[l.value]} />
                        </Box>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Box>
              </ButtonBase>
            </Box>
          </LazyLoad>
        ))}
      </Box>
      <ReportDialog
        isVisible={!!reportingQuestion}
        onClose={() => setReportingQuestion(null)}
        questionId={get(reportingQuestion, 'id')}
        subject={get(reportingQuestion, 'subject')}
        chapter={get(reportingQuestion, 'chapter')}
        withDefaultReasons
      />
    </WithAppBar>
  )
}

export default ManageQuestions
