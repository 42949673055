import React from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useI18n from '../../misc/languages/useI18n'
import { premiumConfig, stripeKey } from '../../config'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import Box from '@material-ui/core/Box'
import { loadStripe } from '@stripe/stripe-js'
import { useFirebase } from 'react-redux-firebase'
import useFirebaseFunctions from '../../misc/useFirebaseFunctions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSnackbar } from 'notistack'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import TextField from '@material-ui/core/TextField'
import { selectStudents, selectSchoolTeachers } from '../../misc/selectors'

const stripePromise = loadStripe(stripeKey)

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[300],
  },
  cardHeaderHighlighted: {
    backgroundColor: theme.palette.primary[400],
  },
  whiteText: {
    color: theme.palette.common.white,
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(-3),
  },
  button: {
    margin: theme.spacing(2),
    maxWidth: 300,
    alignSelf: 'center',
  },
}))

export default function SchoolPricing({ hideTitle, ...props }) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const { enqueueSnackbar } = useSnackbar()
  const { role, premium } = useSelector((state) => get(state, 'firebase.profile'))
  const studentCount = useSelector((state) => selectStudents(state).length)
  const teacherCount = useSelector((state) => selectSchoolTeachers(state).length)
  const isPremium = !!get(premium, 'customerId', false)
  const { t } = useI18n()
  const [subscribe, updateSubscription, getBillingPortal] = useFirebaseFunctions([
    'subscribe',
    'updateSubscription',
    'getBillingPortal',
  ])
  const [loading, setLoading] = React.useState(false)

  const [students, setStudents] = React.useState(get(premium, 'student.quantity', 10))
  const [teachers, setTeachers] = React.useState(get(premium, 'teacher.quantity', 2))

  const onConfirm = async () => {
    try {
      setLoading(true)
      if (isPremium) {
        await updateSubscription({ students, teachers })
        enqueueSnackbar(t('subscription updated'))
      } else {
        const session = await subscribe({
          plans: [
            {
              id: premiumConfig.plans.studentSchoolDiscount.key,
              quantity: students,
            },
            {
              id: premiumConfig.plans.teacherSchoolDiscount.key,
              quantity: teachers,
            },
          ],
          prevPage: window.location.href,
        })
        const stripe = await stripePromise
        const { error } = await stripe.redirectToCheckout({
          sessionId: session.data.id,
        })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const navigateBillingPortal = async () => {
    try {
      setLoading(true)
      const billingPortal = await getBillingPortal({ prevPage: window.location.href })
      window.location.href = get(billingPortal, 'data.url')
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  return (
    <>
      {hideTitle ? null : (
        <Typography variant="h5" color="textPrimary" gutterBottom>
          {t('pricing')}
        </Typography>
      )}
      {isPremium ? (
        <Button disabled={loading} size="large" onClick={navigateBillingPortal} color="primary">
          {t('billing details')}
        </Button>
      ) : null}
      <Box display="flex" width="100%" {...props}>
        <Box flex={1} my={2}>
          <Card>
            <CardHeader
              title={t('pricing calculator')}
              subheader={t('school pricing explain')}
              titleTypographyProps={{
                align: 'center',
                className: classes.whiteText,
              }}
              subheaderTypographyProps={{
                align: 'center',
                className: classes.whiteText,
              }}
              className={classes.cardHeaderHighlighted}
            />
            <CardContent>
              <Box mt={4} display="flex" alignItems="center">
                <Typography style={{ width: 100 }} variant="h6">
                  {t('students')}
                </Typography>
                {isMobile ? null : (
                  <Box mr={2} flex={1} pt={2}>
                    <Slider
                      step={1}
                      min={1}
                      max={Math.max(students, 500)}
                      valueLabelDisplay="on"
                      value={students}
                      onChange={(e, v) => setStudents(v)}
                    />
                  </Box>
                )}
                <TextField
                  type="number"
                  style={{ width: 60 }}
                  value={students}
                  onChange={(e) => setStudents(e.target.value)}
                  inputProps={{
                    step: 1,
                    min: 1,
                  }}
                />
                <Box
                  width={isMobile ? undefined : 120}
                  flex={isMobile ? 1 : undefined}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    ${students * premiumConfig.plans.studentSchoolDiscount.price}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t('per month')}
                  </Typography>
                </Box>
              </Box>
              <Box mt={4} display="flex" alignItems="center">
                <Typography style={{ width: 100 }} variant="h6">
                  {t('teachers')}
                </Typography>
                {isMobile ? null : (
                  <Box mr={2} flex={1} pt={2}>
                    <Slider
                      step={1}
                      min={1}
                      max={Math.max(teachers, 100)}
                      valueLabelDisplay="on"
                      value={teachers}
                      onChange={(e, v) => setTeachers(v)}
                    />
                  </Box>
                )}
                <TextField
                  type="number"
                  style={{ width: 60 }}
                  value={teachers}
                  onChange={(e) => setTeachers(e.target.value)}
                  inputProps={{
                    step: 1,
                    min: 1,
                  }}
                />
                <Box width={120} display="flex" justifyContent="flex-end" alignItems="center">
                  <Typography variant="h6">
                    ${teachers * premiumConfig.plans.teacherSchoolDiscount.price}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t('per month')}
                  </Typography>
                </Box>
              </Box>
              <Box mt={4} display="flex" justifyContent="flex-end" alignItems="center">
                <Typography
                  variant="h6"
                  style={{ fontWeight: '400', marginRight: theme.spacing(1) }}
                >
                  {t('total')}:
                </Typography>
                <Typography variant="h6">
                  $
                  {students * premiumConfig.plans.studentSchoolDiscount.price +
                    teachers * premiumConfig.plans.teacherSchoolDiscount.price}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {t('per month')}
                </Typography>
              </Box>
            </CardContent>
            <CardActions className={classes.actions}>
              <Button
                className={classes.button}
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                onClick={onConfirm}
                disabled={
                  loading ||
                  studentCount > students ||
                  teacherCount > teachers ||
                  !students ||
                  !teachers ||
                  (get(premium, 'student.quantity') === students &&
                    get(premium, 'teacher.quantity') === teachers)
                }
              >
                {loading ? <CircularProgress size={24} /> : t('confirm')}
              </Button>
              {studentCount > students || teacherCount > teachers ? (
                <Typography align="center" color="error">
                  {t('not enough seat', { studentCount, teacherCount })}
                </Typography>
              ) : null}
            </CardActions>
          </Card>
        </Box>
      </Box>
    </>
  )
}
