import React from 'react'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { useForm, Controller } from 'react-hook-form'
import { styled } from '@material-ui/core/styles'
import get from 'lodash/get'
import Button from '@material-ui/core/Button'
import { useFirebase } from 'react-redux-firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import { availableLanguages, availableRoles, facebookPixelId } from '../../config'
// import { useSnackbar } from 'notistack'
import MenuItem from '@material-ui/core/MenuItem'
import useI18n from '../../misc/languages/useI18n'
import ReactPixel from 'react-facebook-pixel'

const Form = styled((props) => <form {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch',
}))

const Input = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const SignUpForm = ({ onBack, isOnboarding, auth }) => {
  const firebase = useFirebase()
  const { t } = useI18n()
  // const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = React.useState(false)
  const { handleSubmit, control, errors, setError } = useForm({ reValidateMode: 'onSubmit' })

  const onSubmit = async ({ email, displayName, role, password, confirmPassword }) => {
    const lang = availableLanguages.find((l) => l.value === navigator.language.substr(0, 2))
    const defaultLang = availableLanguages.find((l) => l.default)
    if (isOnboarding) {
      try {
        await firebase.updateProfile({
          email,
          displayName,
          avatarUrl: get(auth, 'photoURL', ''),
          role: role === 'school' ? 'teacher' : role || get(availableRoles, '[0].value'), // HACK: too trouble to add a "school" role with similar features as "teacher"
          isSchool: role === 'school',
          language: lang ? lang.value : defaultLang.value,
          createdAt: Date.now(),
        })
        facebookPixelId && ReactPixel.track('CompleteRegistration')
      } catch (err) {
        console.log(err)
      }
    } else {
      if (password !== confirmPassword) {
        return setError('confirmPassword', 'mismatch-password', t('mismatch password'))
      }
      try {
        setLoading(true)
        await firebase.createUser(
          { email, password },
          {
            email,
            displayName,
            role: role === 'school' ? 'teacher' : role, // HACK: too trouble to add a "school" role with similar features as "teacher"
            isSchool: role === 'school',
            language: lang ? lang.value : defaultLang.value,
            createdAt: Date.now(),
          }
        )
        facebookPixelId && ReactPixel.track('CompleteRegistration')
        // TODO: its ok not to verify email for now to speed up user registrations
        // await firebase.auth().currentUser.sendEmailVerification()
        // enqueueSnackbar('Verification email has been sent. Please check your inbox')
        // setLoading(false)
      } catch (err) {
        setError('email', 'email-existed', t('email existed'))
        setLoading(false)
      }
    }
  }

  return isOnboarding && !auth.email ? null : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        as={Input}
        name="email"
        label={t('email')}
        control={control}
        defaultValue={get(auth, 'email', '')}
        disabled={isOnboarding}
        error={!!errors.email}
        helperText={get(errors, 'email.message')}
        rules={{
          required: true,
          pattern: {
            value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: t('invalid email'),
          },
        }}
      />
      {isOnboarding ? null : (
        <>
          <Controller
            as={Input}
            name="password"
            type="password"
            label={t('password')}
            control={control}
            defaultValue=""
            error={!!errors.password}
            helperText={get(errors, 'password.message')}
            rules={{
              required: true,
              minLength: {
                value: 6,
                message: t('invalid password'),
              },
            }}
          />
          <Controller
            as={Input}
            name="confirmPassword"
            type="password"
            label={t('confirm password')}
            control={control}
            defaultValue=""
            error={!!errors.confirmPassword}
            helperText={get(errors, 'confirmPassword.message')}
            rules={{ required: true }}
          />
        </>
      )}
      <Controller
        as={Input}
        name="displayName"
        label={t('name')}
        control={control}
        defaultValue={get(auth, 'displayName', '')}
        error={!!errors.displayName}
        helperText={get(errors, 'displayName.message')}
        rules={{ required: true }}
      />
      {availableRoles.length <= 1 ? null : (
        <Controller
          as={Input}
          name="role"
          label={t('i am a')}
          control={control}
          defaultValue=""
          error={!!errors.role}
          helperText={get(errors, 'role.message')}
          rules={{ required: true }}
          select
        >
          {availableRoles.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {t(r.label)}
            </MenuItem>
          ))}
        </Controller>
      )}
      <Box display="flex">
        <Button fullWidth onClick={onBack}>
          {t('back')}
        </Button>
        <Button fullWidth type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : t('sign up')}
        </Button>
      </Box>
    </Form>
  )
}

export default SignUpForm
