import React from 'react'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { useSelector } from 'react-redux'
import AdvancedTable from '../../components/AdvancedTable'
import { selectSchoolTeachers } from '../../misc/selectors'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import ButtonBase from '../../components/ButtonBase'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import get from 'lodash/get'
import { useFirestore } from 'react-redux-firebase'
import ConfirmDialog from '../../components/ConfirmDialog'
import SmallAvatarWithName from '../../components/SmallAvatarWithName'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import format from 'date-fns/format'
import useI18n from '../../misc/languages/useI18n'
import getInitial from '../../misc/getInitial'

const TeacherSchools = () => {
  const firestore = useFirestore()
  const history = useHistory()
  const theme = useTheme()
  const { t } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { height: minAvailableScreenHeight } = useMinAvailableSize(
    ['#app-bar', '#tab-bar'],
    isMobile ? 0 : 4
  )
  const schools = useSelector(selectSchoolTeachers)
  const [deletingSchool, setDeletingSchool] = React.useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const columns = [
    {
      key: 'displayName',
      label: t('name'),
    },
    {
      key: 'students',
      label: t('students'),
    },
    {
      key: 'createdAt',
      label: t('created at'),
    },
    {
      key: 'actions',
      label: '',
      disableSort: true,
    },
  ]
  const rows = schools.map((b) => ({
    key: b.id,
    // onClick: () => history.push(`/app/teacher-statistics/${b.teacher}`),
    profile: b.schoolProfile,
    accepted: b.accepted,
    displayName: {
      label: <SmallAvatarWithName profile={b.schoolProfile} />,
      sortValue: get(b, 'schoolProfile.displayName', ''),
      searchValue: get(b, 'schoolProfile.displayName', ''),
    },
    students: {
      label: b.accepted ? (
        b.students.length ? (
          <Box my={-2}>
            <AvatarGroup max={4}>
              {b.students.map((s) => (
                <Tooltip
                  key={s.student}
                  title={
                    (get(s, 'schoolProfile.displayName', '')
                      ? get(s, 'schoolProfile.displayName', '') + ' - '
                      : '') + get(s, 'studentProfile.displayName', '')
                  }
                >
                  <Avatar src={get(s, 'studentProfile.avatarUrl', '')}>
                    {getInitial(get(s, 'studentProfile.displayName', ''))}
                  </Avatar>
                </Tooltip>
              ))}
            </AvatarGroup>
          </Box>
        ) : (
          t('no student')
        )
      ) : (
        <Typography color="textSecondary">{t('invited you to be teacher')}</Typography>
      ),
      sortValue: b.accepted ? b.students : -1,
      searchValue: b.accepted
        ? b.students.length
          ? b.students
              .map((s) => get(s, 'studentProfile.displayName', ''))
              .reduce((a, b) => a + b, '')
          : t('no student')
        : t('invited you to be teacher'),
    },
    createdAt: {
      label: format(b.createdAt, 'yyyy-MM-dd'),
      sortValue: b.createdAt,
    },
    actions: {
      label: b.accepted ? (
        <Box display="flex" my={-2}>
          <Button color="secondary" onClick={() => setDeletingSchool(b.id)}>
            {t('remove')}
          </Button>
        </Box>
      ) : (
        <Box display={isMobile ? undefined : 'flex'} my={-2}>
          <Button color="secondary" onClick={() => setDeletingSchool(b.id)}>
            {t('reject')}
          </Button>
          <Button
            color="primary"
            onClick={() =>
              firestore.update({ collection: 'teachers', doc: b.id }, { accepted: true })
            }
          >
            {t('accept')}
          </Button>
        </Box>
      ),
      searchValue: '',
    },
  }))

  return (
    <WithAppBar title={t('my schools')} back={isMobile}>
      <AdvancedTable
        containerProps={{ height: minAvailableScreenHeight }}
        rows={rows}
        columns={columns}
        defaultSortBy="createdAt"
        defaultSortOrder={false}
        renderMobileItem={(row) => (
          <ButtonBase key={row.key} onClick={row.onClick}>
            <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
              <SmallAvatarWithName
                moreMargin
                profile={row.profile}
                subtitle={
                  row.accepted ? (
                    <>
                      <Typography variant="body2">{t('students')}</Typography>
                      <Box my={2}>{row.students.label}</Box>
                    </>
                  ) : (
                    row.students.label
                  )
                }
              />
              {row.actions.label}
            </Box>
            <Divider />
          </ButtonBase>
        )}
        emptyText={t('no school')}
      />
      <ConfirmDialog
        isVisible={!!deletingSchool}
        onClose={() => setDeletingSchool(null)}
        message={t('confirm remove school')}
        onSubmit={() => {
          firestore.update({ collection: 'teachers', doc: deletingSchool }, { deleted: true })
          enqueueSnackbar(t('school removed'), { key: deletingSchool })
          setDeletingSchool(null)
        }}
      />
    </WithAppBar>
  )
}

export default TeacherSchools
