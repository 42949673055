import React from 'react'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Box from '@material-ui/core/Box'
import Badge from '@material-ui/core/Badge'
import { useLocation, useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core'
import get from 'lodash/get'

const TabBar = ({ tabs }) => {
  const location = useLocation()
  const history = useHistory()
  const theme = useTheme()
  return (
    <>
      <Box
        id="tab-bar"
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        boxShadow={1}
        bgcolor={theme.palette.background.paper}
        pb={`${get(window, 'ReactNativeWebView.bottomSpace', 0)}px`}
      >
        <BottomNavigation
          value={location.pathname}
          onChange={(e, href) => setTimeout(() => history.replace(href), 200)}
        >
          {tabs.map((item) => (
            <BottomNavigationAction
              key={item.href}
              style={{ paddingTop: theme.spacing(1), minWidth: 50 }}
              value={item.href}
              icon={
                <Badge max={9} badgeContent={item.badge} color="secondary">
                  {item.icon}
                </Badge>
              }
            />
          ))}
        </BottomNavigation>
      </Box>
    </>
  )
}

export default TabBar
