import React from 'react'
import WithAppBar, { MenuWidth } from '../../components/WithAppBar'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { useSelector } from 'react-redux'
import addYears from 'date-fns/addYears'
import startOfToday from 'date-fns/startOfToday'
import endOfToday from 'date-fns/endOfToday'
import { selectStat, selectLatestSubject, selectSchoolProfile } from '../../misc/selectors'
import FilterCard from './FilterCard'
import StatCards from './StatCards'
import ChartCard from './ChartCard'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import AdvancedTable from '../../components/AdvancedTable'
import { useFirestoreConnect } from 'react-redux-firebase'
import get from 'lodash/get'
import { useRouteMatch, useLocation } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useI18n from '../../misc/languages/useI18n'
import AssignmentsTab from './AssignmentsTab'
import { forceCheck } from 'react-lazyload'

const Statistics = () => {
  const theme = useTheme()
  const { t, locale } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 0)
  const { height: tableHeightWithoutBottomSpace } = useMinAvailableSize(
    ['#app-bar', '#tab-bar', '#filter-container'],
    6
  )
  const tableHeight =
    tableHeightWithoutBottomSpace - get(window, 'ReactNativeWebView.bottomSpace', 0)

  const latestSubject = useSelector(selectLatestSubject)
  const { defaultSubject, isSchool } = useSelector((state) => state.firebase.profile)
  const uid = useSelector((state) => state.firebase.auth.uid)
  const [subject, setSubject] = React.useState(defaultSubject || latestSubject)
  const [from, setFrom] = React.useState(addYears(startOfToday(), -1))
  const [to, setTo] = React.useState(endOfToday())
  const [tab, setTab] = React.useState(0)
  const [chartType, setChartType] = React.useState('averageScore')
  const [animateHeight, setAnimateHeight] = React.useState(false)
  const {
    params: { studentId },
  } = useRouteMatch()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const schoolId = query.get('school') || ''
  const schoolProfile = useSelector((state) => selectSchoolProfile(state, schoolId))
  const { chartData, chaptersData, ...stat } = useSelector((state) =>
    selectStat(state, subject, from.getTime(), to.getTime(), studentId, schoolId)
  )

  useFirestoreConnect(
    [
      { collection: 'chapters', where: [['subject', '==', subject || '']] },
      { collection: 'sections', where: [['subject', '==', subject || '']] },
      studentId
        ? {
            collection: 'practices',
            where: [
              ['user', '==', studentId],
              isSchool ? null : ['teacher', '==', uid],
              ['school', '==', schoolId],
            ].filter((a) => a),
          }
        : null,
    ].filter((a) => a)
  )

  const columns = [
    {
      key: 'section',
      label: t('section'),
    },
    {
      key: 'chapter',
      label: t('chapter'),
    },
    {
      key: 'noOfQuestions',
      label: t('questions done'),
    },
    {
      key: 'score',
      label: t('average score'),
    },
    {
      key: 'grade',
      label: t('expected grade'),
    },
  ]

  const rows = chaptersData
    .filter((d) => d.noOfQuestions)
    .map((d) => ({
      key: d.id,
      chapter: {
        label: `${d.chapterNo}. ${get(d, `title.${locale}`, '')}`,
        sortValue: 1000 * get(d, 'section.order', 0) + Number(d.chapterNo),
      },
      section: { label: get(d, `section.title.${locale}`, '') },
      noOfQuestions: { label: d.noOfQuestions.toString(), sortValue: d.noOfQuestions },
      score: {
        label: d.noOfQuestions
          ? `${d.score} / ${d.noOfQuestions} (${Math.round((100 * d.score) / d.noOfQuestions)}%)`
          : '/',
        sortValue: d.score / d.noOfQuestions,
      },
      grade: {
        label: d.noOfQuestions ? (
          <span style={{ color: get(d, 'grade.color'), fontWeight: 'bold' }}>
            {get(d, 'grade.grade')}
          </span>
        ) : (
          '/'
        ),
        sortValue: d.score / d.noOfQuestions,
        searchValue: get(d, 'grade.grade', '/'),
      },
    }))

  React.useEffect(() => {
    setTimeout(() => setAnimateHeight(true), 100)
  }, [])

  React.useEffect(() => {
    setTimeout(forceCheck, 500)
  }, [tab])

  return (
    <WithAppBar title={t('statistics')} back={!!studentId}>
      <Box
        overflow="auto"
        height={minAvailableScreenHeight}
        width={`calc(100vw - ${isMobile ? 0 : MenuWidth}px)`}
      >
        <FilterCard
          from={from}
          to={to}
          subject={subject}
          onFromChange={setFrom}
          onToChange={setTo}
          onSubjectChange={setSubject}
          tab={tab}
          setTab={setTab}
          studentId={studentId}
          schoolProfile={schoolProfile}
        />
        {studentId ? (
          <SwipeableViews
            resistance
            animateHeight={animateHeight}
            disableLazyLoading
            index={tab}
            onChangeIndex={setTab}
          >
            <Box pb={get(window, 'ReactNativeWebView.bottomSpace', 0) + 'px'}>
              <StatCards {...stat} onCardClick={setChartType} activeCard={chartType} />
              <ChartCard chartData={chartData} chartType={chartType} subjectId={subject} />
            </Box>
            <AssignmentsTab
              containerProps={{
                height: isMobile ? undefined : tableHeight,
                mt: 0,
                minHeight: isMobile ? tableHeight : undefined,
                pb: get(window, 'ReactNativeWebView.bottomSpace', 0) + 'px',
              }}
              studentId={studentId}
              schoolId={schoolId}
              subject={subject}
              from={from}
              to={to}
            />
            <AdvancedTable
              containerProps={{
                height: isMobile ? undefined : tableHeight,
                mt: 0,
                minHeight: isMobile ? tableHeight : undefined,
                pb: get(window, 'ReactNativeWebView.bottomSpace', 0) + 'px',
              }}
              rows={rows}
              columns={columns}
              defaultSortBy="chapter"
              defaultSortOrder
              disableSearch
              renderMobileItem={(row) => (
                <React.Fragment key={row.key}>
                  <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Box flex={1}>
                      <Typography>{row.section.label}</Typography>
                      <Typography variant="body2">{row.chapter.label}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {row.score.label}
                      </Typography>
                    </Box>
                    <Typography variant="h5" style={{ color: row.grade.color }}>
                      {row.grade.label}
                    </Typography>
                  </Box>
                  <Divider />
                </React.Fragment>
              )}
              emptyText={t('no data')}
            />
          </SwipeableViews>
        ) : (
          <SwipeableViews
            resistance
            animateHeight={animateHeight}
            disableLazyLoading
            index={tab}
            onChangeIndex={setTab}
          >
            <Box>
              <StatCards {...stat} onCardClick={setChartType} activeCard={chartType} />
              <ChartCard chartData={chartData} chartType={chartType} subjectId={subject} />
            </Box>
            <AdvancedTable
              containerProps={{ height: isMobile ? undefined : tableHeight, mt: 0 }}
              rows={rows}
              columns={columns}
              defaultSortBy="chapter"
              defaultSortOrder
              disableSearch
              renderMobileItem={(row) => (
                <React.Fragment key={row.key}>
                  <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Box flex={1}>
                      <Typography>{row.section.label}</Typography>
                      <Typography variant="body2">{row.chapter.label}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {row.score.label}
                      </Typography>
                    </Box>
                    <Typography variant="h5" style={{ color: row.grade.color }}>
                      {row.grade.label}
                    </Typography>
                  </Box>
                  <Divider />
                </React.Fragment>
              )}
              emptyText={t('no data')}
            />
          </SwipeableViews>
        )}
      </Box>
    </WithAppBar>
  )
}

export default Statistics
