const getGlobalRanking = (score, globalStat) => {
  if (!globalStat) {
    return {
      type: 'top',
      label: 1,
      value: 1,
    }
  }
  const { mean, sd } = globalStat

  const X = (score - mean) / sd
  const T = 1 / (1 + 0.2316419 * Math.abs(X))
  const D = 0.3989423 * Math.exp((-X * X) / 2)
  let prob =
    D * T * (0.3193815 + T * (-0.3565638 + T * (1.781478 + T * (-1.821256 + T * 1.330274))))
  if (X > 0) {
    prob = 1 - prob
  }

  if (prob > 0.5) {
    return {
      type: 'top',
      label: Math.max(Math.ceil((1 - prob) * 100), 1),
      value: prob,
    }
  }
  return {
    type: 'bottom',
    label: Math.max(Math.ceil(prob * 100), 1),
    value: prob,
  }
}

export default getGlobalRanking
