import I18n from 'i18n-js'
import en from './en.json'
import zh from './zh.json'
import { availableLanguages } from '../../config'

// HACK: Using global variable to potentially share code between React and React Native
window.I18n = I18n
window.I18n.translations = { en, zh }
window.I18n.defaultLocale = availableLanguages.find((l) => l.default).value
window.I18n.locale = localStorage.getItem('language') || window.navigator.language.substr(0, 2)
window.I18n.fallbacks = true
