import React from 'react'
import WithAppBar, { MenuWidth } from '../../components/WithAppBar'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { useSelector } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'
import get from 'lodash/get'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useTheme } from '@material-ui/core/styles'
import {
  selectPracticeWithSubjectAndChapters,
  selectAssignmentWithChapter,
} from '../../misc/selectors'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import OverviewTab from './OverviewTab'
import QuestionsTab from './QuestionsTab'
import ChaptersTab from './ChaptersTab'
import Hidden from '@material-ui/core/Hidden'
import TabsView from '../../components/TabsView'
import IconButton from '@material-ui/core/IconButton'
import PrintIcon from '@material-ui/icons/Print'
import PrintDialog from '../../components/PrintDialog'
import useI18n from '../../misc/languages/useI18n'

const Result = () => {
  const theme = useTheme()
  const { t } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const { height: minAvailableScreenHeight } = useMinAvailableSize(
    ['#app-bar', '#tab-bar'],
    isMobile ? 0 : 4
  )
  const {
    params: { practiceId, assignmentId, studentId },
  } = useRouteMatch()
  const [isPrintDialogVisible, setIsPrintDialogVisible] = React.useState(false)
  const tabs = isMobile
    ? [t('overview'), t('questions'), t('chapters')]
    : [t('questions'), t('chapters')]
  const history = useHistory()
  const practice = useSelector((state) =>
    selectPracticeWithSubjectAndChapters(state, practiceId, assignmentId, studentId)
  )
  const assignment = useSelector((state) =>
    selectAssignmentWithChapter(state, get(practice, 'assignment'))
  )
  useFirestoreConnect(
    [
      assignmentId && studentId
        ? {
            collection: 'practices',
            where: [
              ['assignment', '==', assignmentId],
              ['user', '==', studentId],
            ],
          }
        : null,
    ].filter((a) => a)
  )

  React.useEffect(() => {
    if (practice && !get(practice, 'finishedAt')) {
      history.push(`/app/working/${practiceId}/0`)
    }
  }, [practice])

  return practice ? (
    <WithAppBar title={t('result')} back>
      <Box
        height={isMobile ? minAvailableScreenHeight : undefined}
        pb={`${theme.spacing(2) + get(window, 'ReactNativeWebView.bottomSpace', 0)}px`}
        overflow="auto"
        width={isMobile ? '100vw' : `calc(100vw - ${MenuWidth}px)`}
      >
        <Grid container>
          <Hidden smDown>
            <Grid item xs={12} sm={12} md={3}>
              <OverviewTab practice={practice} assignment={assignment} studentId={studentId} />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} md={9}>
            <TabsView
              tabs={tabs}
              rightComponent={
                isMobile ? null : (
                  <IconButton onClick={() => setIsPrintDialogVisible(true)}>
                    <PrintIcon />
                  </IconButton>
                )
              }
            >
              {isMobile ? (
                <Box width={document.body.clientWidth} pb={4}>
                  <OverviewTab practice={practice} assignment={assignment} studentId={studentId} />
                </Box>
              ) : null}
              <QuestionsTab practice={practice} assignmentId={assignmentId} />
              <ChaptersTab practice={practice} />
            </TabsView>
          </Grid>
        </Grid>
      </Box>
      <PrintDialog
        isVisible={isPrintDialogVisible}
        onClose={() => setIsPrintDialogVisible(false)}
        practice={practice}
        assignment={assignment}
      />
    </WithAppBar>
  ) : null
}

export default Result
