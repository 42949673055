import React from 'react'
import get from 'lodash/get'
import { useSelector } from 'react-redux'

const useI18n = () => {
  const language = useSelector((state) => get(state, 'firebase.profile.language'))
  if (window.I18n && language) {
    window.I18n.locale = language
  }
  return window.I18n
}

export default useI18n
