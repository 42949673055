import React from 'react'
import { useFirestore, useFirebase } from 'react-redux-firebase'
import Box from '@material-ui/core/Box'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import AddIcon from '@material-ui/icons/Add'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import DownIcon from '@material-ui/icons/KeyboardArrowDown'
import RemoveIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import SortableList from '../../components/SortableList'
import ConfirmDialog from '../../components/ConfirmDialog'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { availableLanguages, enableCategories } from '../../config'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { selectAllSubjects, selectCategories } from '../../misc/selectors'
import IconButton from '@material-ui/core/IconButton'
import ColorPicker from 'material-ui-color-picker'
import { useSnackbar } from 'notistack'
import useI18n from '../../misc/languages/useI18n'
import MenuItem from '@material-ui/core/MenuItem'

const ManageSubjects = () => {
  const firestore = useFirestore()
  const firebase = useFirebase()
  const { t, locale } = useI18n()
  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 0)
  const subjects = useSelector(selectAllSubjects)
  const categories = useSelector(selectCategories)
  const defaultCategory = useSelector((state) => state.firebase.profile.defaultCategory)
  const { enqueueSnackbar } = useSnackbar()
  const [isItemExpanded, setIsItemExpanded] = React.useState({})
  const [deletingSubjectId, setDeletingSubjectId] = React.useState(null)
  const [category, setCategory] = React.useState(defaultCategory)

  const updateGrade = (subject, newGrade, index) => {
    const newGrades = [...subject.grades]
    newGrades[index] = newGrade
    firestore.update(
      { collection: 'subjects', doc: subject.id },
      { grades: newGrades.filter((g) => g) }
    )
  }
  return (
    <WithAppBar title={t('manage subjects')}>
      <Box height={minAvailableScreenHeight} overflow="auto">
        <Box m={2}>
          {enableCategories ? <TextField
            label={t('category')}
            fullWidth
            select
            value={category}
            onChange={(e) => {
              setCategory(e.target.value)
              firebase.updateProfile({ defaultCategory: e.target.value })
            }}
          >
            <MenuItem value={' '}>{t('all')}</MenuItem>
            {categories.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.title[locale]}
              </MenuItem>
            ))}
          </TextField> : null}
          <SortableList
            defaultItems={subjects.filter((s) =>
              category === ' ' ? true : s.category === category
            )}
            onSort={(result) =>
              result.map((r, order) =>
                firestore.update({ collection: 'subjects', doc: r.id }, { order })
              )
            }
            renderRightItem={(subject) => (
              <Box display="flex">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={subject.published}
                      onChange={(e) =>
                        firestore
                          .collection('subjects')
                          .doc(subject.id)
                          .update({ published: e.target.checked })
                      }
                      color="secondary"
                    />
                  }
                  label={t('publish')}
                />
                <IconButton
                  onClick={() => setIsItemExpanded({ [subject.id]: !isItemExpanded[subject.id] })}
                >
                  {isItemExpanded[subject.id] ? <UpIcon /> : <DownIcon />}
                </IconButton>
                <IconButton
                  color="secondary"
                  disabled={subjects.length <= 1}
                  onClick={() => setDeletingSubjectId(subject.id)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            )}
            renderCenterItem={(subject) => (
              <Box>
                {enableCategories ? (
                  <Box mb={1}>
                    <TextField
                      label={t('category')}
                      defaultValue={subject.category}
                      onChange={(e) =>
                        firestore.update(
                          { collection: 'subjects', doc: subject.id },
                          { category: e.target.value }
                        )
                      }
                      select
                      fullWidth
                    >
                      {categories.map((s) => (
                        <MenuItem key={s.id} value={s.id}>
                          {s.title[locale]}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                ) : null}
                <Box mb={1}>
                  <Typography variant="h6">{t('title')}</Typography>
                </Box>
                <Grid container spacing={2}>
                  {availableLanguages.map((lang) => (
                    <Grid key={lang.value} item xs={12} sm={12} md={6}>
                      <TextField
                        label={lang.label}
                        defaultValue={get(subject, `title.${lang.value}`)}
                        onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
                        onBlur={(e) =>
                          firestore.update(
                            { collection: 'subjects', doc: subject.id },
                            { [`title.${lang.value}`]: e.target.value }
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                  ))}
                </Grid>
                {isItemExpanded[subject.id] ? (
                  <>
                    <Box mt={2} mb={1}>
                      <Typography variant="h6">{t('grades')}</Typography>
                    </Box>
                    {subject.grades.map((grade, index) => (
                      <Grid key={index} container spacing={2}>
                        <Grid item xs={12} sm={12} md={3}>
                          <TextField
                            label={t('name')}
                            defaultValue={grade.grade}
                            onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
                            onBlur={(e) =>
                              updateGrade(subject, { ...grade, grade: e.target.value }, index)
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                          <TextField
                            label={t('min score 0 - 100')}
                            defaultValue={grade.min * 100}
                            onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
                            onBlur={(e) =>
                              updateGrade(subject, { ...grade, min: e.target.value / 100 }, index)
                            }
                            type="number"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                          <TextField
                            label={t('max score 0 - 100')}
                            defaultValue={grade.max * 100}
                            onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
                            onBlur={(e) =>
                              updateGrade(subject, { ...grade, max: e.target.value / 100 }, index)
                            }
                            type="number"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={8} sm={8} md={2}>
                          <ColorPicker
                            label={t('color')}
                            defaultValue={grade.color}
                            onChange={(color) =>
                              color && updateGrade(subject, { ...grade, color }, index)
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={1}>
                          <IconButton
                            color="secondary"
                            onClick={() => updateGrade(subject, null, index)}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                    <Box mt={2}>
                      <Button
                        startIcon={<AddIcon />}
                        color="secondary"
                        variant="contained"
                        onClick={() =>
                          firestore.update(
                            { collection: 'subjects', doc: subject.id },
                            {
                              grades: [
                                ...subject.grades,
                                { grade: '', color: '#000000', min: 0, max: 1 },
                              ],
                            }
                          )
                        }
                      >
                        {t('add grade')}
                      </Button>
                    </Box>
                  </>
                ) : null}
              </Box>
            )}
          />
          <Button
            startIcon={<AddIcon />}
            color="secondary"
            variant="contained"
            onClick={() =>
              firestore.add(
                { collection: 'subjects' },
                { order: subjects.length, grades: [], title: {}, deleted: false, category }
              )
            }
          >
            {t('add subject')}
          </Button>
        </Box>
      </Box>
      <ConfirmDialog
        isVisible={!!deletingSubjectId}
        onClose={() => setDeletingSubjectId(null)}
        message={t('confirm delete subject')}
        onSubmit={() => {
          firestore.update({ collection: 'subjects', doc: deletingSubjectId }, { deleted: true })
          enqueueSnackbar(t('subject deleted'), { key: deletingSubjectId })
          setDeletingSubjectId(null)
        }}
      />
    </WithAppBar>
  )
}

export default ManageSubjects
