import React from 'react'
import Typography from '@material-ui/core/Typography'
import { styled } from '@material-ui/core/styles'

const Content = styled(Typography)(() => ({
  marginBlockStart: 0,
  marginBlockEnd: 0,
  '& p': {
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
}))

const HTMLContent = React.memo(({ html, ...props }) => {
  const [random] = React.useState(Math.random().toString())
  const loadMathContent = () => {
    if (typeof html === 'string' && html.includes('$$')) {
      if (window.renderMathInElement) {
        window.renderMathInElement(document.getElementById(random))
      } else {
        setTimeout(loadMathContent, 100)
      }
    }
  }
  React.useEffect(() => {
    loadMathContent()
  }, [html])
  return <Content id={random} dangerouslySetInnerHTML={{ __html: html }} {...props} />
})

export default HTMLContent
