import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import * as React from 'react'
import get from 'lodash/get'
import set from 'lodash/set'
import times from 'lodash/times'
import DateFnsUtils from '@date-io/date-fns'
import isSafari from 'is-safari'
import { BrowserRouter, Route, useRouteMatch, useHistory, Redirect, Switch } from 'react-router-dom'
import { primaryColor, secondaryColor, enableCategories, firebaseConfig } from '../config'
import { isEmpty, useFirestoreConnect, useFirebase } from 'react-redux-firebase'
import { SnackbarProvider } from 'notistack'
import SignIn from '../pages/SignIn'
import { useSelector } from 'react-redux'
// Admin
import ManageCategories from './ManageCategories'
import ManageSubjects from './ManageSubjects'
import ManageChapters from './ManageChapters'
import ManageQuestions from './ManageQuestions'
import ManageQuestion from './ManageQuestion'
import Admins from './Admins'
import Reports from './Reports'
// Student
import FreePractice from './FreePractice'
import Working from './Working'
import Result from './Result'
import Solution from './Solution'
import PracticeHistory from './PracticeHistory'
import Bookmarks from './Bookmarks'
import Statistics from './Statistics'
import MyTeachers from './MyTeachers'
// Teacher
import MyStudents from './MyStudents'
import CreateAssignment from './CreateAssignment'
import ConfirmAssignment from './ConfirmAssignment'
import Assignments from './Assignments'
import AssignmentResult from './AssignmentResult'
import { selectBadgeCount } from '../misc/selectors'
import SchoolTeachers from './SchoolTeachers'
import TeacherSchools from './TeacherSchools'
import Profile from './Profile'
import More from './More'

const Logout = () => {
  const firebase = useFirebase()
  const history = useHistory()

  const logout = async () => {
    await firebase.updateProfile({ pushToken: '' })
    firebase.logout()
    localStorage.removeItem('lastShowStudentModal')
    localStorage.removeItem('lastShowTeacherModal')
    history.replace('/')
  }
  React.useEffect(() => {
    logout()
  }, [])
  return null
}

const AuthedApp = () => {
  const firebase = useFirebase()
  const uid = useSelector((state) => get(state, 'firebase.auth.uid', ''))
  const profile = useSelector((state) => state.firebase.profile)
  const { role, isSchool } = profile
  const { path } = useRouteMatch()
  const history = useHistory()
  const badge = useSelector((state) =>
    Object.values(selectBadgeCount(state) || {}).reduce((a, b) => a + b, 0)
  )

  // const lastYear = Date.now() - 3600 * 24 * 365 * 1000

  useFirestoreConnect(
    [
      { collection: 'subjects' },
      { collection: 'categories' },
      ['super-admin', 'admin'].includes(role)
        ? { collection: 'reports', where: [['deleted', '==', false]] }
        : undefined,
      { collection: 'sections' },
      { collection: 'chapters' },
      ['teacher', 'student'].includes(role)
        ? {
            collection: 'bookmarks',
            where: [
              ['user', '==', uid],
              // ['createdAt', '>', lastYear],
            ],
          }
        : undefined,
      ['teacher', 'student'].includes(role)
        ? { collection: 'students', where: [[isSchool ? 'school' : role, '==', uid]] }
        : undefined,
      ['teacher', 'student'].includes(role)
        ? {
            collection: 'assignments',
            where: [
              role === 'teacher'
                ? [isSchool ? 'school' : 'teacher', '==', uid]
                : ['students', 'array-contains', uid],
              ['deleted', '==', false],
              // ['createdAt', '>', lastYear],
            ],
          }
        : undefined,
      role === 'student'
        ? {
            collection: 'practices',
            where: [
              ['user', '==', uid],
              ['deleted', '==', false],
              // ['createdAt', '>', lastYear],
            ],
          }
        : undefined,
      role === 'teacher'
        ? {
            collection: 'teachers',
            where: [[isSchool ? 'school' : 'teacher', '==', uid]],
          }
        : undefined,
    ].filter((a) => a)
  )

  React.useEffect(() => {
    // TODO: its ok not to verify email for now to speed up user registrations
    // if (isEmpty(auth) || !auth.emailVerified) {
    if (!uid) {
      history.replace('/')
    } else if (window.ReactNativeWebView) {
      window.ReactNativeWebView.updatePushToken = (pushToken) => {
        // HACK: somehow firebase._.authUid is null even when auth is loaded
        set(firebase, '_.authUid', uid)
        firebase.updateProfile({
          pushToken,
        })
      }
      window.ReactNativeWebView.postMessage(`logged_in::${uid}`)
    }
  }, [uid])

  React.useEffect(() => {
    if (uid) {
      // HACK: somehow firebase._.authUid is null even when auth is loaded
      set(firebase, '_.authUid', uid)
      firebase.updateProfile({ badge })
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(`update_badge::${badge}`)
      }
    }
  }, [firebase, badge])

  React.useEffect(() => {
    firebaseConfig.measurementId &&
      firebase.analytics().setUserProperties({
        role: profile.role,
        language: profile.language,
        isPremium: !!get(profile, `premium.${get(profile, 'role', 'student')}.quantity`, 0),
        ratedOnAppStore: !!profile.ratedOnAppStoreAt,
        sharedOnFacebook: !!profile.sharedOnFacebookAt,
      })
  }, [profile])

  if (role === 'super-admin') {
    return (
      <Switch>
        <Route path={`${path}manage-categories`} component={ManageCategories} />
        <Route path={`${path}manage-subjects`} component={ManageSubjects} />
        <Route path={`${path}manage-chapters`} component={ManageChapters} />
        <Route path={`${path}manage-questions`} component={ManageQuestions} />
        <Route path={`${path}manage-question/:questionId`} component={ManageQuestion} />
        <Route path={`${path}reports`} component={Reports} />
        <Route path={`${path}admins`} component={Admins} />
        <Route path={`${path}profile`} component={Profile} />
        <Redirect to={enableCategories ? `${path}manage-categories` : `${path}manage-subjects`} />
      </Switch>
    )
  } else if (role === 'admin') {
    return (
      <Switch>
        <Route path={`${path}manage-questions`} component={ManageQuestions} />
        <Route path={`${path}manage-question/:questionId`} component={ManageQuestion} />
        <Route path={`${path}profile`} component={Profile} />
        <Redirect to={`${path}manage-questions`} />
      </Switch>
    )
  } else if (role === 'teacher') {
    return (
      <Switch>
        <Route path={`${path}create-assignment`} component={CreateAssignment} />
        {isSchool ? (
          <Route path={`${path}my-teachers`} component={SchoolTeachers} />
        ) : (
          <Route path={`${path}my-schools`} component={TeacherSchools} />
        )}
        <Route path={`${path}my-students`} component={MyStudents} />
        <Route path={`${path}assignments`} component={Assignments} />
        <Route path={`${path}confirm-assignment/:assignmentId`} component={ConfirmAssignment} />
        <Route path={`${path}assignment-result/:assignmentId`} component={AssignmentResult} />
        <Route path={`${path}assignment-submission/:assignmentId/:studentId`} component={Result} />
        <Route path={`${path}solution/:practiceId/:questionNo`} component={Solution} />
        <Route path={`${path}statistics/:studentId`} component={Statistics} />
        <Route path={`${path}bookmarks`} component={Bookmarks} />
        <Route path={`${path}profile`} component={Profile} />
        <Route path={`${path}more`} component={More} />
        <Redirect to={`${path}create-assignment`} />
      </Switch>
    )
  } else if (role === 'student') {
    return (
      <Switch>
        <Route path={`${path}free-practice`} component={FreePractice} />
        <Route path={`${path}assignments`} component={Assignments} />
        <Route path={`${path}working/:practiceId/:questionNo`} component={Working} />
        <Route path={`${path}result/:practiceId`} component={Result} />
        <Route path={`${path}solution/:practiceId/:questionNo`} component={Solution} />
        <Route path={`${path}my-teachers`} component={MyTeachers} />
        <Route path={`${path}practice-history`} component={PracticeHistory} />
        <Route path={`${path}statistics`} component={Statistics} />
        <Route path={`${path}bookmarks`} component={Bookmarks} />
        <Route path={`${path}profile`} component={Profile} />
        <Route path={`${path}more`} component={More} />
        <Redirect to={`${path}free-practice`} />
      </Switch>
    )
  } else {
    return null
  }
}

const Routes = () => {
  const auth = useSelector((state) => get(state, 'firebase.auth'))

  return (
    <BrowserRouter>
      <ThemeProvider
        theme={createMuiTheme({
          overrides: {
            MuiTypography: {
              root: {
                userSelect: 'text',
              },
            },
            // Material UI's Button ripple is problematic on safari
            MuiButtonBase:
              isSafari || navigator.userAgent.includes('iPhone')
                ? {
                    root: {
                      '&:active': {
                        opacity: 0.4,
                      },
                    },
                  }
                : undefined,
          },
          palette: { primary: primaryColor, secondary: secondaryColor },
          shadows: [
            'none',
            ...times(20, () => 'rgb(230, 230, 230) 0px 0px 30px;'),
            ...times(5, () => 'none'),
          ],
          shape: {
            borderRadius: 8,
          },
          props: {
            MuiButtonBase: {
              // Material UI's Button ripple is problematic on safari
              disableRipple: isSafari || navigator.userAgent.includes('iPhone'),
            },
          },
        })}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SnackbarProvider
            preventDuplicate
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          >
            <React.Fragment>
              <CssBaseline />
              <Switch>
                <Route path="/" exact component={SignIn} />
                <Route path="/logout" exact component={Logout} />
                <Route path="/app/" component={AuthedApp} />
                {/* TODO: its ok not to verify email for now to speed up user registrations */}
                {/* <Redirect to={isEmpty(auth) || !auth.emailVerified ? '/' : '/app/'} /> */}
                <Redirect to={!isEmpty(auth) ? '/app/' : '/'} />
              </Switch>
            </React.Fragment>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default Routes
