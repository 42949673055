import React from 'react'
import { useFirestore } from 'react-redux-firebase'
import Box from '@material-ui/core/Box'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import SortableList from '../../components/SortableList'
import ConfirmDialog from '../../components/ConfirmDialog'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { availableLanguages } from '../../config'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { selectCategories } from '../../misc/selectors'
import IconButton from '@material-ui/core/IconButton'
import ColorPicker from 'material-ui-color-picker'
import { useSnackbar } from 'notistack'
import useI18n from '../../misc/languages/useI18n'

const ManageCategories = () => {
  const firestore = useFirestore()
  const { t } = useI18n()
  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 0)
  const categories = useSelector(selectCategories)
  const { enqueueSnackbar } = useSnackbar()
  const [isItemExpanded, setIsItemExpanded] = React.useState({})
  const [deletingCategoryId, setDeletingCategoryId] = React.useState(null)

  return (
    <WithAppBar title={t('manage categories')}>
      <Box height={minAvailableScreenHeight} overflow="auto">
        <Box m={2}>
          <SortableList
            defaultItems={categories}
            onSort={(result) =>
              result.map((r, order) =>
                firestore.update({ collection: 'categories', doc: r.id }, { order })
              )
            }
            renderRightItem={(category) => (
              <Box display="flex">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={category.published}
                      onChange={(e) =>
                        firestore
                          .collection('categories')
                          .doc(category.id)
                          .update({ published: e.target.checked })
                      }
                      color="secondary"
                    />
                  }
                  label={t('publish')}
                />
                <IconButton
                  color="secondary"
                  disabled={categories.length <= 1}
                  onClick={() => setDeletingCategoryId(category.id)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            )}
            renderCenterItem={(category) => (
              <Box>
                <Box mb={1}>
                  <Typography variant="h6">{t('title')}</Typography>
                </Box>
                <Grid container spacing={2}>
                  {availableLanguages.map((lang) => (
                    <Grid key={lang.value} item xs={12} sm={12} md={6}>
                      <TextField
                        label={lang.label}
                        defaultValue={get(category, `title.${lang.value}`)}
                        onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
                        onBlur={(e) =>
                          firestore.update(
                            { collection: 'categories', doc: category.id },
                            { [`title.${lang.value}`]: e.target.value }
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          />
          <Button
            startIcon={<AddIcon />}
            color="secondary"
            variant="contained"
            onClick={() =>
              firestore.add(
                { collection: 'categories' },
                { order: categories.length, grades: [], title: {}, deleted: false }
              )
            }
          >
            {t('add category')}
          </Button>
        </Box>
      </Box>
      <ConfirmDialog
        isVisible={!!deletingCategoryId}
        onClose={() => setDeletingCategoryId(null)}
        message={t('confirm delete category')}
        onSubmit={() => {
          firestore.update({ collection: 'categories', doc: deletingCategoryId }, { deleted: true })
          enqueueSnackbar(t('category deleted'), { key: deletingCategoryId })
          setDeletingCategoryId(null)
        }}
      />
    </WithAppBar>
  )
}

export default ManageCategories
