import React from 'react'
import MoreIcon from '@material-ui/icons/Menu'
import Badge from '@material-ui/core/Badge'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import Box from '@material-ui/core/Box'
import ProfileForm from './ProfileForm'
import useI18n from '../../misc/languages/useI18n'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectBadgeCount } from '../../misc/selectors'

const Profile = () => {
  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 0)
  const { t } = useI18n()
  const role = useSelector((state) => state.firebase.profile.role)
  const history = useHistory()
  const badgeCount = useSelector(selectBadgeCount)
  return (
    <WithAppBar
      title={t('profile')}
      rightButtonIcon={
        ['student', 'teacher'].includes(role) ? (
          <Badge
            max={9}
            badgeContent={role === 'student' ? badgeCount.student : badgeCount.school}
            color="secondary"
          >
            <MoreIcon />
          </Badge>
        ) : null
      }
      onRightButtonClick={() => history.push('/app/more')}
      hideRightButtonOnDesktop
    >
      <Box height={minAvailableScreenHeight} overflow="auto">
        <Box
          m={2}
          p={2}
          borderRadius="borderRadius"
          overflow="hidden"
          boxShadow={3}
          bgcolor="background.paper"
        >
          <ProfileForm />
        </Box>
      </Box>
    </WithAppBar>
  )
}

export default Profile
