import React from 'react'
import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Badge from '@material-ui/core/Badge'

const MenuItem = ({ item, location, isMobile, history }) => (
  <ListItem
    onClick={() => {
      if (item.onClick) {
        item.onClick()
      } else if (isMobile) {
        setTimeout(() => history.push(item.href), 500)
      } else {
        history.push(item.href)
      }
    }}
    button
    selected={location.pathname === item.href}
  >
    <ListItemIcon>{item.icon}</ListItemIcon>
    <ListItemText primary={item.title} />
    {item.badge ? (
      <Box mr={1}>
        <Badge max={9} badgeContent={item.badge} color="secondary" />
      </Box>
    ) : null}
  </ListItem>
)

export default MenuItem
