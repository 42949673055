import React from 'react'
import { useFirestore } from 'react-redux-firebase'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import RemoveIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import ConfirmDialog from '../../components/ConfirmDialog'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { useSelector } from 'react-redux'
import { selectSectionsAndChaptersBySubject } from '../../misc/selectors'
import { useSnackbar } from 'notistack'
import HTMLEditor from '../../components/HTMLEditor'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import { useHistory } from 'react-router-dom'
import useI18n from '../../misc/languages/useI18n'
import ReportDialog from '../../components/ReportDialog'

const QuestionEditor = ({ questionId, question, onApproveChange }) => {
  const firestore = useFirestore()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { t, locale } = useI18n()
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = React.useState(false)
  const [reportingQuestionDialogOpen, setReportingQuestionDialogOpen] = React.useState(false)
  const sections = useSelector((state) =>
    question ? selectSectionsAndChaptersBySubject(state, question.subject) : []
  )
  const isSuperAdmin = useSelector((state) => get(state, 'firebase.profile.role') === 'super-admin')

  return question ? (
    <>
      <Box m={2} mt={4} mb={0}>
        <Typography variant="h5">{t('properties')}</Typography>
      </Box>
      <Box m={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3}>
            <TextField label="ID" fullWidth disabled value={question.id} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextField
              label={t('chapter')}
              fullWidth
              select
              value={question.chapter}
              onChange={(e) =>
                firestore.update(
                  { collection: 'questions', doc: question.id },
                  { chapter: e.target.value }
                )
              }
            >
              {flatten(sections.map((s) => s.chapters)).map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {s.chapterNo}. {s.title[locale]} {}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextField
              label={t('level')}
              fullWidth
              select
              value={question.level}
              onChange={(e) =>
                firestore.update(
                  { collection: 'questions', doc: question.id },
                  { level: e.target.value }
                )
              }
            >
              {['1', '2', '3'].map((s) => (
                <MenuItem key={s} value={s}>
                  {s}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Box ml={2}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!isSuperAdmin}
                    checked={!!question.approved}
                    onChange={(e) => {
                      onApproveChange && onApproveChange(e.target.checked)
                      firestore.update(
                        { collection: 'questions', doc: question.id },
                        { approved: e.target.checked }
                      )
                    }}
                    color="primary"
                  />
                }
                label={t('approved')}
              />
              <Button
                style={{ width: 120 }}
                color="secondary"
                onClick={() => setReportingQuestionDialogOpen(true)}
              >
                {t('report')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box m={2} mt={4}>
        <Typography variant="h5">{t('question')}</Typography>
      </Box>
      <HTMLEditor
        value={question.question}
        onChange={(question) =>
          firestore.update({ collection: 'questions', doc: questionId }, { question })
        }
      />
      <Grid container>
        {['a', 'b', 'c', 'd'].map((ans) => (
          <Grid key={ans} item xs={12} sm={12} md={6}>
            <Box mt={4} m={2} display="flex" justifyContent="space-between">
              <Typography variant="h5">{ans.toUpperCase()}</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={ans === question.correctAns}
                    onChange={(e) =>
                      firestore.update(
                        { collection: 'questions', doc: question.id },
                        { correctAns: ans }
                      )
                    }
                    color="primary"
                  />
                }
                label={t('correct answer')}
              />
            </Box>
            <HTMLEditor
              value={question[ans]}
              onChange={(v) =>
                firestore.update({ collection: 'questions', doc: questionId }, { [ans]: v })
              }
            />
          </Grid>
        ))}
      </Grid>
      <Box m={2} mt={4}>
        <Typography variant="h5">{t('solution')}</Typography>
      </Box>
      <HTMLEditor
        value={question.solution}
        onChange={(solution) =>
          firestore.update({ collection: 'questions', doc: questionId }, { solution })
        }
      />
      <Box m={2} mt={4}>
        <Button
          startIcon={<RemoveIcon />}
          variant="contained"
          color="secondary"
          onClick={() => setConfirmDeleteDialogOpen(true)}
        >
          {t('delete question')}
        </Button>
      </Box>
      <ConfirmDialog
        isVisible={confirmDeleteDialogOpen}
        onClose={() => setConfirmDeleteDialogOpen(false)}
        message={t('confirm delete question')}
        onSubmit={() => {
          firestore.update({ collection: 'questions', doc: question.id }, { deleted: true })
          enqueueSnackbar(t('question deleted'), { key: question.id })
          history.goBack()
        }}
      />
      <ReportDialog
        isVisible={reportingQuestionDialogOpen}
        onClose={() => setReportingQuestionDialogOpen(false)}
        questionId={question.id}
        subject={question.subject}
        chapter={question.chapter}
      />
    </>
  ) : null
}

export default QuestionEditor
