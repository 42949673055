import React from 'react'
import Box from '@material-ui/core/Box'
import AdvancedTable from '../../components/AdvancedTable'
import ButtonBase from '../../components/ButtonBase'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import groupBy from 'lodash/groupBy'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import get from 'lodash/get'
import maxBy from 'lodash/maxBy'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useHistory, useLocation } from 'react-router-dom'
import useI18n from '../../misc/languages/useI18n'

const ChaptersTab = ({ practice }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const history = useHistory()
  const location = useLocation()
  const { t, locale } = useI18n()
  const { height: tabContentSizeWithoutBottomSpace } = useMinAvailableSize(
    ['#app-bar', '#tab-bar', '#tabs-bar'],
    6
  )
  const tabContentSize =
    tabContentSizeWithoutBottomSpace - get(window, 'ReactNativeWebView.bottomSpace', 0)
  const questionsGroupedByChapters = practice
    ? Object.values(groupBy(practice.questions, (q) => get(q, 'chapter.id', ''))).map((c) => ({
        ...get(c, '[0]chapter', {}),
        score: c.filter((q) => q.ans === q.correctAns).length,
        count: c.length,
      }))
    : []

  const columns = [
    {
      key: 'chapter',
      label: t('chapter'),
    },
    {
      key: 'performance',
      label: t('performance'),
    },
  ]

  const rows = questionsGroupedByChapters.map((q, i) => ({
    key: q.id,
    onClick: () =>
      history.replace(
        `${location.pathname}?tab=${t('questions')}&search=${`${get(q, 'chapterNo', '')}. ${get(
          q,
          `title.${locale}`,
          ''
        )}`}`
      ),
    chapter: {
      label: `${get(q, 'chapterNo', '')}. ${get(q, `title.${locale}`, '')}`,
      sortValue: Number(get(q, 'chapterNo', 0)),
    },
    performance: {
      label: `${q.score} / ${q.count} (${Math.round((100 * q.score) / q.count)}%)`,
      sortValue: q.score / q.count,
      color: get(
        maxBy(
          practice.subject.grades.filter(
            (g) => (q.score / q.count || 0) >= g.min && (q.score / q.count || 0) <= g.max
          ),
          (g) => g.max
        ),
        'color'
      ),
    },
    ...q,
  }))

  return (
    <Box pt={2} pb={isMobile ? 2 : 0}>
      <AdvancedTable
        containerProps={{
          pb: get(window, 'ReactNativeWebView.bottomSpace', 0) + 'px',
          mb: 0,
          mt: 0,
          ml: isMobile ? 2 : 0,
          height: isMobile ? undefined : tabContentSize,
          minHeight: isMobile ? tabContentSize : undefined,
        }}
        columns={columns}
        rows={rows}
        defaultSortBy="chapter"
        defaultSortOrder
        renderMobileItem={(row) => (
          <ButtonBase key={row.key} onClick={row.onClick}>
            <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
              <Box flex={1}>
                <Typography variant="body2">{row.chapter.label}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {t('performance')}: {row.score} / {row.count}
                </Typography>
              </Box>
              <Typography variant="h5" style={{ color: row.performance.color }}>
                {Math.round((100 * row.score) / row.count) || 0}%
              </Typography>
            </Box>
            <Divider />
          </ButtonBase>
        )}
        emptyText={t('no chapter')}
      />
    </Box>
  )
}

export default ChaptersTab
