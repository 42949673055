import React from 'react'
import StatCard from '../../components/StatCard'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import get from 'lodash/get'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import useI18n from '../../misc/languages/useI18n'

const StatCards = ({
  questionsDone,
  averageScore,
  timeSpentPerQuestion,
  globalRanking,
  expectedGrade,
  papersDone,
  onCardClick,
  activeCard,
}) => {
  const theme = useTheme()
  const { t } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  return isMobile ? (
    <Box m={2} mt={0} id="stat-cards-container">
      <Box mb={2}>
        <StatCard
          title={t('papers done')}
          value={papersDone}
          active={activeCard === 'papersDone'}
          onClick={() => onCardClick('papersDone')}
        />
      </Box>
      <Box mb={2}>
        <StatCard
          title={t('questions done')}
          value={questionsDone}
          active={activeCard === 'questionsDone'}
          onClick={() => onCardClick('questionsDone')}
        />
      </Box>
      <Box mb={2}>
        <StatCard
          title={t('time spent per question')}
          value={`${Math.round(timeSpentPerQuestion)}s`}
          active={activeCard === 'timeSpentPerQuestion'}
          onClick={() => onCardClick('timeSpentPerQuestion')}
        />
      </Box>
      <Box mb={2}>
        <StatCard
          title={t('average score')}
          value={`${Math.round(averageScore * 100)}%`}
          active={activeCard === 'averageScore'}
          onClick={() => onCardClick('averageScore')}
        />
      </Box>
      <Box mb={2}>
        <StatCard
          title={t('global ranking')}
          value={t(`ranking ${get(globalRanking, 'type', 'top')}`, {
            percent: get(globalRanking, 'label', 1),
          })}
          active={activeCard === 'globalRanking'}
          onClick={() => onCardClick('globalRanking')}
          masked
        />
      </Box>
      <Box mb={2}>
        <StatCard
          title={t('expected grade')}
          color={get(expectedGrade, 'color')}
          value={get(expectedGrade, 'grade', '/')}
          active={activeCard === 'expectedGrade'}
          onClick={() => onCardClick('expectedGrade')}
        />
      </Box>
    </Box>
  ) : (
    <Box m={2} mt={0} id="stat-cards-container">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title={t('papers done')}
            value={papersDone}
            active={activeCard === 'papersDone'}
            onClick={() => onCardClick('papersDone')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title={t('questions done')}
            value={questionsDone}
            active={activeCard === 'questionsDone'}
            onClick={() => onCardClick('questionsDone')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title={t('time spent per question')}
            value={`${Math.round(timeSpentPerQuestion)}s`}
            active={activeCard === 'timeSpentPerQuestion'}
            onClick={() => onCardClick('timeSpentPerQuestion')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title={t('average score')}
            value={`${Math.round(averageScore * 100)}%`}
            active={activeCard === 'averageScore'}
            onClick={() => onCardClick('averageScore')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title={t('global ranking')}
            value={t(`ranking ${get(globalRanking, 'type', 'top')}`, {
              percent: get(globalRanking, 'label', 1),
            })}
            active={activeCard === 'globalRanking'}
            onClick={() => onCardClick('globalRanking')}
            masked
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title={t('expected grade')}
            color={get(expectedGrade, 'color')}
            value={get(expectedGrade, 'grade', '/')}
            active={activeCard === 'expectedGrade'}
            onClick={() => onCardClick('expectedGrade')}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default StatCards
