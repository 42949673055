import React from 'react'
import Box from '@material-ui/core/Box'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { Line } from 'react-chartjs-2'
import get from 'lodash/get'
import maxBy from 'lodash/maxBy'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { selectSubjectWithOrderedGrades } from '../../misc/selectors'
import { useSelector } from 'react-redux'
import { MenuWidth } from '../../components/WithAppBar'
import useI18n from '../../misc/languages/useI18n'

const ChartCard = ({ chartData, chartType, subjectId }) => {
  const subject = useSelector((state) => selectSubjectWithOrderedGrades(state, subjectId))
  const { t } = useI18n()
  const chartTypes = {
    papersDone: {
      label: t('papers done'),
      mapFunction: (stat) => stat.papersDone,
    },
    questionsDone: {
      label: t('questions done'),
      mapFunction: (stat) => stat.questionsDone,
    },
    averageScore: {
      label: t('average score'),
      mapFunction: (stat) => stat.averageScore * 100,
    },
    timeSpentPerQuestion: {
      label: t('time spent per question'),
      mapFunction: (stat) => stat.timeSpentPerQuestion,
    },
    globalRanking: {
      label: t('global ranking'),
      mapFunction: (stat) => get(stat, 'globalRanking.value', 0.99) * 100,
    },
    expectedGrade: {
      label: t('expected grade'),
      mapFunction: (stat) => get(stat, 'expectedGrade.order', 0),
      yAxesTicksOptions: {
        max: subject ? (maxBy(subject.grades, 'order') || {}).order : undefined,
        callback: (v) => {
          return subject
            ? get(
                subject.grades.find((g) => g.order === v),
                'grade',
                ''
              )
            : v
        },
      },
    },
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const { height } = useMinAvailableSize(
    ['#app-bar', '#tab-bar', '#filter-container', '#stat-cards-container'],
    6
  )
  const chartHeight = Math.max(height, 240)
  const [reloading, setReloading] = React.useState(false)

  const data = {
    datasets: [
      {
        label: chartTypes[chartType].label,
        data: chartData.map(chartTypes[chartType].mapFunction),
        backgroundColor: 'transparent',
        borderColor: theme.palette.primary[500],
      },
    ],
    labels: chartData.map((d) => d.date),
  }
  const options = {
    maintainAspectRatio: false,
    legend: {
      labels: {
        boxWidth: 0,
        fontSize: 21,
      },
    },
    scales: {
      xAxes: [{ ticks: { fontSize: 16 } }],
      yAxes: [
        {
          ticks: {
            fontSize: 16,
            beginAtZero: true,
            ...(chartTypes[chartType].yAxesTicksOptions || {}),
          },
        },
      ],
    },
  }

  React.useEffect(() => {
    setReloading(true)
  }, [chartType])
  React.useEffect(() => {
    reloading && setReloading(false)
  }, [reloading])

  return (
    <Box
      m={2}
      mt={0}
      mr={0}
      borderRadius="borderRadius"
      overflow="hidden"
      boxShadow={3}
      bgcolor="background.paper"
      height={chartHeight}
      width={`calc(100vw - ${(isMobile ? 0 : MenuWidth) + theme.spacing(4)}px)`}
    >
      {reloading ? null : <Line data={data} options={options} height={chartHeight} />}
    </Box>
  )
}

export default ChartCard
