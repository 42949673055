import React from 'react'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { useSelector } from 'react-redux'
import AdvancedTable from '../../components/AdvancedTable'
import { selectTeachers } from '../../misc/selectors'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import { useFirestore } from 'react-redux-firebase'
import ConfirmDialog from '../../components/ConfirmDialog'
import SmallAvatarWithName from '../../components/SmallAvatarWithName'
import { useSnackbar } from 'notistack'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Divider from '@material-ui/core/Divider'
import ButtonBase from '../../components/ButtonBase'
import format from 'date-fns/format'
import useI18n from '../../misc/languages/useI18n'

const MyTeachers = () => {
  const firestore = useFirestore()
  const theme = useTheme()
  const { t } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 4)
  const teachers = useSelector(selectTeachers)
  const shouldShowSchoolSelector = teachers.find((s) => !!s.school)
  const [deletingTeacher, setDeletingTeacher] = React.useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const columns = [
    {
      key: 'displayName',
      label: t('name'),
    },
    {
      key: 'pendingAssignments',
      label: t('on going assignments'),
    },
    {
      key: 'lateAssignments',
      label: t('late'),
    },
    {
      key: 'totalAssignments',
      label: t('total'),
    },
    {
      key: 'punctuality',
      label: t('punctuality'),
    },
    {
      key: 'createdAt',
      label: t('created at'),
    },
    {
      key: 'actions',
      label: '',
      disableSort: true,
    },
  ]
  const rows = teachers.map((b) => ({
    key: b.id,
    profile: b.teacherProfile,
    accepted: b.accepted,
    school: {
      id: b.school,
    },
    displayName: {
      label: (
        <SmallAvatarWithName
          profile={get(b, 'teacherProfile', '')}
          subtitle={get(b, 'schoolProfile.displayName', '')}
        />
      ),
      searchValue:
        get(b, 'teacherProfile.displayName', '') + get(b, 'schoolProfile.displayName', ''),
      sortValue: get(b, 'teacherProfile.displayName', ''),
    },
    pendingAssignments: {
      label: b.accepted ? (
        b.pendingAssignments
      ) : (
        <Typography color="textSecondary">{t('invited you to be student')}</Typography>
      ),
      sortValue: b.accepted ? b.pendingAssignments : -1,
      searchValue: b.accepted ? b.pendingAssignments.toString() : t('invited you to be student'),
    },
    lateAssignments: {
      label: b.accepted ? `${b.lateAssignments}` : '',
      sortValue: b.lateAssignments,
    },
    totalAssignments: {
      label: b.accepted ? `${b.totalAssignments}` : '',
      sortValue: b.totalAssignments,
    },
    punctuality: {
      label: b.accepted
        ? `${Math.round(100 * (1 - b.lateAssignments / b.totalAssignments)) || 0}%`
        : '',
      sortValue: (b.totalAssignments - b.lateAssignments) / b.totalAssignments,
    },
    createdAt: {
      label: format(b.createdAt, 'yyyy-MM-dd'),
      sortValue: b.createdAt,
    },
    actions: {
      label: b.accepted ? (
        <Box display="flex" my={-2}>
          <Button color="secondary" onClick={() => setDeletingTeacher(b.id)}>
            {t('remove')}
          </Button>
        </Box>
      ) : (
        <Box display={isMobile ? undefined : 'flex'} my={-2}>
          <Button color="secondary" onClick={() => setDeletingTeacher(b.id)}>
            {t('reject')}
          </Button>
          <Button
            color="primary"
            onClick={() =>
              firestore.update({ collection: 'students', doc: b.id }, { accepted: true })
            }
          >
            {t('accept')}
          </Button>
        </Box>
      ),
      searchValue: '',
    },
  }))

  const defaultSchool = get(
    rows.find((r) => !r.accepted),
    'school.id'
  )
  console.log(defaultSchool)
  return (
    <WithAppBar title={t('my teachers')} back={isMobile}>
      <AdvancedTable
        withSchoolFilter={shouldShowSchoolSelector}
        defaultSchool={defaultSchool}
        containerProps={{ height: minAvailableScreenHeight }}
        rows={rows}
        columns={columns}
        defaultSortBy="createdAt"
        defaultSortOrder={false}
        renderMobileItem={(row) => (
          <ButtonBase key={row.key} onClick={row.onClick}>
            <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
              <SmallAvatarWithName
                moreMargin
                profile={row.profile}
                subtitle={
                  row.accepted ? (
                    <>
                      <Typography variant="body2" color="textSecondary">
                        {t('on going')}: {row.pendingAssignments.label} | {t('late')}:{' '}
                        {row.lateAssignments.label} | {t('total')}: {row.totalAssignments.label}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {t('punctuality')}: {row.punctuality.label}
                      </Typography>
                    </>
                  ) : (
                    row.pendingAssignments.label
                  )
                }
              />
              {row.actions.label}
            </Box>
            <Divider />
          </ButtonBase>
        )}
        emptyText={t('no teacher')}
      />
      <ConfirmDialog
        isVisible={!!deletingTeacher}
        onClose={() => setDeletingTeacher(null)}
        message={t('confirm remove teacher')}
        onSubmit={() => {
          firestore.update({ collection: 'students', doc: deletingTeacher }, { deleted: true })
          enqueueSnackbar(t('teacher removed'), { key: deletingTeacher })
          setDeletingTeacher(null)
        }}
      />
    </WithAppBar>
  )
}

export default MyTeachers
