import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import useI18n from '../../misc/languages/useI18n'
import Pricing from '../../pages/Profile/Pricing'
import SchoolPricing from '../../pages/Profile/SchoolPricing'
import Box from '@material-ui/core/Box'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { premiumConfig, landingPage } from '../../config'
import { selectStudents, selectSchoolTeachers } from '../../misc/selectors'
import Button from '@material-ui/core/Button'
import { Share } from 'react-facebook'
import { useTheme, styled } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useFirebase } from 'react-redux-firebase'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import { useSnackbar } from 'notistack'

const FacebookButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#3c5898',
  color: 'white',
  '&:hover': {
    backgroundColor: '#29487d',
  },
}))

const PremiumDialog = ({ isVisible, onClose }) => {
  const { t } = useI18n()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const firebase = useFirebase()
  const { role, isSchool, sharedOnFacebookAt, ratedOnAppStoreAt } = useSelector((state) =>
    get(state, 'firebase.profile', {})
  )
  const sharedOnFacebook = !!sharedOnFacebookAt
  const ratedOnAppStore = !!ratedOnAppStoreAt
  const studentCount = useSelector((state) => selectStudents(state).length)
  const teacherCount = useSelector((state) => selectSchoolTeachers(state).length)

  const [isRating, setIsRating] = React.useState(false)

  React.useEffect(() => {
    if (isVisible && window.ReactNativeWebView) {
      window.ReactNativeWebView.ratedOnAppStore = () => {
        // Rated
        firebase.updateProfile({ ratedOnAppStoreAt: Date.now() })
        setIsRating(false)
        enqueueSnackbar(t('thank you for rating'))
      }
    }
  }, [isVisible])

  return (
    <Dialog open={isVisible} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{t('pricing')}</DialogTitle>
      <DialogContent>
        <DialogContentText
          dangerouslySetInnerHTML={{
            __html: isSchool
              ? t('not enough seat', {
                  studentCount,
                  teacherCount,
                })
              : t(`free limit ${role}`, { ...premiumConfig.freeLimit, sharedOnFacebook }),
          }}
        />
        {window.ReactNativeWebView &&
        !ratedOnAppStore &&
        premiumConfig.freeLimit.rateOnAppStoreDays ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={4}
            pb={4}
            mb={2}
            borderBottom={`1px solid ${theme.palette.divider}`}
          >
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => {
                setIsRating(true)
                window.ReactNativeWebView.postMessage('rate_on_app_store')
              }}
              startIcon={<ThumbUpIcon />}
              disabled={isRating}
            >
              {t('rate us on app store')}
            </Button>
            <Box px={1} bgcolor={theme.palette.common.white} mb={-6} mt={2}>
              <Typography>{t('or')}</Typography>
            </Box>
          </Box>
        ) : null}
        <Box
          display={
            !sharedOnFacebook && premiumConfig.freeLimit.shareOnFacebookDays ? 'flex' : 'none'
          }
          flexDirection="column"
          alignItems="center"
          mt={4}
          pb={4}
          mb={2}
          borderBottom={`1px solid ${theme.palette.divider}`}
        >
          <Share href={landingPage}>
            {({ handleClick, data, error, loading }) => {
              if (!error && data) {
                // Shared
                firebase.updateProfile({ sharedOnFacebookAt: Date.now() })
                enqueueSnackbar(t('thank you for sharing'))
              }
              return (
                <FacebookButton
                  size="large"
                  onClick={handleClick}
                  disabled={loading}
                  startIcon={
                    <img src={require('../../assets/images/facebook.svg')} width={20} height={20} />
                  }
                >
                  {t('share on facebook')}
                </FacebookButton>
              )
            }}
          </Share>
          <Box px={1} bgcolor={theme.palette.common.white} mb={-6} mt={2}>
            <Typography>{t('or')}</Typography>
          </Box>
        </Box>
        <Box mt={2}>
          {isSchool ? (
            <SchoolPricing hideTitle />
          ) : (
            <Pricing hideTitle skipFree justifyContent="center" />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default PremiumDialog
