import React from 'react'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { useSelector } from 'react-redux'
import { selectSubjects, selectSectionsAndChaptersBySubject } from '../../misc/selectors'
import { useHistory } from 'react-router-dom'
import CrossIcon from '@material-ui/icons/Close'
import TickIcon from '@material-ui/icons/Check'
import { green, red } from '@material-ui/core/colors'
import { useTheme } from '@material-ui/core/styles'
import useI18n from '../../misc/languages/useI18n'
import { enableCategories } from '../../config'
import { useFirebase } from 'react-redux-firebase'

const ChapterSelector = ({ subject, section, chapter, allQuestions }) => {
  const theme = useTheme()
  const history = useHistory()
  const { t, locale } = useI18n()
  const firebase = useFirebase()
  const subjects = useSelector(selectSubjects)
  const sections = useSelector((state) => selectSectionsAndChaptersBySubject(state, subject))

  return (
    <Box m={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label={t('subject')}
            fullWidth
            select
            value={subject}
            onChange={(e) => {
              history.replace(`/app/manage-questions?subject=${e.target.value}`)
              firebase.updateProfile({ defaultSubject: e.target.value })
            }}
          >
            {subjects.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {enableCategories && s.category ? s.category.title[locale] + ' - ' : ''}
                {s.title[locale]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <TextField
            label={t('section')}
            fullWidth
            select
            value={section}
            onChange={(e) =>
              history.replace(`/app/manage-questions?subject=${subject}&section=${e.target.value}`)
            }
          >
            {sections.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.title[locale]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <TextField
            label={t('chapter')}
            fullWidth
            select
            value={chapter}
            onChange={(e) =>
              history.replace(
                `/app/manage-questions?subject=${subject}&section=${section}&chapter=${e.target.value}`
              )
            }
            disabled={!section}
          >
            {section
              ? sections
                  .find((s) => s.id === section)
                  .chapters.map((s) => {
                    const unapprovedCount = allQuestions.filter(
                      (q) => q.chapter === s.id && !q.approved
                    ).length
                    const approvedCount = allQuestions.filter(
                      (q) => q.chapter === s.id && q.approved
                    ).length
                    return (
                      <MenuItem key={s.id} value={s.id}>
                        <Box whiteSpace="pre-wrap" display="inline">
                          {s.chapterNo}. {s.title[locale]}
                        </Box>
                        {
                          <Box ml={2} display="inline-flex" whiteSpace="no-wrap">
                            <Typography>
                              (
                              <TickIcon
                                style={{ color: green[500], marginBottom: theme.spacing(-1) }}
                              />
                              : {approvedCount}
                              <CrossIcon
                                style={{
                                  color: red[500],
                                  marginBottom: theme.spacing(-1),
                                  marginLeft: theme.spacing(2),
                                }}
                              />
                              : {unapprovedCount})
                            </Typography>
                          </Box>
                        }
                      </MenuItem>
                    )
                  })
              : null}
          </TextField>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ChapterSelector
