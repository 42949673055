import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import useI18n from '../../misc/languages/useI18n'
import { Controller, useForm } from 'react-hook-form'
import { styled } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import get from 'lodash/get'
import { useFirebase } from 'react-redux-firebase'

const Input = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const ResetPasswordDialog = ({ isVisible, onClose }) => {
  const { t } = useI18n()
  const firebase = useFirebase()
  const { enqueueSnackbar } = useSnackbar()
  const { handleSubmit, control, errors, setError } = useForm({ reValidateMode: 'onSubmit' })
  const [loading, setLoading] = React.useState(false)

  const onSubmit = async (params) => {
    if (params.password && params.password !== params.confirmPassword) {
      return setError('confirmPassword', 'mismatch-password', t('mismatch password'))
    }
    try {
      setLoading(true)
      const user = firebase.auth().currentUser
      await user.reauthenticateWithCredential(
        firebase.auth.EmailAuthProvider.credential(user.email, params.oldPassword)
      )
      await user.updatePassword(params.password)
      setLoading(false)
      onClose()
      enqueueSnackbar(t('password reset successful'))
    } catch (err) {
      console.log(err)
      setError('oldPassword', 'mismatch-password', t('old password incorrect'))
      setLoading(false)
    }
  }

  return (
    <Dialog open={isVisible} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{t('reset password')}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ paddingTop: 0 }}>
          <Controller
            type="password"
            as={Input}
            name="oldPassword"
            label={t('old password')}
            control={control}
            defaultValue=""
            error={!!errors.oldPassword}
            helperText={get(errors, 'oldPassword.message')}
            fullWidth
            rules={{ required: true }}
          />
          <Controller
            type="password"
            as={Input}
            name="password"
            label={t('new password')}
            control={control}
            defaultValue=""
            error={!!errors.password}
            helperText={get(errors, 'password.message')}
            fullWidth
            rules={{ required: true }}
          />
          <Controller
            type="password"
            as={Input}
            name="confirmPassword"
            label={t('confirm password')}
            control={control}
            defaultValue=""
            error={!!errors.confirmPassword}
            helperText={get(errors, 'confirmPassword.message')}
            fullWidth
            rules={{ required: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button style={{ width: 120 }} onClick={onClose} color="secondary">
            {t('cancel')}
          </Button>
          <Button disabled={loading} style={{ width: 120 }} type="submit" color="primary" autoFocus>
            {loading ? <CircularProgress size={24} /> : t('confirm')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ResetPasswordDialog
