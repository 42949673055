import React from 'react'
import Box from '@material-ui/core/Box'
import AdvancedTable from '../../components/AdvancedTable'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import get from 'lodash/get'
import BookmarkButton from '../../components/BookmarkButton'
import { styled, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Rating from '@material-ui/lab/Rating'
import { useHistory, useLocation } from 'react-router-dom'
import { selectBookmarks } from '../../misc/selectors'
import { useSelector } from 'react-redux'
import ButtonBase from '../../components/ButtonBase'
import useI18n from '../../misc/languages/useI18n'

const BookmarkIcon = styled((props) => <BookmarkButton readOnly value={1} {...props} />)(
  ({ theme }) => ({
    marginLeft: theme.spacing(1),
  })
)

const QuestionsTab = ({ assignment, submissions }) => {
  const theme = useTheme()
  const history = useHistory()
  const location = useLocation()
  const { t, locale } = useI18n()
  const query = new URLSearchParams(location.search)
  const search = query.get('search')
  const bookmarks = useSelector(selectBookmarks)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const { height: tabContentSizeWithoutBottomSpace } = useMinAvailableSize(
    ['#app-bar', '#tab-bar', '#tabs-bar'],
    6
  )
  const tabContentSize =
    tabContentSizeWithoutBottomSpace - get(window, 'ReactNativeWebView.bottomSpace', 0)

  const columns = [
    {
      key: 'questionNo',
      label: t('question no'),
    },
    {
      key: 'chapter',
      label: t('chapter'),
    },
    {
      key: 'level',
      label: t('level'),
    },
    {
      key: 'correctAns',
      label: t('correct answer'),
      align: 'center',
    },
    {
      key: 'score',
      label: t('performance'),
    },
  ]

  const rows = assignment.questions.map((q, i) => {
    const noOfCorrectAns = submissions
      .map((s) => s.questions[i].ans === q.correctAns)
      .filter((a) => a).length

    return {
      key: q.id,
      onClick: () => history.push(`/app/solution/${assignment.id}/${i}`),
      questionNo: {
        label: (
          <Box display="flex">
            <Typography>{i + 1}</Typography>
            {bookmarks.find((bm) => bm.assignment === assignment.id && bm.questionNo === i) ? (
              <BookmarkIcon />
            ) : null}
          </Box>
        ),
        sortValue: i,
        searchValue: (i + 1).toString(),
      },
      chapter: {
        label: `${get(q, 'chapter.chapterNo', '')}. ${get(q, `chapter.title.${locale}`, '')}`,
        sortValue: Number(get(q, 'chapter.chapterNo', 0)),
      },
      level: {
        label: <Rating name="level" value={q.level} max={3} readOnly />,
        sortValue: q.level,
        searchValue: q.level || '',
      },
      correctAns: {
        label: q.correctAns.toUpperCase(),
      },
      score: {
        label: `${noOfCorrectAns} / ${assignment.students.length} (${
          Math.round((100 * noOfCorrectAns) / assignment.students.length) || 0
        }%)`,
        sortValue: noOfCorrectAns,
        color: get(
          get(assignment, 'subject.grades', []).find(
            (g) =>
              (noOfCorrectAns / assignment.students.length || 0) >= g.min &&
              (noOfCorrectAns / assignment.students.length || 0) <= g.max
          ),
          'color'
        ),
      },
    }
  })

  return (
    <Box pt={2} pb={isMobile ? 2 : 0}>
      <AdvancedTable
        containerProps={{
          pb: get(window, 'ReactNativeWebView.bottomSpace', 0) + 'px',
          mb: 0,
          mt: 0,
          ml: isMobile ? 2 : 0,
          height: isMobile ? undefined : tabContentSize,
          minHeight: isMobile ? tabContentSize : undefined,
        }}
        columns={columns}
        rows={rows}
        defaultSortBy="questionNo"
        defaultSortOrder
        defaultSearch={search}
        renderMobileItem={(row) => (
          <ButtonBase key={row.key} onClick={row.onClick}>
            <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center" flex={1}>
                <Box width={theme.spacing(4)}>{row.questionNo.label}</Box>
                <Box ml={2}>
                  <Typography variant="body2">{row.chapter.label}</Typography>
                  <Box display="flex" alignItems="center">
                    <Typography
                      style={{ marginRight: theme.spacing(1) }}
                      variant="body2"
                      color="textSecondary"
                    >
                      {t('level')}:
                    </Typography>
                    <Rating
                      size="small"
                      name="level"
                      value={row.level.sortValue}
                      max={3}
                      readOnly
                    />
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    {t('performance')}: {row.score.sortValue} / {assignment.students.length}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="h5" style={{ color: row.score.color }}>
                {Math.round((100 * row.score.sortValue) / assignment.students.length)}%
              </Typography>
            </Box>
            <Divider />
          </ButtonBase>
        )}
        emptyText={t('no question')}
      />
    </Box>
  )
}

export default QuestionsTab
