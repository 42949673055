import React from 'react'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import Box from '@material-ui/core/Box'
import SortIcon from '@material-ui/icons/Reorder'
import arrayMove from 'array-move'

const DragHandle = sortableHandle(() => <SortIcon style={{ cursor: 'move' }} color="disabled" />)

const SortableItem = sortableElement(({ renderCenterItem, renderRightItem, item }) => (
  <Box
    mb={2}
    p={2}
    borderRadius="borderRadius"
    overflow="hidden"
    boxShadow={3}
    display="flex"
    bgcolor="background.paper"
  >
    <DragHandle />
    <Box mx={2} flex={1}>
      {renderCenterItem(item)}
    </Box>
    <Box>{renderRightItem ? renderRightItem(item) : null}</Box>
  </Box>
))

const SortableContainer = sortableContainer(({ children }) => {
  return <Box mt={2}>{children}</Box>
})

const SortableList = ({ renderCenterItem, renderRightItem, defaultItems, onSort }) => {
  const [items, setItems] = React.useState([])
  React.useEffect(() => {
    setItems(defaultItems)
  }, [defaultItems])
  return (
    <SortableContainer
      useDragHandle
      onSortEnd={({ oldIndex, newIndex }) => {
        const newItems = arrayMove(items, oldIndex, newIndex)
        setItems(newItems)
        onSort(newItems)
      }}
    >
      {items.map((item, index) => (
        <SortableItem
          key={item.id}
          index={index}
          renderCenterItem={renderCenterItem}
          renderRightItem={renderRightItem}
          item={item}
        />
      ))}
    </SortableContainer>
  )
}

export default SortableList
