import React from 'react'
import Box from '@material-ui/core/Box'
import { styled } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import Avatar from '@material-ui/core/Avatar'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Divider from '@material-ui/core/Divider'
import { selectIsPremiumFeaturesPermitted } from '../../misc/selectors'
import { useFirebase } from 'react-redux-firebase'
import useI18n from '../../misc/languages/useI18n'
import Chip from '@material-ui/core/Chip'
import MenuItem from './MenuItem'
import { MenuWidth } from './'
import packageJson from '../../../package.json'
import getInitial from '../../misc/getInitial'

const LargeAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  marginBottom: theme.spacing(1),
}))

const WrapText = styled(Typography)(({ theme }) => ({
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}))

const MenuList = ({ isMobile, menuItemsTop, menuItemsBottom, fromMore, ...props }) => {
  const { t } = useI18n()
  const location = useLocation()
  const firebase = useFirebase()
  const history = useHistory()
  const { avatarUrl, displayName, email, role, isSchool } = useSelector(
    (state) => state.firebase.profile
  )

  React.useEffect(() => {
    // Replace facebook avatar with higher resolution
    if (avatarUrl && avatarUrl.includes('graph.facebook.com') && !avatarUrl.includes('?height=')) {
      firebase.updateProfile({
        avatarUrl: `${avatarUrl}?height=200`,
      })
    }
  }, [avatarUrl])

  const { isPremium } = useSelector(selectIsPremiumFeaturesPermitted)

  return (
    <Box
      borderRadius="borderRadius"
      overflow="hidden"
      boxShadow={3}
      bgcolor="background.paper"
      width={fromMore ? undefined : MenuWidth}
      height="100vh"
      {...props}
    >
      {fromMore ? null : (
        <Box p={2} pt={4} display="flex" flexDirection="column" alignItems="center">
          <LargeAvatar src={avatarUrl}>{getInitial(displayName)}</LargeAvatar>
          <WrapText variant="h6" align="center">
            {displayName}
          </WrapText>
          <WrapText variant="body2" align="center">
            {email}
          </WrapText>
          {isPremium ? (
            <Box mt={1}>
              <Chip label={t('premium')} color="secondary" />
            </Box>
          ) : null}
        </Box>
      )}
      <List>
        {menuItemsTop.map((item) => (
          <MenuItem
            isMobile={isMobile}
            history={history}
            key={item.href || item.title}
            item={item}
            location={location}
          />
        ))}
      </List>
      <Divider />
      <List>
        {menuItemsBottom.map((item) => (
          <MenuItem
            isMobile={isMobile}
            history={history}
            key={item.href || item.title}
            item={item}
            location={location}
          />
        ))}
      </List>
      <Box display="flex" justifyContent="center" mt={2}>
        <Typography variant="caption">v{packageJson.version}</Typography>
      </Box>
    </Box>
  )
}

export default MenuList
