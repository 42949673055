import React from 'react'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase'
import WithAppBar from '../../components/WithAppBar'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import compact from 'lodash/compact'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import QuestionsList from '../../components/QuestionsList'
import Hidden from '@material-ui/core/Hidden'
import QuestionCard from './QuestionCard'
import Timer from './Timer'
import AnswerButton from './AnswerButton'
import Footer from './Footer'
import ConfirmDialog from '../../components/ConfirmDialog'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { selectPractice, selectQuestion } from '../../misc/selectors'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import ListIcon from '@material-ui/icons/FormatListNumbered'
import { useTheme } from '@material-ui/core/styles'
import useI18n from '../../misc/languages/useI18n'
import scrollParentToChild from '../../misc/scrollParentToChild'

const Working = () => {
  const firestore = useFirestore()
  const theme = useTheme()
  const { t } = useI18n()
  const {
    params: { practiceId, questionNo: questionNoString },
  } = useRouteMatch()
  const questionNo = Number(questionNoString)
  const history = useHistory()
  const practice = useSelector((state) => selectPractice(state, practiceId))
  const questionId = get(practice, `questions[${questionNo}].id`)
  const question = useSelector((state) => selectQuestion(state, questionId))
  const unfinishedQuestionCount = get(practice, 'questions', []).filter((q) => !q.ans).length

  useFirestoreConnect(
    compact([questionId ? { collection: 'questions', doc: questionId } : undefined])
  )

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = React.useState(false)

  const { height: minAvailableScreenHeight } = useMinAvailableSize(
    ['#app-bar', '#tab-bar', '#time-left-container'],
    2
  )

  const submit = async () => {
    try {
      const score = practice.questions.filter((q) => q.ans === q.correctAns).length
      const finishedAt = Date.now()
      await firestore.update({ collection: 'practices', doc: practiceId }, { score, finishedAt })
    } catch (err) {
      console.log(err)
    }
  }

  React.useEffect(() => {
    if (get(practice, 'finishedAt')) {
      history.replace('/app/practice-history')
      history.push(`/app/result/${practiceId}`)
    }
  }, [practice])

  React.useEffect(() => {
    setIsDrawerOpen(false)
    scrollParentToChild(
      document.getElementById('scroll-container'),
      document.getElementById('scroll-container').childNodes[0],
      0
    )
  }, [questionNo])

  return (
    <WithAppBar
      title={t('working')}
      hideRightButtonOnDesktop
      rightButtonIcon={<ListIcon />}
      onRightButtonClick={() => setIsDrawerOpen(true)}
      back
    >
      <Timer practiceId={practiceId} practice={practice} onTimeOut={submit} />
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={9} lg={10}>
          <Box
            id="scroll-container"
            height={minAvailableScreenHeight + theme.spacing(2)}
            overflow="auto"
          >
            <QuestionCard
              question={question}
              questionNo={questionNo}
              practiceId={practiceId}
              practice={practice}
            />
            <Grid container spacing={0}>
              {['a', 'b', 'c', 'd'].map((ans) => (
                <AnswerButton
                  key={ans}
                  ans={ans}
                  practiceId={practiceId}
                  practice={practice}
                  questionNo={questionNo}
                  question={question}
                />
              ))}
            </Grid>
            <Footer
              practiceId={practiceId}
              practice={practice}
              questionNo={questionNo}
              onSubmit={() => setIsConfirmModalVisible(true)}
            />
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid item md={3} lg={2}>
            <Box
              borderRadius="borderRadius"
              overflow="hidden"
              boxShadow={3}
              bgcolor="background.paper"
              height={minAvailableScreenHeight - theme.spacing(2)}
              mr={2}
              mt={2}
              overflow="auto"
            >
              <QuestionsList practiceId={practiceId} practice={practice} questionNo={questionNo} />
            </Box>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <SwipeableDrawer
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            onOpen={() => {}}
            anchor="right"
          >
            <QuestionsList practiceId={practiceId} practice={practice} questionNo={questionNo} />
          </SwipeableDrawer>
        </Hidden>
      </Grid>
      <ConfirmDialog
        message={
          unfinishedQuestionCount
            ? t('confirm submit unfinished', { n: unfinishedQuestionCount })
            : t('confirm submit')
        }
        isVisible={isConfirmModalVisible}
        onClose={() => setIsConfirmModalVisible(false)}
        onSubmit={submit}
      />
    </WithAppBar>
  )
}

export default Working
